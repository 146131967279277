import { LoginBranding } from '@components/layout/Login/Branding/LoginBranding';
import LoginForm from '@components/layout/Login/LoginForm';
import { RegisterProvider } from '@components/layout/Register/context/Register.context';
import { RegisterForm } from '@components/layout/Register/Register.form';
import { RegisterUserProfileForm } from '@components/layout/Register/RegisterUserProfileForm';
import { FinishRegister } from '@components/modal/FinishRegister/FinishRegister';
import { useLoginModalStyles } from '@components/modal/Login/LoginModal.styles';
import { Modal, ModalProps } from '@components/modal/Modal';
import { Box, DialogContent } from '@mui/material';
import { useAcoRouter } from '@providers/hooks/AcoRouter';
import { OnDesktop, useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import React, { useState } from 'react';

interface LoginModalProps {
  viewLogin?: boolean;
  email?: string;
  notRedirect?: boolean;
  closeRegister?: () => void;
  isAutomaticModal?: boolean;
  noClose?: boolean;
  ticketForm?: boolean;
  registerTicket?: (password: string) => void;
  withoutBranding?: boolean;
}

export const LoginModal: React.VFC<{ modalProps: ModalProps, loginModalProps?: LoginModalProps }> = ({
                                                                                                       modalProps,
                                                                                                       loginModalProps,
                                                                                                     }) => {

  const classes = useLoginModalStyles();
  const isMobile = useIsMobile();
  const router = useAcoRouter();
  const { isLogged } = useSession();

  const [showProfileForm, setShowProfileForm] = useState(false);
  const [viewLogin, setViewLogin] = useState<boolean>(loginModalProps?.viewLogin != undefined ? loginModalProps.viewLogin : true);
  const [finishRegister, setFinishRegister] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [dataFinishRegister, setDataFinishRegister] = useState<{ name: string, email: string }>();

  const fromTicket = (typeof window !== 'undefined') && window.location.href.includes('tickets') && loginModalProps?.isAutomaticModal;


  const handleClose = () => {
    if (!isLogged && loginModalProps?.noClose) {
      router.home();

      return;
    }


    modalProps.onClose && modalProps.onClose('escapeKeyDown', 'escapeKeyDown');
    loginModalProps?.closeRegister && loginModalProps.closeRegister();
  };

  const handleChange = (state?: boolean) => {
    setViewLogin(state);
  };

  const handleStopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const handleFinish = (password?: string, fromProvider?: boolean) => {
    const data = JSON.parse(localStorage.getItem('__tmp_w'));

    if (loginModalProps.ticketForm && !fromProvider) {
      loginModalProps.registerTicket(password);
      handleClose();
    } else if (fromProvider) {
      handleClose();
    } else {
      setDataFinishRegister({ name: data.name, email: data.email });
      localStorage.removeItem('__tmp_w');
      setFinishRegister(true);
      setModalOpen(true);
    }
  };

  const handleText = (): string => {
    // eslint-disable-next-line max-len
    return loginModalProps?.isAutomaticModal ? (isMobile ? 'Inicia sesión o Registrate gratis para acceder a todas las funcionalidades' : '') : 'Para realizar esta acción debes estar registrado o haber iniciado sesión';
  };

  return (
    <>
      {finishRegister ?
        <FinishRegister ModalProps={{ open: modalOpen, onClose: () => setModalOpen(false) }}
                        FinishRegister={{ name: dataFinishRegister.name, email: dataFinishRegister.email }} />
        :
        <Modal {...(modalProps as ModalProps)} onClose={handleClose}
               maxWidth='md' fullWidth fullScreen={isMobile}
               sx={{ '& .MuiDialog-container': { alignItems: 'flex-start' } }} onClick={handleStopPropagation}
               title={handleText()}>
          <DialogContent className={classes.content}>

            <Box display='flex' justifyContent='center' alignItems='flex-start'>
              <Box display='flex' justifyContent='space-around' width='100%' alignItems='center'>
                <OnDesktop>
                  {!loginModalProps.withoutBranding && <LoginBranding isModal={true} isAutomaticModal={loginModalProps?.isAutomaticModal} />}
                </OnDesktop>

                <RegisterProvider>
                  {viewLogin ?
                    <>
                      {!showProfileForm &&
                        <LoginForm onContinue={() => setShowProfileForm(true)} isModal={true} setChange={handleChange}
                                   setClose={handleClose} email={loginModalProps?.email}
                                   notRedirect={loginModalProps?.notRedirect} fromTicket={fromTicket} withoutBranding={loginModalProps.withoutBranding}/>}
                      {showProfileForm && <RegisterUserProfileForm back={() => setShowProfileForm(false)} isModal={true}
                                                                   finishRegister={(password, fromProvider) => handleFinish(password, fromProvider)}
                                                                   isAutomaticModal={loginModalProps?.isAutomaticModal} />}
                    </>
                    :
                    <>
                      {!showProfileForm && <RegisterForm onContinue={() => setShowProfileForm(true)} isModal={true}
                                                         setClose={handleChange} withoutBranding={loginModalProps.withoutBranding} />}
                      {showProfileForm && <RegisterUserProfileForm back={() => setShowProfileForm(false)} isModal={true}
                                                                   finishRegister={(password, fromProvider) => handleFinish(password, fromProvider)}
                                                                   isAutomaticModal={loginModalProps?.isAutomaticModal} />}
                    </>
                  }
                </RegisterProvider>
              </Box>
            </Box>
          </DialogContent>
        </Modal>
      }
    </>
  );
};
