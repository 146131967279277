import { Menu as MUIMenu, MenuItem as MUIMenuItem, MenuProps } from '@mui/material';
import React from 'react';

export const Menu = (props: MenuProps) => (
  <MUIMenu
    elevation={2}
    keepMounted
    variant='menu'
    anchorOrigin={props.anchorOrigin || {
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={props.transformOrigin || {
      vertical: 'bottom',
      horizontal: 'right',
    }}
    {...props}
    sx={{
      paper: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #DCDCDC',
        borderRadius: 8
      },
      list: {
        padding: 0
      }
    }}
  />
);

export const MenuItem = MUIMenuItem;