import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { OldLogo } from '@svg/aco';
import React from 'react';

import { useLoginBrandingStyles } from './LoginBranding.styles';

interface LoginProps {
  isModal?: boolean,
  isAutomaticModal?: boolean;
}

export const LoginBranding: React.FC<LoginProps> = (props) => {
  const classes = useLoginBrandingStyles();

  interface Items {
    text: string;
    icon: OverridableComponent<SvgIconTypeMap>;
  }

  const items: Items[] = [
    {
      text: props.isAutomaticModal ? 'Realizar consultas a expertos' : 'Realizá consultas a expertos',
      icon: ForumOutlinedIcon,
    },
    {
      text: props.isAutomaticModal ? 'Participar de eventos online exclusivos' : 'Participá de eventos online exclusivos',
      icon: LiveTvOutlinedIcon,
    },
    {
      text: props.isAutomaticModal ? 'Intercambiar experiencias con colegas' : 'Intercambiá experiencias con colegas',
      icon: PeopleAltOutlinedIcon,
    },
  ];

  return (
    <Box>

      {!props.isAutomaticModal ?
        <Box>
          <Box marginBottom='8px' className={classes.logoContainer}>
            <OldLogo />
          </Box>
          <Typography variant={props.isModal ? 'h5' : 'h3'} color='primary'>
            Bienvenido a
          </Typography>
          <Typography variant='h3' color='primary'>
            Agroconsultas
          </Typography>
        </Box> :
        <Box>
          <Typography variant='h3' color='primary'>
            Inicia sesión o Registrate gratis para acceder a todas las funcionalidades:
          </Typography>
        </Box>
      }


      <Box marginTop='54px'>
        {items.map((item, index: number) => {
          const Icon = item.icon;

          return (
            <Box key={index} marginBottom='40px'>
              <Typography variant='subtitle1' className={classes.boxText}>
                <Icon className={classes.icon} /> {item.text}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

