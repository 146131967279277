import { usePostCardStyles } from '@components/cards/card/Post/PostCard.styles';
import { Attachment } from '@components/cards/card/shared/Gallery/Gallery';
import { AttachFile } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

export interface PDFViewerProps {
  attachments: Attachment[];
  expandFile: boolean;
}

// @ts-ignore
export const PDFViewer: React.VFC<PDFViewerProps> = (props) => {
  const classes = usePostCardStyles();

  return (props.attachments.map((attachment, index) => {
      return (
        <Box key={index} marginTop='10px'>
          <Box display='flex'>
            <AttachFile fontSize='small' />
            <Typography><u><a href={attachment.url} target='_blank'
                              rel='noreferrer'>{attachment.filename}</a></u></Typography>
          </Box>
          {props.expandFile && <iframe src={attachment.url} className={classes.iframePdf} frameBorder='0' ></iframe>}
        </Box>
      );
    })
  );
};
