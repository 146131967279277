import AcoButton from '@components/AcoButtons/Button';
import { Box } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import LinearProgress from '@mui/material/LinearProgress';
import makeStyles from '@mui/styles/makeStyles';
import React, { PropsWithChildren } from 'react';

interface AcoProgressButtonProps extends ButtonProps {
  loading: boolean;
  boxClassName?: string;
}

const customStyles = makeStyles({
  linearProgress: {
    margin: '-5px 0px 0px 0px',
    height: '5px',
    borderRadius: '0px 0px 400px 400px'
  }
});

const AcoProgressButton: React.FC<PropsWithChildren<AcoProgressButtonProps>> = ({loading, boxClassName, ...props}) => {
  const styles = customStyles();

  return (
    <Box display='flex' flexDirection='column' className={boxClassName}>
      <AcoButton {...props}>
        {props.children}
      </AcoButton>
      {loading && <LinearProgress color={props.color == 'secondary' ? 'secondary' : 'primary'} className={styles.linearProgress} />}
    </Box>
  )
};

export default AcoProgressButton;