import { LoginModal } from '@components/modal/Login/LoginModal';
import { Grid, Button } from '@mui/material';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { items } from './items';
import styles from './Menu.styles';


const Menu: React.FC = () => {
  const classes = styles();
  const router = useRouter();
  const [loginOpen, setLoginOpen] = useState(false);

  const redirect = (url: string) => {
    router.push(url);
  };
  
  return (
    <Grid container spacing={1} direction='row' justifyContent='flex-start' wrap='nowrap' alignItems='center'>
      {items.map((item, index) => {
        const Icon = item.icon;

        return (
          <Grid item key={index}>
            <Button
              onClick={() => redirect(item.redirection)}
              disableRipple
              className={clsx(classes.itemRoot, { selected: item.selected(router.pathname) })}
              classes={{ text: classes.itemLabel }}>
              <Icon classes={{ root: classes.iconRoot }} />
              {item.text}
            </Button>
          </Grid>
        );
      })}

      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }} />}
    </Grid>
  );
};

export default Menu;
