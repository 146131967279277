import React, { createContext, useEffect, useState } from 'react';

const esperarCargaEPLMobile = (props) => {
  return new Promise((resolve) => {
    const waitEpl = () => {
      if (typeof window['eplAD4M'] !== 'undefined') {

        // @ts-ignore
        resolve();
      } else {
        props.onPoll();
        window.setTimeout(waitEpl, 100);
      }
    };

    waitEpl();
  });
};

export const EplContext = createContext({ i: 0, loaded: false });

export const EplContextProvider = ({ children }) => {
  const [done, setDone] = useState(false);
  const [i, setI] = useState(0);

  useEffect(() => {
    esperarCargaEPLMobile({
      onPoll: () => setI((i) => i + 1),
    }).then(() => setDone(true));
  }, []);

  return (
    <EplContext.Provider value={{ loaded: done, i }}>
      {children}
    </EplContext.Provider>
  );
};

