import { Attachment, Gallery } from '@components/cards/card/shared/Gallery/Gallery';
import { PDFViewer } from '@components/cards/card/shared/Gallery/PDFViewer';
import { AttachFile } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { IconCount } from '../IconCount/IconCount';

export interface CardAttachmentsProps {
  count?: number;
  hideGallery?: boolean;
  alwaysExpandImages?: boolean;
  expandPdf?: boolean;
  files: Attachment[];
}

export function filesOrganizer(attachments: Attachment[]) {
  const img = [], pdf = [], other = []

  attachments?.map(file => {
    if(file.mediaType?.toLowerCase().startsWith('image')) {
      img.push(file)
    }
    else if (file.mediaType?.toLowerCase().startsWith('application/pdf')) {
      pdf.push(file);
    }
    else {
      other.push(file);
    }
  })

  return [img, pdf, other];
}

export const CardAttachments = (props: CardAttachmentsProps) => {
  const count = props.count || (props.files || []).length;

  const showGallery = props.files && !(props.hideGallery || false);

  const [images, pdfs, attachments] = filesOrganizer(props.files);

   const otherFiles = (file: Attachment) => (
       <Box display='flex'>
         <AttachFile fontSize='small' />
         <Typography><u><a href={file.url} target='_blank'
                           rel='noreferrer'>{file.filename}</a></u></Typography>
       </Box>
   )

  return (
    <React.Fragment>
      {count > 0 && <IconCount icon={AttachFile} count={count}/>}

      {showGallery && attachments &&
        attachments.map((file) => otherFiles(file) )
      }

      {showGallery && <Gallery images={images} maxHeight={props.files.length > 1 ? 200 : 379} alwaysExpand={props.alwaysExpandImages}/>}

      <div onClick={(e) => {
        e.stopPropagation();
      }}>
        {showGallery && pdfs && <PDFViewer attachments={pdfs} expandFile={props.expandPdf} />}
      </div>

    </React.Fragment>
  );
}
