import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    body: {
      backgroundColor: theme.palette.background.paper,
      minHeight: '100vh',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column'
    },
    main: {
      marginTop: '10px',
      display: 'flex',
      [StyleResponsive.onDesktop]: {
        padding: '0 10px',
      },
      [StyleResponsive.onMobile]: {
        padding: '0 0',
      }
    },
    cleanContainer: {
      maxWidth: '100%',
      background: '#737373',
      height: '100%',
      padding: 0,
      margin: 0,
      flexDirection: 'column'
    },
    principalBox: {
      flexGrow: 1,
      overflowX: 'auto',
      overflowY: 'hidden',
      maxWidth: '100%',
      [StyleResponsive.onMobile]: {
        marginBottom: '70px',
      },
    },
    rightBox: {
      marginLeft: '20px',
      width: '310px'
    },
    toTopButton: {
      position: 'sticky',
      top: '90vh',
      marginTop: '35px',
      height: '30px',
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxSizing: 'border-box',
      minWidth: '30px !important',
      width: '30px !important',
      '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
      },
    }
  })
);
