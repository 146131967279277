import Button from '@components/AcoButtons/Button';
import CardContainer, { ACOCardContainerProps } from '@components/cards/card/Container/CardContainer';
import { usePostCardStyles } from '@components/cards/card/Post/PostCard.styles';
import { SharedMinObject } from '@components/cards/card/Post/shared/SharedMinObject/SharedMinObject';
import { SharedObject } from '@components/cards/card/Post/shared/SharedObject/SharedObject';
import { CardAttachments, CardAttachmentsProps } from '@components/cards/card/shared/Attachments/CardAttachments';
import { CardLocation } from '@components/cards/card/shared/Location/CardLocation';
import { CommonCardProp, SocialProps } from '@components/cards/card/shared/model';
import UserInfo, { UserData } from '@components/cards/card/shared/UserInfo/UserInfo';
import { FlagCountry } from '@components/Flag/ACOFlag';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { WithLinks } from '@components/WithLinks/WithLinks';
import { matchSocialMedia } from '@components/WithLinks/WithLinks.defaults';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFollowUseful } from '@providers/hooks/useful/FollowUsefulHook';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType } from '@service/model';
import { AcoObject } from '@service/objects/model';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

export interface PostCardProps extends CommonCardProp {
  user: UserData;
  date: string;
  location: string;
  content: string;
  minContent?: string;
  frames?: string;
  social: SocialProps;
  attachments: CardAttachmentsProps;
  shared?: AcoObject[];
  sharedMin?: boolean;
  onlyFollowers?: boolean;
  privacy: string;
  changeViewPrivacy?: () => void;
}

export const PostCard: React.FC<PostCardProps> = (props) => {
  const theme = useTheme();
  const classes = usePostCardStyles();
  const router = useRouter();
  const { user: loggedUser } = useSession();
  const { follow } = useFollowUseful();
  const [loginOpen, setLoginOpen] = useState(false);

  const cardContainerProps: ACOCardContainerProps = {
    id: props.id,
    type: ObjectType.POST,
    header: {
      color: theme.palette.post.light,
      title: 'Publicación',
      icon: ArticleOutlinedIcon,
      flagCounty: FlagCountry.ARG,
    },
    social: props.social,
    hideActions: props.hideActions,
    share: {
      text: `Post de ${props.user.name}: ${props.content}`,
    },
    noRedirect: props.noRedirect,
    isPageView: props.isPageView,
    sourceObject: props.sourceObject,
    publisher: props.user.id,
  };

  const followButton = () => {
    if (loggedUser) {
      follow(props.user.id, props.user.name);
      props.changeViewPrivacy();
    } else {
      setLoginOpen(true);
    }
  };

  const isPageView = props.isPageView;

  const isMatchSocialMedia = matchSocialMedia(props.content);

  const sanitizedContent = !isMatchSocialMedia && !isPageView && props?.minContent ? props.minContent?.replace('/\n/g', '<br/>') || '' :
    props.content?.replace('/\n/g', '<br/>') || '';

  return (
    <CardContainer {...cardContainerProps} hideActions={props.onlyFollowers}>
      <UserInfo {...props.user} since={props.date} post={ObjectType.POST} privacy={props.privacy} postId={props.id as unknown as number}/>
      {props.location && <CardLocation name={props.location} />}

      <Divider />
      {props.onlyFollowers && (
        <>
          <Box display='flex' alignItems='center'>
            <ErrorOutlineIcon fontSize='small' sx={{ marginBottom: '3px' }} />
            <Typography variant='subtitle2' sx={{ margin: '10px 0 10px 5px' }}>Este posteo es solo para
              seguidores.</Typography>
          </Box>
          <Typography>Para poder verlo deberás seguir al usuario.</Typography>
          <Box margin='10px 12px 10px 0'>
            <Box display='flex' flexDirection='column' justifyContent='flex-start'>
              <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center' marginTop='6px'>
                <Button color='primary' variant='contained' size='medium' onClick={followButton}>
                  Comenzar a seguir
                </Button>
                <Box marginRight='10px' />
                <Button color='primary' variant='outlined' size='medium' onClick={() => router.back()}>
                  Volver
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {!props.onlyFollowers && (
        <>
          <div className={classes.textArea}>
            <WithLinks>
              <div className={clsx(classes.content)}
                   dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
            </WithLinks>
            {props.minContent && !isMatchSocialMedia && !isPageView && <div className='gradient' />}
          </div>

          {!!props.shared &&
            props.shared.map((data, i) => {
              const Shared = props.sharedMin ? SharedMinObject : SharedObject;

              return (
                <div
                  key={i}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <Shared {...data} />
                </div>
              );
            })}
          {!isPageView && props.frames &&
            <WithLinks>
              <div className={clsx(classes.content)} dangerouslySetInnerHTML={{ __html: props.frames }} />
            </WithLinks>
          }
          <CardAttachments {...props.attachments} expandPdf={isPageView} />
        </>
      )}
      {loginOpen && (
        <LoginModal
          modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
          loginModalProps={{ notRedirect: true }}
        />
      )}
    </CardContainer>
  );
};
