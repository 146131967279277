import WhoUsefulCard from '@components/cards/card/Useful/WhoUsefulCard';
import { Box, CircularProgress, List } from '@mui/material';
import { useIsMobile } from '@providers/Responsive.provider';
import { ObjectType, User } from '@service/model';
import { UserSessionData } from '@service/model/session';
import axios from 'axios';
import React from 'react';
import useSWR from 'swr';


interface UsefulModalProps {
  user: UserSessionData;
  objectId: string | number;
  type: ObjectType;
}

const WhoUseful: React.VFC<UsefulModalProps> = (props) => {
  const isMobile = useIsMobile();

  const url = `/api/whoUseful?objectId=${props.objectId}&type=${props.type}&userId=${props.user.id}`;
  const fetcher = (url: string) => axios.get<{ useful: User[] }>(url).then((res) => res.data);
  const { data } = useSWR(url, fetcher);

  return (
    <>
      {!data && (
        <Box display='flex' justifyContent='center' minWidth='240px'>
          <CircularProgress sx={{ marginTop: '25px' }} />
        </Box>
      )}
      {data && (
        <List style={{ maxHeight: isMobile ? '90%' : '450px', overflow: 'auto' }}>
          {data.useful.map((user, index) => (
            <WhoUsefulCard key={index} user={user} />
          ))}
        </List>
      )}
    </>
  );
};

export default WhoUseful;