import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useCardLocationStyles = makeStyles({
  root: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
    [StyleResponsive.onMobile]: {
      fontSize: 12
    }
  }
});