import { LoginResult } from '@service/login.service';
import axios from 'axios';
import { useState } from 'react';

export enum LoginParams {
  DESTINATION = 'destination'
}


export enum RegisterEventStatus {
  INITIAL,
  LOADING,
  ERROR,
  OK,
  LOGGED
}

export const useRegisterEvent = () => {
  const [stateRegisterEvent, setStateRegisterEvent] = useState(RegisterEventStatus.INITIAL);
  const [paidEvent, setPaidEvent] = useState(false);

  const registerForEvent = (email, onlineEventId) => {
    const data = { email, onlineEventId };
    setStateRegisterEvent(RegisterEventStatus.LOADING);

    axios.post<LoginResult>('/api/registerEvent', data)
      .then((res) => {

        if (res.data.success) {
          setStateRegisterEvent(RegisterEventStatus.LOGGED);
        } else {
          setStateRegisterEvent(RegisterEventStatus.ERROR);
        }

      });
  };

  const checkPaidEvent = (email, onlineEventId) => {
    const data = { email, onlineEventId };

    axios.post<LoginResult>('/api/paidEvent', data)
      .then((res) => {

        if (res.data.success) {
          setPaidEvent(true);
        } else {
          setPaidEvent(false);
        }

      });
  };


  return { stateRegisterEvent, registerForEvent , checkPaidEvent, paidEvent};
};
