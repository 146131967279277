import Button from '@components/AcoButtons/Button';
import { Modal, ModalProps } from '@components/modal/Modal';
import {
  Box,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({

  container: {
    boxSizing: 'border-box',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },

}));


export const InLogin: React.VFC<{ props: ModalProps, email: string }> = ({ props, email }) => {

  const classes = useStyles();

  const handleClose = () => {
    props.onClose && props.onClose('', 'escapeKeyDown');
  };


  return (
    <>
      <Modal {...props} onClose={handleClose}
             maxWidth='md' fullWidth
             sx={{ marginTop: '126px', '& .MuiDialog-container': { alignItems: 'flex-start' } }}>
        <DialogContent>
          <>
            <Typography variant='h4' color='#26A65B' marginBottom='20px'>
              Actualización de contraseña
            </Typography>

            <Box className={classes.container}>
              <Typography fontSize='18px' display='inline'>
                Te hemos enviado un mail a <b>{email}</b> para que actualices tu contraseña.
              </Typography>

              <Typography fontSize='16px' display='inline' style={{ marginTop: '10px' }}>Ante cualquier inconveniente
                escribinos
                a: <b><u><a
                  href='mailto:contacto@agroconsultasonline.com.ar'>contacto@agroconsultasonline.com.ar</a></u></b></Typography>
            </Box>
          </>
        </DialogContent>

        <DialogActions>
          <>
            <Button
              color='primary'
              variant='contained'
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </>
        </DialogActions>
      </Modal>
    </>
  );
};
