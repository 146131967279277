import Email from './email.svg';
import Facebook from './facebook.svg';
import Instagram from './instagram.svg';
import Twitter from './twitter.svg';
import Whatsapp from './whasapp.svg';
import Youtube from './youtube.svg';


export const SocialEmailSVG = Email;
export const SocialFacebookSVG = Facebook;
export const SocialTwitterSVG = Twitter;
export const SocialWhatsappSVG = Whatsapp;
export const SocialYoutubeSVG = Youtube;
export const SocialInstagramSVG = Instagram;

