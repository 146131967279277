import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';

export const CommentSkeleton: React.FC = () => {
  return (
    <List style={{padding: 0}}>
      <ListItem alignItems='flex-start' style={{paddingLeft: '0', paddingRight: '0'}}>
        <ListItemAvatar>
          <Skeleton animation='pulse' style={{borderRadius: '6px'}} variant="rectangular" width={48} height={48} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={<Skeleton animation='pulse' variant="rectangular" height='16px' width='123px' style={{borderRadius: '20px', marginBottom: '6px'}} />}
          secondary={
            <>
              <Skeleton animation='pulse' variant="rectangular"  height='16px' width='80px' style={{borderRadius: '20px'}} />
              <Skeleton animation='pulse' variant="rectangular" height='40px' width='100%' style={{borderRadius: '8px', marginBottom: '6px', marginTop:'10px'}} />
            </>
          }
        />
      </ListItem>
    </List>
  );
};
