import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() =>
  createStyles({
    paper: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      borderRadius: 8,
      padding: 15,
      '& .dz-error-mark': {
        borderRadius: '50%',
        backgroundColor: 'rgba(255, 0, 0, 0.3)',
      },
      '& .dz-success-mark': {
        borderRadius: '50%',
        backgroundColor: 'rgba(105, 138, 38, 0.5)',
      },
      '& .dz-remove': {
        fontSize: '12px',
        position: 'absolute',
        top: '-8px',
        right: '-8px',
        color: 'white',
        height: '25px',
        width: '25px',
        lineHeight: '18px',
        backgroundColor: 'grey',
        padding: '5px',
        borderRadius: '50%',
        zIndex: 40,
        transition: 'box-shadow 0.5s ease-out',
      }
    },
    dropzone: {
      backgroundColor: 'rgba(34, 112, 168, 0.04)',
      border: '1px dashed #2270a870',
      borderRadius: 8,
      padding: 20,
      textAlign: 'center'
    },
    titleOne: {
      fontWeight: 500
    },
    folderIcon: {
      fontSize: '38px',
      color: '#2270A8',
    },
    typographyOne: {
      fontWeight: 500,
      color: '#2270A8',
    },
    filesContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 10
    },
    fileContainer: {
      display: 'inline-flex',
      borderRadius: 8,
      border: '1px solid #eaeaea',
      marginBottom: 8,
      marginRight: 8,
      width: 130,
      height: 130,
      boxSizing: 'border-box',
      overflow: 'hidden',
      position: 'relative',
      '&:hover': {
        '& $overlay':{
          display: 'block',
          background: 'rgba(0, 0, 0, .5)'
        },
        '& $fileInfo':{
          opacity: 1
        }
      }
    },
    overlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0)',
      transition: 'background 0.5s ease'
    },
    fileInner: {
      display: 'flex',
      width: '100%',
      overflow: 'hidden',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    thumb: {
      display: 'block',
      height: 'auto',
      width: '100%'
    },
    fileInfo: {
      position:'absolute',
      width: '100%',
      height: '100%',
      opacity: 0,
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 5
    },
    fileName: {
      fontSize: 10,
      lineHeight: '10px'
    },
    fileButton: {
      borderRadius: 8,
      textTransform: 'none',
      fontSize: 12,
      color: '#545353',
      fontWeight: 400
    }

  })
);
