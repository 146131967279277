import { useGalleryStyles } from '@components/cards/card/shared/Gallery/Gallery.styles';
import { ImageResponsive } from '@components/cards/card/shared/Gallery/ImageResponsive/ImageResponsive';
import { Box } from '@mui/material';
import { onClickPrevent } from '@providers/helper/click';
import clsx from 'clsx';
import FsLightbox from 'fslightbox-react';
import React, { useState } from 'react';

export interface Attachment {
  url: string;
  thumbnailUrl?: string;
  mediaType?: string;
  filename?: string;
}

export interface GalleryProps {
  images: Attachment[];
  maxHeight?: number;
  alwaysExpand?: boolean;
}

export const Gallery: React.FC<GalleryProps> = (props) => {
  const classes = useGalleryStyles();

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  if (!props.images.length) {
    return null;
  }

  const openLightboxOnSlide = (number: number) => {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  };

  const unique = props.images.length === 1;
  const more = props.images.length > 4;
  const multiple = props.images.length > 2;

  return (
    <Box className={classes.root} maxHeight={props.maxHeight}>

      <div className={clsx(classes.principal, { unique })}>
        <div className={clsx(classes.imageContainer)}
             style={{ position: 'relative' }} {...onClickPrevent(() => openLightboxOnSlide(1))}>
          <ImageResponsive src={props.images[0].thumbnailUrl} alt='Imagen 1' alwaysExpand={props.alwaysExpand} />
        </div>
      </div>

      {!unique &&
        <div key='image-gallery-other' className={clsx(classes.right, { more, multiple })}>
          {props.images.slice(1, 4).map((image, i) => (
            <div key={i} className={classes.imageContainer}
                 style={{ position: 'relative' }} {...onClickPrevent(() => openLightboxOnSlide(i + 2))}>
              {i === 2 && more &&
                <div className='more-counter'>
                  <label>+{props.images.length - 4}</label>
                </div>
              }
              <ImageResponsive src={`${image.thumbnailUrl}?rand=${i}`} alt={`Imagen ${i + 2}`}
                               alwaysExpand={props.alwaysExpand} />
            </div>
          ))}
        </div>
      }

      <div onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}>
        <FsLightbox
          toggler={lightboxController.toggler}
          sources={props.images.map((image) => image.url)}
          types={[...new Array(props.images.length).fill('image')]}
          slide={lightboxController.slide}
        />
      </div>

    </Box>
  );

};
