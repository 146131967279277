import { Theme, PaletteColor } from '@mui/material/styles';
import { ObjectType } from '@service/model';

export interface SocialProps {
  hasUseful?: boolean;
  comments: number;
  useful: number;
  shares?: number;
}

export interface StatsUserProps {
  followers: number,
  following: number,
}

export interface CommonCardProp {
  id: string;
  hideActions?: boolean;
  noRedirect?: boolean;
  isPageView?: boolean;
  sourceObject?: {
    pkg: string,
    id: string,
  }
}

declare type ThemedPaletteColor = (theme: Theme) => PaletteColor;

const COLORS: { [key: string]: ThemedPaletteColor } = {
  [ObjectType.TICKET]: theme => theme.palette.ticket,
  [ObjectType.POST]: theme => theme.palette.post,
  [ObjectType.NEWSLETTER]: theme => theme.palette.newsletter,
  [ObjectType.DOCUMENT]: theme => theme.palette.document,
};

export function objectTypePalette(type: ObjectType): ThemedPaletteColor {
  return COLORS[type] || (theme => theme.palette.secondary);
}
