import { TmpFile } from '@service/uploads/models';
import React, { useContext, useState } from 'react';

type UploadFilesStoreAdd = (uuid: string, tmpFile: TmpFile) => void;

type UploadFilesStoreRemove = (uuid: string) => void;

type UploadFilesStoreGetAll = () => TmpFile[];

type UploadFilesStoreGetAllWithKey = () => [string, TmpFile][];

export interface UploadFilesStore {
  add: UploadFilesStoreAdd;
  getAll: UploadFilesStoreGetAll;
  getAllWithKey: UploadFilesStoreGetAllWithKey;
  remove: UploadFilesStoreRemove;
}

export const useUploadFilesStore: () => UploadFilesStore = () => {
  const [data, setData] = useState<Map<string, TmpFile>>(new Map());

  const getAll: UploadFilesStoreGetAll = () => {return [...data.values()]};

  const getAllWithKey: UploadFilesStoreGetAllWithKey = () => {return [...data.entries()]}

  const add: UploadFilesStoreAdd = (uuid, tmpFile) => {
    setData(data.set(uuid, tmpFile))
  };

  const remove: UploadFilesStoreRemove = (uuid) => {
    data.delete(uuid)
    setData(data)
  };

  return { add, getAll, getAllWithKey, remove };

};

export const UploadFilesStoreContext = React.createContext<UploadFilesStore>(null);

export const useUploadFilesStoreContext = () => useContext(UploadFilesStoreContext);

