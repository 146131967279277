import Button from '@components/AcoButtons/Button';
import ACOLink from '@components/ACOLink/ACOLink';
import { Avatar } from '@components/Avatar/Avatar';
import { filesOrganizer } from '@components/cards/card/shared/Attachments/CardAttachments';
import { Gallery } from '@components/cards/card/shared/Gallery/Gallery';
import { PDFViewer } from '@components/cards/card/shared/Gallery/PDFViewer';
import styles from '@components/Comments/Comment/Comment.styles';
import CommentForm from '@components/Comments/Form/CommentForm';
import CommentsLayout from '@components/Comments/Layout/CommentsLayout';
import { WithLinks } from '@components/WithLinks/WithLinks';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import { Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useSession } from '@providers/user/SessionContext';
import { Comment as CommentDTO, ReferenceObject } from '@service/model';
import { ResponseResult } from '@service/user.service';
import { parseDateFromBackendFormat, relativeShortDate } from '@shared/helper/date';
import axios from 'axios';
import clsx from 'clsx';
import React, { useState } from 'react';

interface CommentProperties {
  comment: CommentDTO;
  lastItem?: boolean;
  reference?: ReferenceObject;
  deleteComment?: (number) => void;
  editComment?: (Comment) => void;
}

const CommentItem: React.VFC<CommentProperties> = ({ comment, lastItem, reference, deleteComment, editComment }) => {
  const classes = styles();
  const isReply = !!comment.parentId;
  const showGallery = comment.attachments?.length > 0;
  const { user: user, isLogged } = useSession();
  const { snackbars } = useCustomSnackbar();

  const [showReplies, setShowReplies] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showEditComment, setShowEditComment] = useState(false);

  const repliesUrl = `/api/comments/${comment.id}/replies?object_type=${comment.reference?.referenceType}&object_id=${comment.reference?.referenceId}`;

  const [images, pdfs] = filesOrganizer(comment.attachments);

  const showRepliesAction = () => {
    setShowReplies(!showReplies);
  };

  const showFormAction = () => {
    setShowForm(true);
    setShowReplies(true);
  };

  const editCommentAction = () => {
    setShowEditComment(!showEditComment);
  };

  const deleteCommentAction = () => {
    axios.post<{ success: boolean, error?: string }>('/api/comments/delete', {
      commentId: comment.id,
    })
      .then((res) => {

        if (res.data.success) {
          snackbars.showSuccess('Comentario eleminado');
          deleteComment(comment.id);
          setShowEditComment(!showEditComment);
        } else {
          snackbars.showError(res.data.error);
        }
      })
      .catch(() => snackbars.showError('Error al eliminar el comentario'));
  };


  const suspendComment = (commentId) => {
    const data = { commentId };

    axios.post<ResponseResult>('/api/comments/suspend', data)
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Comentario suspendido');
          deleteComment(comment.id);
        } else {
          snackbars.showSuccess('Error al suspender comentario');

          if (res.data.status === 500) {
            console.error('Error interno');
          }
        }
      });
  };

  return (
    <Box className={classes.comments}>
      <ListItem alignItems='flex-start'
                className={clsx(classes.listItem, { reply: isReply }, { lastItem: lastItem }, { showReplies: showReplies })}
                id={`comment_${comment.id}`}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <ACOLink href={comment.user.url}>
            <Avatar alt={comment.user.completeName} src={comment.user.img} name={comment.user.completeName}
                    size={{ desktop: 48, mobile: 36 }} />
          </ACOLink>
        </ListItemAvatar>
        {!showEditComment ?
          <ListItemText
            className={clsx(classes.listItemText, { reply: isReply })}
            disableTypography
            primary={<Box display='flex' justifyContent='space-between'>
              <ACOLink href={comment.user.url}>
                <Typography variant='subtitle1'>{comment.user.completeName}</Typography>
              </ACOLink>
              <Box>
                {isLogged && user.id === comment.user.id &&
                  <Button size='small' style={{ minWidth: '10px', padding: '8px 4px' }}
                          startIcon={<EditIcon />}
                          onClick={editCommentAction}
                  />
                }
                {isLogged && user?.isAdmin &&
                  <Button size='small' color='error' style={{ minWidth: '10px', padding: '8px 4px' }}
                          startIcon={<SpeakerNotesOffOutlinedIcon />}
                          onClick={() => suspendComment(comment.id)}
                  />
                }
              </Box>
            </Box>
            }
            secondary={
              <>
                <Typography variant='body1'
                            color='textPrimary'>{relativeShortDate(parseDateFromBackendFormat(comment.date), 'Hace')}</Typography>
                <Box marginBottom='10px' />
                <WithLinks>
                  <Typography variant='body1' color='textPrimary'
                              dangerouslySetInnerHTML={{ __html: comment.message }} />
                </WithLinks>
                {showGallery && images && <Gallery images={images} maxHeight={200} alwaysExpand={false} />}
                {showGallery && pdfs && <PDFViewer attachments={pdfs} expandFile={false} />}
                {!isReply &&
                  <Button startIcon={<ChatOutlinedIcon fontSize='small' />}
                          size='small' color='primary'
                          style={{ marginTop: '5px' }}
                          onClick={showFormAction}
                  >
                    Responder
                  </Button>
                }
                {comment.repliesCount > 0 &&
                  <Button color='primary' size='small'
                          startIcon={showReplies ? <ExpandLessOutlinedIcon fontSize='small' /> :
                            <ExpandMoreOutlinedIcon fontSize='small' />}
                          style={{ marginTop: '5px' }}
                          onClick={showRepliesAction}
                  >
                    {comment.repliesCount} respuesta{comment.repliesCount > 1 ? 's' : ''}
                  </Button>
                }
              </>
            }
          /> :
          <Box flexGrow={1}>
            <CommentForm reference={reference} isEdit={true} initInput={comment.message} buttonText={'Modificar'}
                         comment={comment}
                         showEditComment={() => editCommentAction()} changeComment={editComment} />
          </Box>
        }
        {showEditComment &&
          <Box display='flex' flexDirection='column'>
            <Button size='small' color='error'
                    startIcon={<DeleteForeverIcon />}
                    onClick={deleteCommentAction}
            />
            <Button size='small'
                    startIcon={<CloseIcon />}
                    onClick={editCommentAction}
            />
          </Box>
        }
      </ListItem>
      {showReplies &&
        <Box className={classes.boxResponse}>
          <CommentsLayout
            url={repliesUrl}
            allPages
            commentParentId={comment.id}
            showForm={showForm}
            reference={comment.reference}
            buttonText={'Responder'}
            label={'Escribí una respuesta'}
          />
        </Box>}
    </Box>
  );
};

export default CommentItem;
