import { LinkifyMatch } from '@components/WithLinks/model';
import { defaultComponentDecorator, defaultMatchDecorator } from '@components/WithLinks/WithLinks.defaults';
import React, { PropsWithChildren } from 'react';


function ignoreMatch(string: string, match: LinkifyMatch) {
  if (match.index === 0 || match.lastIndex === string.length - 1) {
    return false;
  }

  const context = string.substr(match.index - 6, 6);

  return context.includes('src="') || context.includes('href="');
}

interface Props {
  componentDecorator?: (a: string, b: string, c: number) => React.ReactNode,
  hrefDecorator?: (string) => string,
  matchDecorator?: (string) => Array<LinkifyMatch>,
  textDecorator?: (string) => string,
}


export const WithLinks: React.FC<Props> = (props) => {

  const parseString = (string: string) => {
    if (string === '') {
      return string;
    }

    const matches = props.matchDecorator(string);

    if (!matches) {
      return string;
    }

    const elements = [];
    let lastIndex = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    matches.filter(match => !ignoreMatch(string, match)).forEach((match: any, i) => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(string.substring(lastIndex, match.index));
      }

      const decoratedHref = props.hrefDecorator(match.url);
      const decoratedText = props.textDecorator(match.text);
      const decoratedComponent = props.componentDecorator(decoratedHref, decoratedText, i);
      elements.push(decoratedComponent);

      lastIndex = match.lastIndex;
    });

    // Push remaining text if there is any
    if (string.length > lastIndex) {
      elements.push(string.substring(lastIndex));
    }

    return (elements.length === 1) ? elements[0] : elements;
  };

  function parse(children, key = 0) {
    if (typeof children === 'string') {
      return parseString(children);
    } else if (React.isValidElement(children) && (children.type !== 'a') && (children.type !== 'button')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const innerHtml = (children.props as any).dangerouslySetInnerHTML;

      if (innerHtml) {
        const parsed = parseString(innerHtml.__html);

        const sanitized: React.ReactNode[] = (Array.isArray(parsed) ? parsed : [parsed]).map((element, i) => {

          if (typeof element === 'string') {
            return <div style={{display: 'inline'}} key={`sanitized-url-${i}`} dangerouslySetInnerHTML={{ __html: element }} />;
          }

          return element;
        });

        // @ts-ignore
        return <div className={children.props.className}>{sanitized}</div>;
      }

      return React.cloneElement(children, { key: key }, parse((children.props as PropsWithChildren<unknown>).children));
    } else if (Array.isArray(children)) {
      return children.map((child, i) => parse(child, i));
    }

    return children;
  }

  return (
    <>
      {parse(props.children)}
    </>
  );
};


WithLinks.defaultProps = {
  componentDecorator: defaultComponentDecorator,
  hrefDecorator: href => href,
  matchDecorator: defaultMatchDecorator,
  textDecorator: text => text
};