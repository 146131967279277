import Button from '@components/AcoButtons/Button';
import { SharedObject } from '@components/cards/card/Post/shared/SharedObject/SharedObject';
import { transformTextWithMentions } from '@components/common/MentionsTextInput';
import { useCreatePostStyles } from '@components/modal/CreatePost/CreatePost.styles';
import { Modal, ModalProps } from '@components/modal/Modal';
import TextCard from '@components/ticket/cards/TextCard';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { PostStatus, useNewPostContext } from '@providers/post/NewPostContext';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType } from '@service/model';
import axios from 'axios';
import clsx from 'clsx';
import React, { useState } from 'react';
import useSWR from 'swr';

interface PostModalProps {
  objectId: string | number,
  objectType: ObjectType,
}


export const CreatePost: React.VFC<{ props: ModalProps, postModalProps?: PostModalProps }> = ({
                                                                                                props,
                                                                                                postModalProps,
                                                                                              }) => {

  const classes = useCreatePostStyles();
  const theme = useTheme();

  const maxCharactersText = 1000;

  const { user } = useSession();
  const isMobile = useIsMobile();
  const { snackbars } = useCustomSnackbar();

  const {
    statePost,
    postText,
    privacy,
    publisherId,
    postLocation,
    uploadFilesStore,
    postErrors,
    sharedObject,
    updatePostText,
    updateMentions,
    updatePostPublisher,
    updatePostLocation,
    updatePostPrivacy,
    removePostLocation,
    createPost,
    restartFormPost,
    updateSharedObject,
  } = useNewPostContext();

  const enableButton = postText.length > 0 && (postText.length <= maxCharactersText || (user.id == 40 || user.id == 7693));

  const [object, setObject] = useState(null);
  const [loadObject, setLoadObject] = useState(true);

  const sendPost = () => {
    const transformedText = transformTextWithMentions(postText);
    updatePostText(transformedText);
    createPost(user, publisherId, transformedText);
  };

  const handleClose = () => {
    props.onClose && props.onClose(statePost == PostStatus.OK, 'escapeKeyDown');
  };

  const postPublished = () => {
    handleClose();

    {
      snackbars.showSuccess('Publicación creada con éxito');
    }

    restartFormPost();
  };

  if (postModalProps?.objectType && postModalProps?.objectId) {
    const url = '/api/object';
    const fetcher = (url: string) => axios.get(url).then((res) => res.data);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useSWR(`${url}?objectType=${postModalProps.objectType}&objectId=${postModalProps.objectId}`, fetcher);

    data && sharedObject === null && loadObject && updateSharedObject({
      objectType: postModalProps.objectType,
      objectId: postModalProps.objectId,
    });

    data && object === null && setObject(data.object);
  }

  const removeSharedObject = () => {
    setObject(null);
    updateSharedObject(null);
    setLoadObject(false);
  };


  const renderCard = () => {
    return (
      sharedObject ?
        <Box margin='10px'>
          <Box className={classes.deleteButton}
               style={{ backgroundColor: theme.palette[(postModalProps.objectType).toLowerCase()].contrastText }}>
            <CloseIcon onClick={removeSharedObject} />
          </Box>
          <SharedObject type={sharedObject.objectType} props={object} />
        </Box> :
        <Grid container item xs={12} alignItems='center' marginLeft='12px'>
          <CircularProgress size={20} />
          <Typography variant='subtitle1'>
            Cargando contenido para compartir...
          </Typography>
        </Grid>
    );
  };

  return (
    <>
      {statePost == PostStatus.OK &&
        postPublished()
      }
      <Modal {...(props as ModalProps)} title='Crear Publicación' onClose={handleClose}
             maxWidth='md' fullWidth fullScreen={isMobile}
             sx={{
               '& .MuiDialog-container': { alignItems: 'flex-start' },
               '& .MuiDialog-paper': { overflow: 'inherit' },
             }} onClick={(e) => {
        e.stopPropagation();
      }}>
        <DialogContent className={classes.content}>
          {statePost !== PostStatus.LOADING ?
            <TextCard TextCardProps={{
              updateText: updatePostText,
              updateMentions: updateMentions,
              updateLocation: updatePostLocation,
              removeLocation: removePostLocation,
              text: postText,
              privacy,
              updatePostPrivacy,
              updatePostPublisher,
              location: postLocation,
              errors: postErrors,
              uploadFilesStore: uploadFilesStore,
              isPost: true,
              sharedObjectNode: sharedObject && loadObject && renderCard(),
            }} />
            :
            <Box display='flex' justifyContent='center' alignItems='center' minHeight='300px'>
              <CircularProgress />
              <Typography marginLeft='20px' variant='h5'>Publicando...</Typography>
            </Box>
          }
        </DialogContent>


        {postText.length > 1000 && user.id !== 40 && user.id !== 7693 &&
          <Typography margin='10px 0 0 7%' variant='body1' color='error'>El texto no puede tener más de 1000
            caracteres.</Typography>
        }

        {postErrors.archivos &&
          <Typography margin='10px 0 0 7%' variant='body1' color='error'>{postErrors.archivos}</Typography>
        }

        {statePost !== PostStatus.LOADING &&
          <DialogActions className={classes.actions}>
            <Button color='secondary' onClick={handleClose}>
              Cancelar
            </Button>
            <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                    onClick={sendPost} disabled={!enableButton}>
              Postear
            </Button>
          </DialogActions>
        }

      </Modal>
      {statePost == PostStatus.ERROR &&
        snackbars.showError('Error al crear el posteo. Por favor, intentelo nuevamente')
      }
    </>
  );
};
