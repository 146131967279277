import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    mainBox: {
      width: '100%',
    },

    secondaryBox: {
      width: '90%',
      maxWidth: '1440px',
      margin: '0 5%',
    },

    boxText: {
      display: 'flex',
    },

    paper: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',

      [StyleResponsive.onDesktop]: {
        padding: '29px 27px 28px 29px',
        minWidth: '413px',
        marginTop: '10px',
        marginBottom: '10px',

        '&.modal': {
          marginTop: '0px',
          marginBottom: '0px',
          borderLeft: '1px solid #DCDCDC',
          boxShadow: 'unset',
          backgroundColor: '#F8F8F8',
          border: 'unset',
        },

        '&.withoutBranding': {
          border: '1px solid #DCDCDC',
        },

      },
      [StyleResponsive.onMobile]: {
        padding: '23px 10px 14px 12px',
        minWidth: '320px',
        width: '90%',
        minHeight: '520px',
        marginTop: '14px',
        marginBottom: '14px',

        '&.modal': {
          width: '100%',
        },
      },
    },

    boxTop: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    buttons: {
      boxSizing: 'border-box',
      borderRadius: '8px',
      minWidth: '170px',
      marginBottom: '18px',
      marginRight: '10px',
      justifyContent: 'flex-start',
      padding: '4px 6px',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '120px',
        minHeight: '42px',
        marginBottom: '10px',
        marginRight: '10px',
      },

      '&.facebook': {
        border: `2px solid ${theme.palette.secondary.main}`,
        color: `${theme.palette.secondary.main}`,
      },

      '&.google': {
        border: `2px solid ${theme.palette.error.main}`,
        color: `${theme.palette.error.main}`,
      },
    },


    boxConnect: {
      [StyleResponsive.onDesktop]: {
        marginBottom: '10px',
      },
      [StyleResponsive.onMobile]: {
        marginBottom: '8px',
      },
    },

    textButton: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      [StyleResponsive.onDesktop]: {
        marginLeft: '16px',
      },
      [StyleResponsive.onMobile]: {
        marginLeft: '3px',
      },
    },

    divider: {
      [StyleResponsive.onDesktop]: {
        margin: 'auto 0px',
      },
      [StyleResponsive.onMobile]: {
        margin: 'auto 4px',
      },
    },

    boxButtons: {
      marginTop: '25px',
      [StyleResponsive.onDesktop]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '21px',
      },
      [StyleResponsive.onMobile]: {
        display: 'flex',
        flexDirection: 'column-reverse',
        marginBottom: '8px',
      },
    },

    boxButtonLogin: {
      [StyleResponsive.onMobile]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },

    boxButtonRecorverPass: {
      [StyleResponsive.onMobile]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
      },
    },

    buttonRecorverPass: {
      width: '168px',
      height: '40px',
      letterSpacing: '0.1px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },

    buttonLogin: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      width: '153px',
      height: '52px',
      color: 'white',
      letterSpacing: '0.1px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:disabled': {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey['500'],
      },
    },

    disabled: {},

    boxBottom: {
      marginTop: '12px',
      display: 'flex',
      alignItems: 'center',
      [StyleResponsive.onDesktop]: {
        justifyContent: 'start',
        marginBottom: '12px',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '260px',
        justifyContent: 'space-around',
        marginBottom: '12px',
      },
    },

    register: {
      letterSpacing: '0.1px',
      textTransform: 'none',

      [StyleResponsive.onMobile]: {
        border: '2px solid #26A65B',
        boxSizing: 'border-box',
        borderRadius: '6px',
        padding: '9px 12px',
        marginLeft: '10px',
      },
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },

    icon: {
      minHeight: '22px',
      minWidth: '22px',
      marginRight: '14px',
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    textInputContainer: {
      width: '100%',
      minHeight: 56,
      '& > div': {
        minHeight: 56,
        width: '100%',
      },
    },

    inputEmail: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    inputPassword: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    formHelperText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.error.main,
      marginTop: '10px',
      marginLeft: '16px',
    },

    textFormHelperText: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      marginLeft: '8px',
    },

    textHelp: {
      backgroundColor: theme.palette.text.disabled,
      alignItems: 'center',
      padding: '10px',
      borderRadius: '8px',
      maxWidth: 'fit-content',
      margin: '22px 0 30px',

      [StyleResponsive.onMobile]: {
        margin: '10px 15px 0 15px',
        padding: '8px',
        lineHeight: '20px',
        fontSize: '13px',
      },
    },

    circularProgress: {
      [StyleResponsive.onDesktop]: {
        padding: '0px 50px',
        width: '153px',
        height: '52px',
      },
      [StyleResponsive.onMobile]: {
        padding: '0px 120px',
      },
    },

    socialIcon: {
      [StyleResponsive.onMobile]: {
        width: '33px',
        height: '33px',
      },
    },

    logoContainer: {
      '& > svg': {
        maxWidth: 60,
        '& > path': {
          fill: theme.palette.primary.main,
        },
      },
    },
  }),
);
