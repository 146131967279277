import Button from '@components/AcoButtons/Button';
import { IFormPassword } from '@components/layout/Login/RecoverPassword';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { Modal, ModalProps } from '@components/modal/Modal';
import { userRegisterEventModalStyles } from '@components/modal/OnlineEvents/RegisterEventModal.styles';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import { RegisterEventStatus, useRegisterEvent } from '@providers/hooks/RegisterEventHook';
import { useIsMobile } from '@providers/Responsive.provider';
import { OnlineEvent, SingularEventType } from '@service/model';
import { GA } from '@shared/ga/ga';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export const SelfRegistrationEventModal: React.VFC<{ modalProps: ModalProps, onlineEvent: OnlineEvent }> = ({
                                                                                                              modalProps,
                                                                                                              onlineEvent,
                                                                                                            }) => {

  const classes = userRegisterEventModalStyles();

  const router = useRouter();
  const { stateRegisterEvent, checkPaidEvent, paidEvent } = useRegisterEvent();
  const [loginOpen, setLoginOpen] = useState(false);
  const isMobile = useIsMobile();


  const INITIAL_FORM_INPUTS = {
    email: '',
  };

  const [values, setValues] = React.useState<IFormPassword>(INITIAL_FORM_INPUTS);

  const handleClose = () => {
    modalProps.onClose && modalProps.onClose('escapeKeyDown', 'escapeKeyDown');

    //GA.event({ action: `close_modal_evento_${onlineEvent.id}`, category: 'modal', label: 'closeModal', value: 1 });

    if (router.query.oeid) {
      router.push(onlineEvent.categoryId == 3 ? '/trainings' : '/events');
    }
  };

  const handleStopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const register = () => {
    router.push(`/events/register/${onlineEvent.id}`);

    GA.event({
      name: 'inicio_registro_evento',
      params: {
        evento_id: onlineEvent.id,
        pago: onlineEvent.paid,
      },
    });
  };

  const login = () => {

    router.push({
      query: { destination: `/events/${onlineEvent.id}` },
    }, undefined, { shallow: true });

    setLoginOpen(true);

    GA.event({
      name: 'inicio_sesion_evento',
      params: {
        evento_id: onlineEvent.id,
        pago: onlineEvent.paid,
      },
    });
  };

  useEffect(() => {

    if (stateRegisterEvent === RegisterEventStatus.LOGGED && !paidEvent) {
      checkPaidEvent(values.email, onlineEvent.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRegisterEvent]);

  useEffect(() => {
    if (onlineEvent.paid) {
      GA.event({
        name: 'aviso_costo_evento',
        params: {
          evento_id: onlineEvent.id,
        },
      });
    }
  });

  const modalRegisterEvent = (
      <>
        <Modal {...(modalProps as ModalProps)} onClose={handleClose}
               maxWidth='md' fullWidth fullScreen={isMobile}
               sx={{ '& .MuiDialog-container': { alignItems: 'flex-start' } }}
               onClick={handleStopPropagation}
               title={onlineEvent.title}>
          <DialogContent className={classes.content}>

            {stateRegisterEvent === RegisterEventStatus.INITIAL &&
              <>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onlineEvent.description) }} />

                <Box className={classes.boxPago}>
                  {onlineEvent.settings.amount &&
                    <Box display='flex' justifyContent='center' flexDirection={isMobile ? 'column' : 'unset'}>
                      <Typography
                        variant='subtitle1'>{onlineEvent.categoryId == 2 ? 'Este ' : 'Esta '} {SingularEventType[onlineEvent.categoryId]} tiene
                        un costo de ${onlineEvent.settings.amount} o USD {onlineEvent.settings.dolarAmount}: </Typography>

                      {onlineEvent.id != 349 && <Typography variant='subtitle1' style={{
                        color: '#5A9CCB',
                        marginLeft: isMobile ? '0px' : '4px',
                      }}>
                        <b> Para el acceso libre por 6 meses.</b>
                      </Typography>}


                    </Box>}
                </Box>

                <Typography style={{ textAlign: 'center' }}>Para continuar</Typography>
              </>
            }


          </DialogContent>

          <DialogActions className={`${classes.actions} register`}>
            <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                    onClick={register}>
              Registrarse
            </Button>

            <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                    onClick={login}>
              Iniciar sesion
            </Button>
          </DialogActions>
        </Modal>
      </>
    )
  ;


  return (
    <>
      {modalRegisterEvent}
      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
                                loginModalProps={{ withoutBranding: true }} />}
    </>
  );
};
