import { useIsMobile } from '@providers/Responsive.provider';
import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

export const ACOSharedInstagram = ({ href }) => {
  const isMobile = useIsMobile();

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <InstagramEmbed url={href} width={isMobile ? '100%' : '70%'} height={isMobile ? '570px' : 'unset'} />
    </div>
  );
};
