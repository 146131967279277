import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useAcoRouter } from '@providers/hooks/AcoRouter';
import { LoginResult } from '@service/login.service';
import axios from 'axios';

export const usePost = () => {

  const acoRouter = useAcoRouter();
  const { snackbars } = useCustomSnackbar();

  const deletePost = (objectId) => {
    const data = { objectId };

    axios.post<LoginResult>('/api/posts/delete', data)
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Posteo eliminado');
          acoRouter.home();
        } else {
          snackbars.showSuccess('Error al eliminar el posteo');

          if (res.data.status === 500) {
            console.error('Error interno');
          }
        }
      });
  };

  return { deletePost };
};
