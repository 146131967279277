import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const homePathRegex = /\/$|\/(newsletters|documents|posts|tickets)\/\[\w+]$/;

interface MenuItem {
  text: string;
  redirection: string;
  icon: OverridableComponent<SvgIconTypeMap>;
  badge?: boolean;
  selected: (string) => boolean;
}

export const items: MenuItem[] = [
  {
    text: 'Inicio',
    redirection: '/',
    icon: HomeOutlinedIcon,
    selected: (path: string) => {
      return path.match(homePathRegex)?.length > 0;
    },
  },
  {
    text: 'Expertos',
    redirection: '/experts',
    icon: GroupOutlinedIcon,
    selected: (path: string) => {
      return path == '/experts';
    },
  },
  {
    text: 'Eventos',
    redirection: '/events',
    icon: LiveTvOutlinedIcon,
    badge: false,
    selected: (path: string) => {
      return path == '/events';
    },
  },
  {
    text: 'Capacitaciones',
    redirection: '/trainings',
    icon: CoPresentOutlinedIcon,
    selected: (path: string) => {
      return path == '/trainings';
    },
  },
  {
    text: 'Chat ACO',
    redirection: '/chataco',
    icon: PsychologyOutlinedIcon,
    selected: (path: string) => {
      return path == '/chataco';
    },
  },
  // {
  //   text: 'Herramientas',
  //   redirection: '/tools',
  //   icon: BuildOutlinedIcon,
  //   selected: (path: string) => {
  //     return path == '/tools'
  //   }
  // },
];
