import Footer from '@components/Footer/Footer';
import Header from '@components/Header/Header';
import { AdBlockModal } from '@components/modal/AdBlock/AdBlockModal';
import { ChatInvitation } from '@components/modal/Chat/ChatInvitation';
import { UserLogged } from '@components/modal/NewPassword/UserLogged';
import OnlineEventHome from '@components/OnlineEvent/OnlineEventHome';
import RightColumn from '@components/RightColumn/RightColumn';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { Container, Box, Button, useScrollTrigger } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import EventCarouselProvider from '@providers/onlineEvent/eventCarouselContext';
import { OnDesktop } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { GA } from '@shared/ga/ga';
import { useDetectAdBlock } from 'adblock-detect-react';
import axios from 'axios';
import clsx from 'clsx';
import React, { PropsWithChildren, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import styles from './ACOLayout.styles';

interface ACOLayoutProps {
  clean?: boolean;
  withoutRightColumn?: boolean;
  searchText?: string;
  withoutOnLiveEventRightColumn?: boolean;
}

const ACOLayout: React.FC<PropsWithChildren<ACOLayoutProps>> = ({
                                                                  children,
                                                                  clean,
                                                                  withoutRightColumn,
                                                                  searchText,
                                                                  withoutOnLiveEventRightColumn,
                                                                }) => {
  const classes = styles();
  const { snackbars } = useCustomSnackbar();

  const scroll = useScrollTrigger({ disableHysteresis: true, threshold: 200 });
  const [cookies, setCookie] = useCookies();
  const adBlockDetected = useDetectAdBlock();
  const [openAdBlockModal, setOpenAdBlockModal] = React.useState(false);
  const { isLogged, loading, user } = useSession();
  const [openChatInvitationModal, setOpenChatInvitationModal] = React.useState(false);
  const [openNewPasswordModal, setOpenNewPasswordModal] = React.useState(false);

  const backToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (!cookies['Agroconsultas::InvitationToChat'] && !cookies['Agroconsultas::ChatEnabled'] && !openChatInvitationModal && !openAdBlockModal && !openNewPasswordModal) {
      setOpenChatInvitationModal(true);
      const date = new Date();
      date.setDate(date.getDate() + 7);
      setCookie('Agroconsultas::InvitationToChat', 1, { expires: date });
      //GA.event({ action: 'open_invitation_chat_modal', category: 'modal', label: 'openModal', value: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openChatInvitationModal, openAdBlockModal, openNewPasswordModal]);

  useEffect(() => {
    if (adBlockDetected && !cookies['Agroconsultas::AdBlock'] && !openChatInvitationModal && !openNewPasswordModal) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      setCookie('Agroconsultas::AdBlock', 1, { expires: date });
      setOpenAdBlockModal(true);

      GA.event({
        name: 'open_adblock_modal',
        params: {},
      });

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adBlockDetected, openChatInvitationModal, openNewPasswordModal]);


  useEffect(() => {
    if ((loading || isLogged) && !cookies['Agroconsultas::Notificaciones']) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      setCookie('Agroconsultas::Notificaciones', 1, { expires: date });
      /*requestForToken(snackbars, true);*/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged && !openChatInvitationModal && !openAdBlockModal && !cookies['Agroconsultas::PasswordAES']) {
      axios
        .get('/api/user/passwordAES')
        .then((res) => {
          if (res.data.success) {
            if (res.data.has_aes === 0) {
              setOpenNewPasswordModal(true);
              const date = new Date();
              date.setDate(date.getDate() + 1);
              setCookie('Agroconsultas::PasswordAES', 1, { expires: date });
            } else {
              const date = new Date();
              date.setDate(date.getDate() + 3);
              setCookie('Agroconsultas::PasswordAES', 1, { expires: date });
            }
          } else {
            snackbars.showError(res.data.errors);
          }
        })
        .catch((err) => {
          console.warn('Cannot check email for user', err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adBlockDetected, openChatInvitationModal, isLogged]);


  const container =
    clean ?
      <Container className={clsx(classes.main, classes.cleanContainer)}>
        {children}
      </Container>
      :
      <Container maxWidth='lg' className={classes.main} id='layoutBody'>
        <Box className={classes.principalBox}>
          {children}
        </Box>
        {!withoutRightColumn && (
          <OnDesktop>
            <Box className={classes.rightBox}>
              <EventCarouselProvider>
                {!withoutOnLiveEventRightColumn &&
                  <OnlineEventHome />}
              </EventCarouselProvider>

              <RightColumn />
              <Box mb={2} />
              <Footer />
              {scroll &&
                <Button onClick={backToTop} className={classes.toTopButton}>
                  <ArrowUpwardOutlinedIcon />
                </Button>}
            </Box>
          </OnDesktop>
        )}
      </Container>;

  return (
    <div className={classes.body}>
      <Header searchText={searchText} />
      {container}
      {openAdBlockModal && <AdBlockModal open={openAdBlockModal} onClose={() => setOpenAdBlockModal(false)} />}

      {openChatInvitationModal &&
        <ChatInvitation open={openChatInvitationModal} onClose={() => setOpenChatInvitationModal(false)} />}

      {openNewPasswordModal &&
        <UserLogged open={openNewPasswordModal} onClose={() => setOpenNewPasswordModal(false)} />}

    </div>
  )
    ;
};

export default ACOLayout;
