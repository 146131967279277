import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useSession } from '@providers/user/SessionContext';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import React from 'react';


export const useFollowUseful = () => {

  const { isLogged } = useSession();
  const { snackbars } = useCustomSnackbar();
  const [stateFollow, setStateFollow] = React.useState(false);


  const unfollow = (usuarioId: number, completeName: string) => {
    const data = { usuarioId };

    if (isLogged) {
      axios.post<ResponseResult>('/api/user/red/followers', data)
          .then((res) => {
            if (res.data.success) {
              snackbars.showError(`Dejaste de seguir a ${completeName}`);
              setStateFollow(!stateFollow);
            } else {
              snackbars.showWarning('Error interno');
            }
          });
    } else {
      snackbars.showWarning('Es necesario iniciar sesión para seguir a un usuario');
    }

  };

  const follow = (usuarioId: number, completeName: string) => {
    const data = { usuarioId };

    if (isLogged) {
      axios.post<ResponseResult>('/api/user/red/following', data)
          .then((res) => {
            if (res.data.success) {
              snackbars.showSuccess(`Comenzaste a seguir a ${completeName}`);
              setStateFollow(!stateFollow);
            } else {
              snackbars.showWarning('Error interno');
            }
          });
    } else {
      snackbars.showWarning('Es necesario iniciar sesión para seguir a un usuario');
    }
  };


  return { isFollow: stateFollow, setIsFollow: setStateFollow, unfollow, follow };
};