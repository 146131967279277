import { alpha } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useTicketClasses = makeStyles(theme => createStyles({
  involvedPeople: {
    display: 'flex',
    [StyleResponsive.onMobile]: {
      justifyContent: 'space-around',
      marginBottom: 10,
    },
    '& .arrow': {
      margin: 'auto',
      minWidth: 60,
      fontSize: 15,
      fontWeight: 'bold',
      [StyleResponsive.onMobile]: {
        fontSize: 12,
        minWidth: 30,
        marginTop: 48,
      },
    } as CSSProperties,
  },
  boxArrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  location: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0',
    [StyleResponsive.onMobile]: {
      fontSize: 12,
    },
  },
  boxLabels: {
    display: 'flex',
    marginBottom: '15px',
    maxWidth: '690px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      height: '5px',
    },

    '&::-webkit-scrollbar-thumb': {
      height: '5px',
    },
  },
  labels: {
    '& .label': {
      padding: '0 12px',
      fontSize: 12,
      borderRadius: 6,
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      color: theme.palette.primary.main,
      width: 'max-content',
      display: 'flex',
      marginBottom: '5px',

      [StyleResponsive.onMobile]: {
        marginRight: '5px',
      },
    } as CSSProperties,
  },
  question: {
    marginTop: 20,
    lineHeight: '20px',
    padding: '0 5px',
    fontSize: 16,
    letterSpacing: 0.25,
    [StyleResponsive.onMobile]: {
      fontSize: 14,
    },
    [StyleResponsive.onDesktop]: {
      wordBreak: 'break-word',
    },
  },
  attachmentsCount: {
    margin: '6px 5px',
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& svg.icon': {
      fontSize: 15,
      marginRight: 8,
    } as CSSProperties,
  },
  attachment: {
    borderRadius: 8,
  },
  answerSince: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.25,
    margin: '15px 0 2px 4px',
    [StyleResponsive.onMobile]: {
      marginTop: 8,
    },
  },
  btnGreen: {
    background: theme.palette.primary.main,
    color: '#FFFFFF',

    '&:hover': {
      background: theme.palette.primary.light,
    },
  },
  state: {
    display: 'flex',
    backgroundColor: theme.palette.info.light,
    borderRadius: '8px',
    padding: '5px 8px',
    alignItems: 'center',
    marginTop: '10px',
  },
  buttonToNewTicket: {
    width: '100%',
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
