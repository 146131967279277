import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useOnlineEventClasses = makeStyles(theme => createStyles({

  cardPastEvent: {
    display: 'flex',

    [StyleResponsive.onMobile]: {
      flexDirection: 'column',
      width: '100%',
      minHeight: '150px',
    },
  },

  imgEvent: {
    objectFit: 'none',
    width: '100%',
    borderRadius: '6px',

    [StyleResponsive.onMobile]: {
      maxHeight: '270px',
    },
  },

  divider: {
    height: '2px',
    background: theme.palette.secondary.main,
    borderRadius: '8px',

    [StyleResponsive.onDesktop]: {
      minWidth: '400px',
      marginTop: '13px',
    },

    [StyleResponsive.onMobile]: {
      marginTop: '5px',
    },
  },

  boxPreviewVideo: {
    minHeight: '180px',
    minWidth: '200px',
    borderRadius: '6px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '12px',
    alignItems: 'flex-start',

    [StyleResponsive.onDesktop]: {
      width: '30%',
      marginRight: '13px',
    },

    [StyleResponsive.onMobile]: {
      marginTop: '10px',
    },
  },

  iconPlayOnLive: {
    height: '150px',
    width: '40px',
    position: 'absolute',
    [StyleResponsive.onMobile]: {
      height: '264px',
    },
  },

  linkDescription: {
    textDecoration: 'none',
    marginLeft: '2px',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },

  boxCategory: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    width: '160px',
    minHeight: '33px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },

  boxPrice: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    width: '70px',
    marginLeft: '8px',
    minHeight: '33px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.main,
  },

}));
