import { makeResponsiveStyles } from '@providers/Responsive.provider';

export const useLoginBrandingStyles = makeResponsiveStyles(() => (theme) => ({
  logoContainer: {
    '& > svg': {
      maxWidth: 60,
      '& > path': {
        fill: theme.palette.primary.main,
      },
    },
  },

  boxText: {
    display: 'flex',
  },

  icon: {
    minHeight: '22px',
    minWidth: '22px',
    marginRight: '14px',
  },
}));
