import { Collapse, useScrollTrigger } from '@mui/material';
import React from 'react';

const HideOnScroll: React.FC<{timeout?: { enter: number, exit: number }}> = ({ children, timeout }) => {
  const trigger = useScrollTrigger();

  return (
    <Collapse appear={false} in={!trigger} timeout={timeout} >
      {children}
    </Collapse>
  );
};

HideOnScroll.defaultProps = {
  timeout: {enter: 250, exit: 250}
}


export default HideOnScroll;
