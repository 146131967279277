import UserInfo from '@components/cards/card/shared/UserInfo/UserInfo';
import { ArrowForwardIos, AttachFile, DoneOutlineOutlined, MessageOutlined } from '@mui/icons-material';
import { Box, Button, Card } from '@mui/material';
import { onClickPrevent } from '@providers/helper/click';
import { useIsMobile } from '@providers/Responsive.provider';
import { Ticket } from '@service/model';
import { useRouter } from 'next/router';
import React from 'react';

import styles from './SimilarTickets.styles';


interface SimilarTicketsCardProps {
  ticket: Ticket;
}

const SimilarTicketsCard: React.VFC<SimilarTicketsCardProps> = (props) => {
  const classes = styles();
  const isMobile = useIsMobile();
  const router = useRouter();
  const redirectClick = props.ticket.url ? (() => router.push(props.ticket.url)) : undefined;

  return (
    <Card className={classes.card} onClick={redirectClick}>

      <div className={classes.involvedPeople}>
        <UserInfo id={props.ticket.user.id} name={props.ticket.user.name} img={props.ticket.user.img}
                  hideTooltip={true} showName={true} />
        <Box className={classes.boxArrow}>
          <ArrowForwardIos className='arrow' />
        </Box>
        <UserInfo id={props.ticket.expert.user.id} name={props.ticket.expert.user.completeName}
                  img={props.ticket.expert.user.img}
                  verified={true} hideTooltip={true} />
      </div>

      <Box onClick={redirectClick}>
        <div className={classes.question} dangerouslySetInnerHTML={{ __html: props.ticket.question.slice(0, 100) }} />
      </Box>

      <Box>

        <Button size={isMobile ? 'small' : 'medium'} startIcon={
          <DoneOutlineOutlined />} {...onClickPrevent(() => redirectClick())} style={{ color: '#3A3A3A' }}>
          {props.ticket.stats.useful && `${props.ticket.stats.useful}`}
        </Button>

        <Button size={isMobile ? 'small' : 'medium'}
                startIcon={<MessageOutlined />} {...onClickPrevent(() => redirectClick())} style={{ color: '#3A3A3A' }}>
          {props.ticket.stats?.comments && `${props.ticket.stats?.comments}`}
        </Button>

        <Button size={isMobile ? 'small' : 'medium'} startIcon={
          <AttachFile />} {...onClickPrevent(() => redirectClick())} style={{ color: '#3A3A3A' }}>
          {props.ticket.attachments.count && `${props.ticket.attachments.count}`}
        </Button>


      </Box>

    </Card>
  );
};


export default SimilarTicketsCard;
