import { Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export const PageProgress: React.FC = () => {
  const router = useRouter();
  const [isPageProgress, setPageProgress] = useState(false);

  useEffect(() => {
    const handleStart = () => {
      setPageProgress(true);
    };

    const handleStop = () => {
      setPageProgress(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);

  return (
    <Collapse in={isPageProgress}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    </Collapse>
  );
};