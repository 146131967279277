import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles(() =>
  createStyles({
    mainList: {
      padding: '0',
      '&.reply:before': {
        content: '""',
        backgroundColor: '#DCDCDC',
        borderRadius: 8,
        width: 3,
        height: 'calc(100% - 45px)',
        position: 'absolute',
        [StyleResponsive.onDesktop]: {
          left: '23px',
          top: '35px',
        },
        [StyleResponsive.onMobile]: {
          left: '16px',
          top: '27px',
        },
      }
    }
  })
);
