import makeStyles from '@mui/styles/makeStyles';

export const useResponsiveImageStyles = makeStyles({
  root: {
    backgroundColor: '#100f0f',
    cursor: 'pointer',
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '&.wide': {
      '& > img': {
        objectFit: 'cover',
        maxHeight: 'none',
        width: '100%',
        height: '100%',
        '&.wide': {
          '&.expand': {
            width: '100%',
            maxWidth: 'none',
            maxHeight: 'none',
            height: '100%',
            '&.aspectPatch': {
              width: '100%',
              height: 'auto',
              maxHeight: 'none'
            }
          }
        },
        '&.expand': {
          maxHeight: 'none'
        }
      }
    },
    '&.tall': {
      '& > img': {
        objectFit: 'cover',
        maxHeight: 'none',
        width: '100%',
        height: '100%',
        maxWidth: 'none',
        '&.wide': {
          '&.expand': {
            width: 'auto',
            maxWidth: 'none',
            maxHeight: 'none',
            height: '100%',
            '&.aspectPatch': {
              width: '100%',
              height: 'auto',
              maxHeight: 'none'
            }
          }
        },
        '&.expand': {
          maxHeight: 'none'
        }
      }
    },
    '& > img:hover': {
      transform: 'scale(1.02)'
    }
  },
  debug: {
    display: 'flex',
    flexDirection: 'column',
    borderTopRightRadius: 8,
    padding: '5px 2px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'rgb(0, 0, 0, 0.1)',
    color: 'white'
  }
});