import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() =>
  createStyles({
    menu: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    formItem: {
      padding: 15,
      margin: 0
    },
    button: {
      margin: 10
    }
  })
);
