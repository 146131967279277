import styles from '@components/SelectedMenu/SelectedMenu.styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';

interface IOption {
  text: string,
  key: unknown
}

interface Props {
  label: string;
  options: IOption[];
  initialIndex?: number;
  onSelect?: (key: unknown) => void;
  classes?: string
}

const SelectedMenu: React.VFC<Props> = (props: Props) => {
  const classes = styles();
  const options: IOption[] = props.options;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedOptionIndex, setSelectedOptionIndex] = React.useState(props.initialIndex || 0 );

  const handleMenuClickOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>, index: number, key: unknown) => {
    setSelectedOptionIndex(index);
    setAnchorEl(null);
    if (props.onSelect) props.onSelect(key);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center' className={props.classes}>
      <Typography variant='body1'>{props.label}</Typography>
      <Button
        color="primary"
        onClick={handleMenuClickOption}
        endIcon={<KeyboardArrowDownIcon/>}
        classes={{root: classes.button, endIcon:classes.buttonIcon}}
      >
        {options[selectedOptionIndex].text}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
        elevation={2}
      >
        {options.map((option: IOption, index: number) => (
          <MenuItem
            key={index}
            onClick={(event) => handleClickMenuItem(event, index, option.key)}
            className={classes.menuItem}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SelectedMenu;
