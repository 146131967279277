import { Avatar } from '@components/Avatar/Avatar';
import { UserData } from '@components/cards/card/shared/UserInfo/UserInfo';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import { parseDateFromBackendFormat, relativeShortDate } from '@shared/helper/date';
import React from 'react';

import { useTicketAnswerStyle } from './TicketAnswer.style';

export interface TicketAnswerData {
  text: string;
  since: string;
}

export interface TicketAnswerProps {
  expert: UserData;
  answer: TicketAnswerData;
  waitingMessage?: string;
  canceled: boolean;
}

export const TicketAnswer = ({ expert, answer, waitingMessage, canceled }: TicketAnswerProps) => {
  const classes = useTicketAnswerStyle();

  if (canceled) {
    return (
      <div className={classes.root}>
        <div className={classes.waitingBox}>
          <InfoOutlinedIcon color='secondary' fontSize='small'/>
          <Typography color='secondary' marginLeft='5px'>
            {waitingMessage}
          </Typography>
        </div>
      </div>
    )
  }


  return (
    <div className={classes.root}>
      <span className={classes.avatarContainer}>
        <Avatar src={expert.img} alt={expert.name} size={{desktop: 48, mobile: 36}}/>
      </span>
      <div className={classes.answerContainer}>
        <div className={classes.answerText}>
          <div className='expert'>
            <div className='name'>{expert.name}</div>
            <div>{relativeShortDate(parseDateFromBackendFormat(answer.since))}</div>
          </div>
          {waitingMessage ?
            <div className={classes.waitingBox}>
              <InfoOutlinedIcon color='secondary' fontSize='small'/>
              <Typography color='secondary' marginLeft='5px'>
                {waitingMessage}
              </Typography>
            </div> :
            <div className='answer' dangerouslySetInnerHTML={{__html: answer.text}}/>}
        </div>
        {!waitingMessage && <div className='gradient'/>}
      </div>
    </div>
  );
};
