import Button from '@components/AcoButtons/Button';
import { CommentSkeleton } from '@components/Comments/CommentSkeleton';
import CommentForm from '@components/Comments/Form/CommentForm';
import CommentsList from '@components/Comments/List/CommentsList';
import { ErrorState } from '@components/States/ErrorState';
import { Box, Divider, Typography } from '@mui/material';
import { Comment, ReferenceObject } from '@service/model';
import { Pageable } from '@service/objects/model';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSWRInfinite } from 'swr';

import styles from './CommentsLoader.styles';


interface CommentsLoaderProps {
  url: string;
  reference: ReferenceObject;
  commentParentId?: number;
  allPages?: boolean;
  showForm?: boolean;
  buttonText?: string;
  label?: string;
}

const PAGE_SIZE = 10;

const CommentsLayout: React.VFC<CommentsLoaderProps> = (props) => {
  const classes = styles();

  const urlBuilder = (page) => `${props.url}${props.url.includes('?')?'&':'?'}${props.allPages? 'all_pages=true' : `page=${page + 1}&size=${PAGE_SIZE}`}`

  const fetcher = (url: string) => axios.get<Pageable<Comment[]>>(url).then((res) => res.data);

  const { data, error, size, setSize } = useSWRInfinite(urlBuilder, fetcher)

  const total = data?.[0]?.total;

  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (data) setComments([].concat(...(data.map(page => page.data))))
  }, [data])

  const isLoadingInitialData = !data && !error;
  
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');

  const isEmpty = data?.[0]?.data?.length === 0;

  const isReachingEnd = props.allPages ||
    isEmpty || (data && data[data.length - 1]?.data?.length < PAGE_SIZE);

  const appendNewComment = (comment: Comment) => {
    setComments(comments => [...comments, comment])
  };

  const deleteComment = (commentId) => {
    setComments(comments.filter((comment) => comment.id !== commentId))
  }

  const editComment = (newComment) => {
    comments.forEach(comment => {
      if(comment.id == newComment.id){
        comment.message = newComment.message
        comment.messagePlain = newComment.messagePlain
        comment.attachments =  newComment.attachments
      }
    })

    setComments([].concat(comments))
  }

  return (
    <>
      <Box>
        {comments && comments.length > 0 && comments[0] && <CommentsList comments={comments} replies={!!props.commentParentId} reference={props.reference}
                                                                         deleteComment={deleteComment} editComment={editComment}/>}
        {isLoadingMore && <CommentSkeleton />}
        {error && <ErrorState />}
      </Box>
      { comments && comments[0] && !isLoadingInitialData && !isReachingEnd &&
        <>
          <Divider variant='fullWidth' />
          <Button disabled={isLoadingMore} onClick={() => setSize(size + 1)} className={classes.pagination}>
            <Typography variant='subtitle1' color='primary'>Ver más comentarios ({ (size + 1)*PAGE_SIZE < total ? (size + 1)*PAGE_SIZE : total} de {total})</Typography>
          </Button>
        </>
      }
      {props.showForm &&
        <>
          {!props.commentParentId && <Divider variant='fullWidth' />}
          <CommentForm reference={props.reference} commentParentId={props.commentParentId} onNewComment={appendNewComment} buttonText={props.buttonText} label={props.label}/>
        </>
      }
    </>
  )
}

export default CommentsLayout;
