import LogoSVG from './logo.svg';
import LogoFullSVG from './logo_full.svg';
import OldLogoSVG from './old_logo.svg'
import OldLogoFullSVG from './old_logo_full.svg'
// import LogoLoadingSVG from './logo_loading.svg';

export const Logo = LogoSVG;
export const LogoFull = LogoFullSVG;
export const OldLogoFull = OldLogoFullSVG;
export const OldLogo = OldLogoSVG;
// export const LogoLoading = LogoLoadingSVG;
