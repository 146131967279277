import AcoButton from '@components/AcoButtons/Button';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { Modal } from '@components/modal/Modal';
import ShareMenu from '@components/ShareMenu/ShareMenu';
import { DoneOutlineOutlined, MessageOutlined, ShareOutlined } from '@mui/icons-material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, Typography } from '@mui/material';
import { useCommentContext } from '@providers/comment/CommentsContext';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { onClickPrevent } from '@providers/helper/click';
import { usePost } from '@providers/hooks/PostHook';
import { Useful } from '@providers/hooks/useful/model';
import { useUseful } from '@providers/hooks/useful/UsefulHook';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType } from '@service/model';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import styles from './StatsLateral.styles';

export interface StatsLateralProps {
  useful: {
    count: number;
    hasUseful: boolean;
  };
  comments: {
    count: number;
  };
  object: {
    type: ObjectType,
    id: number,
    publisher?: number
  };
  share: {
    text: string,
    redirectUrl?: string
  };
}

const StatsLateral: React.FC<StatsLateralProps> = ({ useful, comments, object, share }) => {
  const classes = styles();

  const router = useRouter();
  const { snackbars } = useCustomSnackbar();
  const { isLogged, user } = useSession();
  const { deletePost } = usePost();
  const [loginOpen, setLoginOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [hasUseful, usefulData, switchUseful] = useUseful({
    id: object.id,
    type: object.type,
    useful: useful?.hasUseful ? Useful.YES : Useful.NO,
    count: useful?.count,
    onError: err => snackbars.showError(err.message),
  });

  const { focusTextField } = useCommentContext();

  const openLoginModal = () => {
    setLoginOpen(true);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Button
        size='medium'
        color='primary'
        {...onClickPrevent(() => (isLogged ? switchUseful() : openLoginModal()))}
        className={clsx(classes.button, classes.primary, { useful: hasUseful })}>
        <DoneOutlineOutlined className={classes.iconWithLabel} />
        {usefulData.count}
      </Button>
      <Box marginBottom='10px' />
      {comments && (
        <>
          <Button
            size='medium'
            color='secondary'
            className={clsx(classes.button, classes.secondary)}
            {...onClickPrevent(() => (isLogged ? focusTextField() : openLoginModal()))}>
            <MessageOutlined className={classes.iconWithLabel} />
            {comments.count}
          </Button>
          <Box marginBottom='10px' />
        </>
      )}

      <ShareMenu
        renderButton={(onClick) => (
          <Button
            size='medium'
            color='secondary'
            className={clsx(classes.button, classes.secondary)}
            {...onClickPrevent(onClick)}>
            <ShareOutlined />
          </Button>
        )}
        url={window.location.origin + share.redirectUrl}
        text={share.text}
        useInHome={false}
        objectId={object.id}
        objectType={object.type}
      />

      <Box marginBottom='10px' />
      {object.type === ObjectType.POST && (user?.id === object.publisher || user?.isAdmin) && (
        <>
          <Button
            size='medium'
            color='error'
            className={clsx(classes.button, classes.delete)}
            {...onClickPrevent(() => (isLogged ? handleOpenModal() : openLoginModal()))}>
            <DeleteOutlineOutlinedIcon />
          </Button>
        </>
      )}

      {object.type === ObjectType.TICKET && (user?.id == 40 || user?.isAdmin) && (
        <Button
          size='medium'
          color='secondary'
          className={clsx(classes.button, classes.secondary)}
          {...onClickPrevent(() => router.push(`/tickets/${object.id}/edit`))}>
          <CreateOutlinedIcon />
        </Button>
      )}

      {loginOpen && (
        <LoginModal
          modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
          loginModalProps={{ notRedirect: true }}
        />
      )}

      {openModal && (
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Typography variant='h6' marginBottom='30px'>¿Estás seguro?</Typography>
          <Typography variant='body1' >La publicación será eliminada y no podrá ser recuperada.</Typography>
          <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '25px'}}>
            <AcoButton color='secondary'  size='large' onClick={() => setOpenModal(false)}>
              Cancelar
            </AcoButton>
            <AcoButton onClick={() => deletePost(object.id)} color='error' variant='contained' size='large' sx={{marginLeft: '10px'}}>Eliminar</AcoButton>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default StatsLateral;
