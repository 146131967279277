import { useResponsiveImageStyles } from '@components/cards/card/shared/Gallery/ImageResponsive/ImageResponsive.styles';
import { Switch } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

const DEBUG = false;

interface ImageResponsiveProps {
  src: string;
  srcSet?: string;
  alt: string;
  alwaysExpand?: boolean;
}

const ASPECT_RATIO_EXPAND_THRESHOLD = 1.6;

export const ImageResponsive: React.VFC<ImageResponsiveProps> = ({ src, alt, srcSet, alwaysExpand }) => {
  const classes = useResponsiveImageStyles();

  const [wide, isWide] = useState(false);
  const [size, setSize] = useState({ w: 0, h: 0 });
  const { width, height, ref } = useResizeDetector();

  const onLoad = ({ currentTarget }) => {
    setSize({ w: currentTarget.naturalWidth, h: currentTarget.naturalHeight });
    isWide(currentTarget.naturalWidth > currentTarget.naturalHeight);
  };

  const aspectRatioImage = size.h > 0 ? (size.w / size.h) : 0;
  const aspectRatioBox = height > 0 ? (width / height) : 0;

  const aspectPatch = aspectRatioImage < aspectRatioBox;

  const isBoxWide = width > height;

  // Should expand if: alwaysExpand is false and the difference between aspect ratio of image and box is less than 10%
  const expand = alwaysExpand ||
    (aspectRatioBox > aspectRatioImage ? (aspectRatioBox < aspectRatioImage * ASPECT_RATIO_EXPAND_THRESHOLD) : (aspectRatioImage < aspectRatioBox * ASPECT_RATIO_EXPAND_THRESHOLD));

  const expandThreshold = aspectRatioBox > aspectRatioImage ? aspectRatioBox/aspectRatioImage : aspectRatioImage/aspectRatioBox;

  const expandThreshold2 = Math.max(aspectRatioBox, aspectRatioImage)/Math.min(aspectRatioBox, aspectRatioImage);

  const debugInfo = DEBUG && (
    <>
      <div className={classes.debug}>
        <div>
          I: {size.w}x{size.h} [{aspectRatioImage.toFixed(1)}] {wide ? '[W]' : '[T]'}
        </div>
        <div>
          B: {width}x{height} [{aspectRatioBox.toFixed(1)}] {width > height ? '[W]' : '[T]'}
        </div>
        <div>
          EXPANDED: {expand ? 'YES' : 'NO'} | ALWAYS EXPAND: {alwaysExpand ? 'YES' : 'NO'}
        </div>
        <div>
          {((expandThreshold2 - 1) * 100).toFixed(2)}% | {((expandThreshold - 1) * 100).toFixed(2)}%
        </div>
      </div>
      <div style={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}>
        <Switch
          // checked={expand}
          // onChange={() => setExpand(!expand)}
          color='primary'
        />
      </div>
    </>
  )

  return (
    <div className={clsx(classes.root, { wide: isBoxWide, tall: !isBoxWide })} ref={ref as React.LegacyRef<HTMLDivElement>}>
      {debugInfo}
      <img src={src} srcSet={srcSet} alt={alt} onLoad={onLoad} className={clsx({ wide, expand, aspectPatch })} />
    </div>
  );
};
