import { Link } from '@mui/material';
import React from 'react';

import { useACOLinkStyles } from './ACOLink.styles';

interface ACOLinkProps {
  href: string;
  text?: string;
}

export const ACOLink: React.VFC<ACOLinkProps> = ({ href, text }) => {
  const classes = useACOLinkStyles();

  // eslint-disable-next-line react/jsx-no-target-blank
  return <Link className={classes.root} target='_blank' href={href} underline="hover">{text || href}</Link>;
};