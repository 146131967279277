import CardContainer, { ACOCardContainerProps } from '@components/cards/card/Container/CardContainer';
import { useOnlineEventClasses } from '@components/cards/card/OnlineEvent/EventCard.styles';
import { CommonCardProp, SocialProps } from '@components/cards/card/shared/model';
import { FlagCountry } from '@components/Flag/ACOFlag';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { Box, Divider, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import { ObjectType, PluralEventType } from '@service/model';
import { parseDateFromBackendFormat } from '@shared/helper/date';
import DOMPurify from 'dompurify';
import React from 'react';


export interface OnlineEventCardProps extends CommonCardProp {
  title: string;
  description: string;
  content: string;
  thumbnails: string;
  dateFinish?: string;
  url?: string;
  date?: string;
  social: SocialProps;
  settings: {
    selfRegistration: string;
    amount: string;
  };
  categoryId: number;
}

export const EventCard: React.FC<OnlineEventCardProps> = (props) => {
  const classes = useOnlineEventClasses();
  const theme = useTheme();

  const cardContainerActions: ACOCardContainerProps = {
    id: props.id,
    type: ObjectType.ONLINE_EVENT,
    header: {
      color: theme.palette.error.light,
      title: 'Evento online',
      icon: LiveTvOutlinedIcon,
      flagCounty: FlagCountry.ARG,
    },
    noRedirect: props.noRedirect,
    isPageView: props.isPageView,
    social: props.social,
    share: {
      text: `Evento online: ${props.title}`,
    },
    sourceObject: props.sourceObject,
    hideActions: props.hideActions,
  };

  const date = parseDateFromBackendFormat(props.date);
  const dateString = date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });

  const [show, setShow] = React.useState(false);

  const handleClickDescription = (e) => {
    setShow(!show);
    e.stopPropagation();
  };

  return (
    <CardContainer {...cardContainerActions}>
      <Box className={classes.cardPastEvent}>
        <OnMobile>
          <Typography variant='subtitle1' color='secondary'> {dateString}</Typography>
          <Divider className={classes.divider} />
        </OnMobile>

        <Box className={classes.boxPreviewVideo}>
          <img src={props.thumbnails} alt={props.title} className={classes.imgEvent} />
          <PlayCircleFilledWhiteOutlinedIcon className={classes.iconPlayOnLive} />
        </Box>

        <Box width='100%'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box display='flex' justifyContent='space-between' alignItems='center' marginTop='10px'>
              <OnDesktop>
                <Typography variant='subtitle1' color='secondary'> {dateString}</Typography>
              </OnDesktop>
            </Box>

            {!props.hideActions &&
              <Box display='flex'>
                <Box className={classes.boxCategory}>
                  <Typography variant='body2' color='secondary'>
                    {PluralEventType[props.categoryId]}
                  </Typography>
                </Box>
                <Box className={classes.boxPrice}>
                  {!props.settings?.amount ? (
                    <Typography variant='body2' color='primary'>
                      Gratis
                    </Typography>
                  ) : (
                    <Typography variant='body2' color='secondary'>
                      ${props.settings?.amount}
                    </Typography>
                  )}
                </Box>
              </Box>
            }
          </Box>


          <Box marginBottom='20px' marginTop='13px'>
            <Typography variant='h6' fontSize='19px'>{props.title}</Typography>
            {props.description &&
              <Box>
                <div style={{ display: 'contents', color: '#828282', fontSize: '16px', fontWeight: '500' }}
                     dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.description.slice(0, show ? props.description.length : 120)) }} />
                {!show && props.description.length > 100 ? '..' : null}
                {props.description.length > 100 &&
                  <Link onClick={handleClickDescription}
                        className={classes.linkDescription}> {show ? 'Ver menos' : 'Ver más'} </Link>}
              </Box>
            }
          </Box>
        </Box>
      </Box>

    </CardContainer>
  );
};


