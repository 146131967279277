import IconButton from '@components/AcoButtons/IconButton';
import ProgressButton from '@components/AcoButtons/ProgressButton';
import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import { Menu } from '@components/custom/Menu';
import { RadioButton } from '@components/custom/RadioButton';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { FormControl, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { ReferenceObject } from '@service/model';
import axios from 'axios';
import React, { useState } from 'react';

import styles from './CommentSubscribe.styles';

interface CommentSubscribeProps {
  reference: ReferenceObject;
}

const CommentSubscribe: React.VFC<CommentSubscribeProps> = (props) => {
  //TODO: load init state from user configuration
  const classes = styles();

  const { snackbars } = useCustomSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [subscriptionType, setSubscriptionType] = React.useState('OWN');
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscriptionType((event.target as HTMLInputElement).value);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  }

  const handleClose = () => {
    closeMenu();
  }

  const handleMenuClickOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleSave = () => {
    setLoading(true);
    
    updateSubscription(subscriptionType)
      .then(result => {
        result ?
          snackbars.showSuccess('Suscripción actualizada') :
          snackbars.showError('Error al actualizar la suscripción, intente nuevamente')
      })
      .catch(() =>
        snackbars.showError('Error al actualizar la suscripción, intente nuevamente')
      )
      .finally(() => {
        setLoading(false);
        closeMenu();
      });
  }

  const updateSubscription = (subscriptionType: string) : Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
      axios.post<boolean>('/api/comments/subscription', {
        objectType: props.reference.referenceType,
        objectId: props.reference.referenceId,
        subscriptionType: subscriptionType
      })
        .then((res) => resolve(res.data))
        .catch(err => reject(err));
    })
  }

  return (
    <>
      <ACOTooltip title='Cambiar configuración de suscripción a comentarios' arrow>
        <IconButton color='secondary' size='large' onClick={handleMenuClickOption}>
          <SettingsOutlinedIcon />
        </IconButton>
      </ACOTooltip>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <FormControl component='fieldset' className={classes.menu}>
          <RadioGroup value={subscriptionType} onChange={handleChange}>
            <FormControlLabel value='ALL' control={<RadioButton color='secondary'/>} label={
              <>
                <Typography variant='body1'>Todos los comentarios y respuestas</Typography>
                <Typography variant='body2'>Recibirás notificaciones cuando haya comentarios o respuestas nuevas.</Typography>
              </>
            } className={classes.formItem}/>
            <FormControlLabel value='OWN' control={<RadioButton color='secondary'/>} label={
              <>
                <Typography variant='body1'>Respuestas a mis comentarios</Typography>
                <Typography variant='body2'>Recibiras notificaciones solo cuando respondan tus comentarios.</Typography>
              </>
            } className={classes.formItem}/>
          </RadioGroup>
          <ProgressButton color='secondary' variant='contained' boxClassName={classes.button} onClick={handleSave} loading={loading}>
            Guardar
          </ProgressButton>
        </FormControl>
      </Menu>
    </>
  )
}

export default CommentSubscribe;