import ARFlag from '@components/Flag/ARFlag';
import React from 'react';

export enum FlagCountry {
  ARG = 'ARG',
  URY = 'URY',
  BOL = 'BOL',
  COL = 'COL',
  PRY = 'PRY',
  ECU = 'ECU',
  VEN = 'VEN',
  ESP = 'ESP'
}

const IsoAlpha3ToFlagEmoji: {[key: string]: string} = {
  'ARG': '🇦🇷',
  'URY': '🇺🇾',
  'BOL': '🇧🇴',
  'COL': '🇨🇴',
  'PRY': '🇵🇾',
  'ECU': '🇪🇨',
  'VEN': '🇻🇪',
  'ESP': '🇪🇸',
  'USA': '🇪🇸',
  'AUS': '🇦🇺'
}

const FLAGS_MAP: { [key: string]: React.FC } = {
  [FlagCountry.ARG]: ARFlag
};

export const ACOFlag = (props: {country: FlagCountry}) => {
  const CountryFlag: React.FC = FLAGS_MAP[props.country || FlagCountry.ARG];

  return <CountryFlag />;
};

export const ACOEmojiFlag = (props: {country: string}) => {
  return <>{IsoAlpha3ToFlagEmoji[props.country]}</>;
};
