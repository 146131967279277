import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StyleResponsive, useIsMobile } from '@providers/Responsive.provider';
import { NotFoundCow } from '@svg/errors';
import React from 'react';


const useStyles = makeStyles(() => ({
  root: {
    '& > *': {
      margin: '8px 0',
    },
    display: 'flex',
    [StyleResponsive.onDesktop]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: '40px'
    },
    [StyleResponsive.onMobile]: {
      flexDirection: 'column-reverse',
      padding: '40px 20px 0',
    }
  },
  errorText: {
    color: 'rgba(34, 112, 168, 0.2)',
    marginBottom: '4px'
  },
  buttons: {
    color: '#828282',
    '& > *': {
      [StyleResponsive.onDesktop]: {
        margin: '8px 0',
      },
      [StyleResponsive.onMobile]: {
        margin: '2px 0',
      },
    },
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexDirection: 'column',
    [StyleResponsive.onDesktop]: {
      marginTop: '50px',
    },
    [StyleResponsive.onMobile]: {
      marginTop: '30px',
    }
  },
  button: {
    textTransform: 'none',
    padding: '10px 20px',
    [StyleResponsive.onMobile]: {
      marginLeft: '-18px',
    }
  },
  homeButton: {
    marginTop: '70px',
    [StyleResponsive.onMobile]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  svg: {
    background: 'rgba(34, 112, 168, 0.1)',
    borderRadius: '45px',
    [StyleResponsive.onMobile]: {
      width: '50vw',
      margin: 'auto'
    },
    [StyleResponsive.onDesktop]: {
      width: '473px'
    }
  }
}));

const PageNotFound: React.FC = () => {

  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
      <Box className={classes.root}>
        <Box>
          <Typography variant='h6' className={classes.errorText}>Error 404</Typography>
          <Typography variant='h4'>No encontramos la página que buscabas</Typography>
          <Box className={classes.buttons}>
            <Typography variant='h6'>Quizás esto te puede ser útil </Typography>
            <Button className={classes.button} href='/experts' variant={isMobile ? 'text' : 'outlined'}
                    color='secondary'
                    startIcon={<GroupOutlinedIcon/>}>Expertos de Agroconsultas</Button>
            <Button className={classes.button} href='/events' variant={isMobile ? 'text' : 'outlined'} color='secondary'
                    startIcon={<LiveTvOutlinedIcon/>}>Próximos eventos online</Button>
            <Button className={classes.button} href='/trainings' variant={isMobile ? 'text' : 'outlined'} color='secondary'
                    startIcon={<CoPresentOutlinedIcon/>}>Capacitaciones</Button>

            <Box className={classes.homeButton}>
              <Button className={classes.button} disableElevation href='/' variant='contained' color='primary'>Volver al
                incio</Button>
            </Box>
          </Box>
        </Box>
        <Box display='flex' flexDirection='column'>
          <NotFoundCow className={classes.svg}/>
        </Box>
      </Box>
  )
};

export default PageNotFound;