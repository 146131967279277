import ACOLink from '@components/ACOLink/ACOLink';
import HeaderProfile from '@components/Header/HeaderProfile/HeaderProfile';
import HideOnScroll from '@components/Header/HideOnScroll/HideOnScroll';
import { StickyAppBar } from '@components/Header/StickyAppBar/StickyAppBar';
import BottomMenu from '@components/Menu/BottomNavigation';
import Menu from '@components/Menu/Menu';
import { PageProgress } from '@components/PageProgress/PageProgress';
import SearchHeader from '@components/Search/SearchHeader';
import { Box, Toolbar, useScrollTrigger } from '@mui/material';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import SearchProvider from '@providers/search/SearchContext';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';
import ScriptTag from 'react-script-tag';

import OldLogoSvg from '../svg/aco/old_logo.svg';
import OldLogoFullSvg from '../svg/aco/old_logo_full.svg';
import styles from './Header.styles';


interface HeaderProperties {
  showSearch?: boolean;
  showBottomMenu?: boolean;
  searchText?: string;
}

const SEARCH_TRANSITION_TIMEOUT = 150;

const Header: React.VFC<HeaderProperties> = (props) => {
  const classes = styles();

  const scroll = useScrollTrigger();
  const router = useRouter();

  function search(text: string) {
    router.push(`/search/${text}`);
  }

  const desktopHeader = (
    <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.mainGrid}>
      <Box display='flex' justifyContent='flex-start' alignItems='center' flexGrow={1}>
        <Box display='flex' justifyContent='flex-start' alignItems='center' className={classes.searchGroup}>
          <Box className={clsx({
            [classes.logoSmallIn]: scroll,
            [classes.logoLargeIn]: !scroll,
          }, classes.logoImgContainer)}>
              <ACOLink href='/'>
                <div style={{display: 'flex'}}>
                {scroll ?
                  <OldLogoSvg className={classes.logo} /> :
                  <OldLogoFullSvg className={classes.logoFull} />
                }
                </div>
              </ACOLink>
          </Box>
          <Box marginRight='15px' />
          <SearchProvider text={props.searchText}>
            <SearchHeader searcher={search} />
          </SearchProvider>
        </Box>
        <Box marginRight='20px' />
        <Menu />
      </Box>
      <HeaderProfile />
    </Box>
  );

  const mobileHeader = (
    <Box display='flex' flexDirection='column' className={classes.mainGrid}>
      <ScriptTag type="text/javascript" src="/js/e-planning/snippet_seccion_mobile.js"/>
      <Box display='flex' alignItems='center'>
        <Box flexGrow='1' display='flex' alignItems='center'>
          <ACOLink href='/'>
            <div className={classes.mobileLogoContainer}>
              <OldLogoFullSvg className={classes.logoFull} />
            </div>
          </ACOLink>
        </Box>
        <HeaderProfile />
      </Box>
      {props.showSearch &&
      <HideOnScroll timeout={{enter: SEARCH_TRANSITION_TIMEOUT, exit: SEARCH_TRANSITION_TIMEOUT}}>
        <Box marginTop='10px'>
          <SearchProvider>
            <SearchHeader searcher={search} />
          </SearchProvider>
        </Box>
      </HideOnScroll>
      }
    </Box>
  );

  return (
    <StickyAppBar timeout={SEARCH_TRANSITION_TIMEOUT} elevation={0}>
      <PageProgress />
      <Toolbar className={classes.toolbar}>
        <OnDesktop>{desktopHeader}</OnDesktop>
        <OnMobile>
          {mobileHeader}
          {props.showBottomMenu && <BottomMenu />}
        </OnMobile>
      </Toolbar>
    </StickyAppBar>
  );
};

Header.defaultProps = {
  showSearch: true,
  showBottomMenu: true,
};

export default Header;
