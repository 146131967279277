import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() =>
  createStyles({
    pagination: {
      backgroundColor: 'rgba(38, 166, 91, 0.1)',
      padding: '15px',
      borderRadius: '0px'
    }
  })
);
