import { Avatar } from '@components/Avatar/Avatar';
import { UserData } from '@components/cards/card/shared/UserInfo/UserInfo';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { Box, Card, Chip, Typography } from '@mui/material';
import { useIsDesktop, useIsMobile } from '@providers/Responsive.provider';
import { ObjectType, Thematic, ThematicExpert } from '@service/model';
import axios from 'axios';
import clsx from 'clsx';
import styles from 'components/cards/card/User/UserCard.styles';
import { useRouter } from 'next/router';
import React from 'react';
import useSWR from 'swr';

function renderThematics(thematics: ThematicExpert[], className: string) {
  if (!thematics || thematics.length <= 0) return;

  const showThematics = thematics.slice(0, 2);
  const moreThematics = thematics.length - showThematics.length;

  return (<>
    {
      showThematics
        .map(thematic => `${thematic.name}`)
        .map((thematicLabel, index) => <Chip key={index} size='small' label={thematicLabel} className={className} />)
    }
    {moreThematics > 0 &&
      <Chip size='small' label={`+${moreThematics}`} className={className} />
    }
  </>);
}


export const UserCard: React.FC<UserData> = (props) => {
  const classes = styles();
  const isMobile = useIsMobile();

  const router = useRouter();
  const redirectUrl = `${props.url}`;
  const redirectClick = redirectUrl ? (() => router.push(redirectUrl)) : undefined;
  const isDesktop = useIsDesktop();

  let thematics = props.thematics;

  if (!thematics && props.verified) {
    const url = props.objectType === ObjectType.USER ? `/api/user/${props.id}/thematics` : `/api/business/${props.id}/thematics`;
    const fetcher = (url: string) => axios.get<{ thematics: Thematic[] }>(url).then((res) => res.data);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data } = useSWR(`${url}`, fetcher);

    thematics = data && data.thematics;
  }

  return (
    <Card className={clsx(classes.cardProfile, { clickable: isDesktop })} onClick={redirectClick}>
      <Box display='flex' alignItems={isMobile ? 'center' : 'unset'} flexDirection={isMobile ? 'column' : 'unset'}>
        <Avatar src={props.img} alt={props.name} size={{ desktop: 99, mobile: 90 }} />
        <Box className={classes.profileInformation}>
          <Box display='flex' marginTop={isMobile ? '15px' : '0px'}>
            <Typography variant='h6'>{props.name}</Typography>
            {props.verified && <VerifiedUserOutlinedIcon color='primary' />}
          </Box>
          <Typography variant='body1' color='textSecondary'>(@{props.nickname})</Typography>
        </Box>
      </Box>

      <Box display='flex' className={classes.categoriesBox} flexDirection='row' justifyContent='flex-start'
           alignContent='center' flexWrap='wrap'>
        {thematics && thematics.length > 0 &&
          <Typography variant='body2' color='textSecondary' style={{ marginTop: 5 }}>Responde sobre:</Typography> &&
          renderThematics(thematics, classes.chip)
        }
      </Box>

    </Card>
  );
};
