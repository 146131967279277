import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useCreatePostStyles = makeStyles({
  content: {
    marginTop: 35,
    minWidth: 500,
    padding: 0,
    overflow: 'inherit',

    [StyleResponsive.onMobile]: {
      minWidth: 'auto',
    },
  },
  actions: {
    padding: 0,
    marginTop: 15,
    '& > .postButton': {
      height: 48,
    },
  },

  deleteButton: {
    position: 'absolute',
    right: '49px',
    width: '30px',
    alignItems: 'center',
    display: 'flex',
    height: '32px',
    borderRadius: '4px',
    cursor: 'pointer',

    [StyleResponsive.onMobile]: {
      right: '33px',
    },
  },


});
