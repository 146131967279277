import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

export const useGalleryStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  principal: {
    marginRight: 4,
    flex: '1 1 auto',
    maxWidth: 'calc(50% - 4px)',
    '&.unique': {
      marginRight: 0,
      maxWidth: '100%'
    }
  },
  right: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 4,
    maxWidth: 'calc(50% - 4px)',
    flexGrow: 1,
    '&.multiple > div': {
      margin: '4px 0',
      '&:first-child': {
        marginTop: 0,
        marginBottom: 4
      },
      '&:last-child': {
        marginTop: 4,
        marginBottom: 0
      }
    },
    '&.more': {
      '& > div:last-child': {
        position: 'relative',
        '& > div.more-counter': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          backgroundColor: alpha('#000000', 0.4),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          '& > label': {
            fontSize: '2.5em'
          }
        }
      }
    }
  },
  imageContainer: {
    borderRadius: 8,
    border: `1px solid ${alpha(theme.palette.text.secondary, 0.9)}`,
    overflow: 'hidden',
    height: '100%',
    width: '100%'
  }
}));
