import AcoButton from '@components/AcoButtons/Button';
import { useCardContainerActionsStyles } from '@components/cards/card/Container/action/CardContainerActions.styles';
import { SocialProps } from '@components/cards/card/shared/model';
import WhoUseful from '@components/cards/card/Useful/WhoUseful';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { Modal } from '@components/modal/Modal';
import ShareMenu from '@components/ShareMenu/ShareMenu';
import { DoneOutlineOutlined, MessageOutlined, ShareOutlined } from '@mui/icons-material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, CardActions, Divider, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { onClickPrevent } from '@providers/helper/click';
import { usePost } from '@providers/hooks/PostHook';
import { Useful } from '@providers/hooks/useful/model';
import { useUseful } from '@providers/hooks/useful/UsefulHook';
import { OnDesktop, OnMobile, useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType } from '@service/model';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';


export interface CardContainerActionsProps {
  id: string | number,
  type: ObjectType,
  share: {
    text: string,
  }
  social: SocialProps,
  redirectUrl?: string,
  isPageView?: boolean;
  publisher?: number;
}

export const CardContainerActions: React.VFC<CardContainerActionsProps> = (props) => {
  const { social } = props;
  const { user, isLogged } = useSession();
  const classes = useCardContainerActionsStyles();
  const router = useRouter();
  const { deletePost } = usePost();
  const redirectClick = props.redirectUrl ? (() => router.push(props.redirectUrl)) : undefined;
  const [loginOpen, setLoginOpen] = useState(false);
  const { snackbars } = useCustomSnackbar();
  const [open, setOpen] = React.useState(false);
  const isMobile = useIsMobile();
  const [openModal, setOpenModal] = useState(false);

  const openLoginModal = () => {
    setLoginOpen(true);
  };

  const handleOpen = () => {
    isLogged ? setOpen(true) : setLoginOpen(true);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const [hasUseful, usefulData, switchUseful] = useUseful({
    id: props.id,
    type: props.type,
    useful: social?.hasUseful ? Useful.YES : Useful.NO,
    count: social?.useful,
    onError: err => snackbars.showError(err.message),
  });

  return (
    <>
      {(usefulData.count > 0) ?
        (usefulData.count == 1 && hasUseful)
          ? <Box {...onClickPrevent(() => handleOpen())}>
            <OnDesktop><Divider /></OnDesktop>
            <Typography variant='body1' margin='8px 0 8px 0' color='textSecondary'>
              Tú lo consideras útil
            </Typography>
          </Box>
          : <Box {...onClickPrevent(() => handleOpen())}>
            <OnDesktop><Divider /></OnDesktop>
            <Typography variant='body1' margin='8px 0 8px 0' color='textSecondary'>
              {`${hasUseful ? 'Tú y ' : ''} ${hasUseful ? usefulData.count - 1 : usefulData.count} persona${(hasUseful ? usefulData.count - 1 : usefulData.count) > 1 ? 's' : ''}
                     lo considera${usefulData.count > 1 ? 'n' : ''} útil`}
            </Typography>
          </Box>
        : <></>}
      <OnDesktop><Divider /></OnDesktop>
      <CardActions className={classes.actions} classes={{ spacing: classes.actionsSpacing }}>
        <Button size={isMobile ? 'small' : 'medium'} startIcon={
          <DoneOutlineOutlined />} {...onClickPrevent(() => isLogged ? switchUseful() : openLoginModal())}
                className={clsx({ useful: hasUseful })}>
          {usefulData.count ? `${usefulData.count} Útil${usefulData.count > 1 ? 'es' : ''}` : 'Útil'}
        </Button>

        <OnMobile>
          { props.isPageView &&
            <ShareMenu
              renderButton={(onClick) => (
                <Button size={isMobile ? 'small' : 'medium'} startIcon={<ShareOutlined />} {...onClickPrevent(onClick)}>
                  Compartir
                </Button>
              )}
              url={window.location.origin + props.redirectUrl}
              text={props.share.text}
              useInHome={true}
              objectType={props.type}
              objectId={props.id}
            />
          }

          {props.type === ObjectType.POST && (user?.id === props?.publisher || user?.isAdmin) && props.isPageView &&
            <Button size={'small'} color='error' startIcon={
              <DeleteOutlineOutlinedIcon />} {...onClickPrevent(() => isLogged ? handleOpenModal() : openLoginModal())}>
              Eliminar
            </Button>
          }

          {props.type === ObjectType.TICKET && (user?.id == 40 || user?.isAdmin) &&
            <Button size={'small'} color='primary' startIcon={
              <CreateOutlinedIcon />} {...onClickPrevent(() => router.push(`/tickets/${props.id}/edit`))}>
              Editar
            </Button>
          }

          {openModal && (
            <Modal open={openModal} onClose={() => setOpenModal(false)} fullScreen>
              <Box>
                <Typography variant='h5' marginBottom='30px'>¿Estás seguro?</Typography>
                <Typography variant='body1' >La publicación será eliminada y no podrá ser recuperada.</Typography>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', marginTop: '25px'}}>
                  <AcoButton color='secondary' variant='outlined' onClick={() => setOpenModal(false)}>
                    Cancelar
                  </AcoButton>
                  <AcoButton onClick={() => deletePost(props.id)} color='error' variant='contained' sx={{marginLeft: '10px'}}>Eliminar</AcoButton>
                </Box>
              </Box>
            </Modal>
          )}
        </OnMobile>

        {!props.isPageView &&
            <Button size={isMobile ? 'small' : 'medium'} startIcon={<MessageOutlined/>} {...onClickPrevent(() => redirectClick())} >
              {social?.comments ? `${social.comments} Comentario${social.comments > 1 ? 's' : ''}` : 'Comentar'}
            </Button>
        }
        {
          props.redirectUrl &&
          <Box className={classes.shareButton}>
            <ShareMenu
              renderButton={(onClick) => (
                <Button size={isMobile ? 'small' : 'medium'} startIcon={<ShareOutlined />} {...onClickPrevent(onClick)}>
                  Compartir
                </Button>
              )}
              url={window.location.origin + props.redirectUrl}
              text={props.share.text}
              useInHome={true}
              objectType={props.type}
              objectId={props.id}
            />
          </Box>
        }
        {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
                                  loginModalProps={{ notRedirect: true }} />}
        <Modal open={open} onClose={handleClose}>
            <Box display='flex' alignItems='center' justifyContent='space-between' marginBottom='16px'>
              <Typography variant='h6'>Marcaron como útil</Typography>
            </Box>
            <Divider />
            <WhoUseful user={user} objectId={props.id} type={props.type} />
        </Modal>
      </CardActions>
    </>
  );
};
