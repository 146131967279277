import { makeStyles } from '@mui/styles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useUserInfoStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '&.post': {
      marginBottom: 15,
    },
  },
  container: {
    display: 'flex',
    '&.ticket': {
      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
        '& .MuiAvatar-root': {
          alignSelf: 'center',
        },
      },
    },
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 15,
    '&.ticket': {
      [StyleResponsive.onMobile]: {
        marginLeft: 0,
        marginTop: 5,
        alignSelf: 'center',
        alignItems: 'center',
      },
    },
    '&.post': {
      maxHeight: 50,
      [StyleResponsive.onMobile]: {
        maxHeight: 44,
        whiteSpace: 'nowrap', 
        maxWidth: '80vw'
      },
    },
  },
  name: {
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    maxHeight: 48,
    [StyleResponsive.onMobile]: {
      fontSize: 14,
      textAlign: 'center',
      '&.post': {
      display: 'inline',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minHeight: '20px'
      },
    },
  },
  since: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.25,
  },
  privacy: {
    color: theme.palette.text.secondary,
    fontSize: 10,
    marginLeft: '5px'
  },
  verified: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    marginLeft: 2,
  },
}));