import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles(() =>
  createStyles({
    comments: {
      position: 'relative'
    },
    listItem: {
      paddingLeft: '0',
      paddingRight: '0',
      '&.reply': {
        padding: 0,
        '&.lastItem' : {
          backgroundColor: 'white'
        }
      },
    },
    listItemAvatar: {
      [StyleResponsive.onDesktop]: {
        minWidth: '56px',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '45px',
      },
      boxShadow: '0px 0px 0 8px white',
    },
    listItemText: {
      marginLeft: '5px',
      '&.reply': {
        background: '#F8F8F8',
        borderRadius: '8px',
        padding: '10px 15px'
      },
    },
    boxResponse: {
      [StyleResponsive.onDesktop]: {
        width: '80%',
        marginLeft: '56px'
      },
      [StyleResponsive.onMobile]: {
        width: '88%',
        marginLeft: '48px'
      },
    }
  })
);
