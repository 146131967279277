import {
  DocumentCard,
  DocumentCardProps,
  NewsletterCard,
  NewsletterCardProps,
  PostCard,
  PostCardProps,
  TicketCard,
  TicketCardProps,
} from '@components/cards/card';
import { BannerCard, BannerCardProps } from '@components/cards/card/Banner/BannerCard';
import { EventCard, OnlineEventCardProps } from '@components/cards/card/OnlineEvent/EventCard';
import { UserData } from '@components/cards/card/shared/UserInfo/UserInfo';
import { UserCard } from '@components/cards/card/User/UserCard';
import { ObjectType } from '@service/model';
import { AcoObject } from '@service/objects/model';
import React from 'react';

export const CARD_LIST_MAPPER: { [key: string]: React.FC | React.VFC } = {
  [ObjectType.DOCUMENT]: (props: DocumentCardProps) => <DocumentCard {...props} />,
  [ObjectType.TICKET]: (props: TicketCardProps) => <TicketCard {...props} />,
  [ObjectType.NEWSLETTER]: (props: NewsletterCardProps) => <NewsletterCard {...props} />,
  [ObjectType.POST]: (props: PostCardProps) => <PostCard {...props} />,
  [ObjectType.BANNER]: (props: BannerCardProps) => <BannerCard {...props} />,
  [ObjectType.USER]: (props: UserData) => <UserCard {...props} />,
  [ObjectType.BUSINESS]: (props: UserData) => <UserCard {...props} />,
  [ObjectType.ONLINE_EVENT]: (props: OnlineEventCardProps) => <EventCard {...props} />,
};

export function renderCard(data: AcoObject) {
  if (!data) {
    return null;
  }

  const CardComponent = CARD_LIST_MAPPER[data.type];

  if (!CardComponent) {
    return null;
  }

  // @ts-ignore
  return <CardComponent {...data.props} />;
}
