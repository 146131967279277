import CommentItem from '@components/Comments/Comment/Comment';
import styles from '@components/Comments/List/CommentsList.styles';
import { Divider, List } from '@mui/material';
import {Comment, ReferenceObject} from '@service/model';
import clsx from 'clsx';
import React from 'react';

interface CommentListProps {
  comments: Comment[];
  replies?: boolean;
  reference?: ReferenceObject;
  deleteComment?: (number) => void;
  editComment?: (Comment) => void;
}

const CommentsList: React.VFC<CommentListProps> = (props) => {
  const classes = styles();

  return (
    <List className={clsx(classes.mainList, { reply: props.replies })}>
      {
        props.comments
          .map((comment, index) => <CommentItem key={comment.id} comment={comment} lastItem={index == props.comments.length-1} reference={props.reference}
                                                                 deleteComment={props.deleteComment} editComment={props.editComment}/>)
          .reduce((prev, curr) =>
            <>
              {prev}
              {!props.replies && <Divider variant={'middle'} component='li' />}
              {curr}
            </>
          )
      }
    </List>
  );
}

export default CommentsList;