import { EventWithoutSelfRegistrationModal } from '@components/modal/OnlineEvents/EventWithoutSelfRegistrationModal';
import { SelfRegistrationEventModal } from '@components/modal/OnlineEvents/SelfRegistrationEventModal';
import { OnlineEventHomeProps } from '@components/OnlineEvent/OnlineEventHome';
import StartedClock from '@components/OnlineEvent/StartedClock';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { Box, Card, Link, Typography } from '@mui/material';
import { OnDesktop, useIsDesktop } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { parseDateFromBackendFormat } from '@shared/helper/date';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import styles from '../OnLiveOnlineEventsHome.styles';


const OnLiveOnlineEventCardHome: React.FC<OnlineEventHomeProps> = ({ onliveEvent, smallOnLiveEvent }) => {
  const classes = styles();

  const [show, setShow] = React.useState(false);
  const [selfRegistrationEventOpen, setSelfRegistrationEventOpen] = useState(false);
  const [registrationEventOpen, setRegistrationEventOpen] = useState(false);
  const date = parseDateFromBackendFormat(onliveEvent.date);

  /*TODO: Pasar noRedirect en props */
  const router = useRouter();
  const redirectUrl = `/events/${onliveEvent.id}`;
  const redirectClick = redirectUrl ? () => router.push(redirectUrl) : undefined;
  const isDesktop = useIsDesktop();
  const { isLogged } = useSession();

  const handleClickDescription = (e) => {
    setShow(!show);
    e.stopPropagation();
  };

  const openSelfRegistrationEventModal = () => {
    setSelfRegistrationEventOpen(true);
  };

  const openRegistrationEventModal = () => {
    setRegistrationEventOpen(true);
  };

  const openEvent = () => {
    onliveEvent.free ? redirectClick() : (isLogged ? redirectClick() : onliveEvent.settings.selfRegistration === '1' ? openRegistrationEventModal() : openSelfRegistrationEventModal());
  };

  return smallOnLiveEvent ? (
    <Box padding='0 10px' marginBottom='10px'>
      <Card className={clsx(`${classes.smallCardOnLive} onLive`, { clickable: isDesktop })} onClick={() => openEvent()}>
        <Box display='flex' flexDirection='column'>
          <Box className={`${classes.smallBoxCategory} onLive`}>
            <Typography variant='body1'>{onliveEvent.title}</Typography>
          </Box>
          <Box className={`${classes.smallOnLive} onLive`}>
            <Box display='flex' alignItems='center'>
              <PlayCircleFilledIcon />
              <Typography variant='subtitle2' className={classes.smallTextOnLive}> Evento en directo </Typography>
            </Box>
            <Typography variant='subtitle2'>
              <StartedClock startDate={date} />
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  ) : (
    <Box className={classes.boxOnLive}>
      <Card className={clsx(classes.cardOnLive, { clickable: isDesktop })} onClick={() => openEvent()}>
        <Box className={classes.onLive}>
          <Box display='flex' alignItems='center'>
            <PlayCircleFilledIcon />
            <Typography variant='subtitle2' fontSize={isDesktop ? 12 : 13} className={classes.textOnLive}> Evento en
              directo </Typography>
          </Box>
          <Typography variant='subtitle2' fontSize={isDesktop ? 12 : 13}>
            <StartedClock startDate={date} />
          </Typography>
        </Box>
        <Box>
          <Box className={`${classes.reproductor} onLive`}>
            <img src={onliveEvent.thumbnails} alt={onliveEvent.title} className={classes.imgEvent} />
            <PlayCircleFilledWhiteOutlinedIcon className={classes.iconPlayOnLive} />
          </Box>
          <Box display='flex' flexDirection='column'>
            {isDesktop && (
              <Box className={classes.boxCategory}>
                <Typography variant='body2'>
                  {onliveEvent.categoryId == 1
                    ? 'Eventos de difusión'
                    : onliveEvent.categoryId == 2
                      ? 'Eventos técnicos'
                      : 'Capacitaciones'}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography variant='h6' fontSize='18px' marginBottom='5px'> {onliveEvent.title}</Typography>
              <OnDesktop>
                {onliveEvent.description &&
                  <Box>
                    <div style={{ display: 'contents', color: '#828282' }}
                         dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onliveEvent.description.slice(0, show ? onliveEvent.description.length : 100)) }} />
                    {!show && onliveEvent.description.length > 100 ? '..' : null}
                    {onliveEvent.description.length > 100 &&
                      <Link onClick={handleClickDescription}
                            className={classes.linkDescription}> {show ? 'Ver menos' : 'Ver más'} </Link>}
                  </Box>
                }
              </OnDesktop>
            </Box>
          </Box>
        </Box>
      </Card>
      {registrationEventOpen &&
        <SelfRegistrationEventModal
          modalProps={{ open: registrationEventOpen, onClose: () => setRegistrationEventOpen(false) }}
          onlineEvent={onliveEvent} />}

      {selfRegistrationEventOpen &&
        <EventWithoutSelfRegistrationModal
          modalProps={{ open: selfRegistrationEventOpen, onClose: () => setSelfRegistrationEventOpen(false) }}
          onlineEvent={onliveEvent} />}
    </Box>
  );
};

export default OnLiveOnlineEventCardHome;
