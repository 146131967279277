import { PostCardProps } from '@components/cards/card';
import { CARD_LIST_MAPPER } from '@components/cards/CardList/renderer/CardList.renderer';
import { ObjectType } from '@service/model';
import { AcoObject } from '@service/objects/model';
import React from 'react';

export const SharedObject = (data: AcoObject) => {
  if (!data) {
    return null;
  }

  const CardComponent = CARD_LIST_MAPPER[data.type];

  if (!CardComponent) {
    return null;
  }

  if (data.type === ObjectType.POST) {
    (data.props as PostCardProps).sharedMin = true;
  }

  // @ts-ignore
  return <CardComponent {...data.props} hideActions={true} />;
};