import { ErrorState } from '@components/States/ErrorState';
import EmptySimilarTickets from '@components/ticket/similarTickets/EmptySimilarTickets';
import SimilarTicketsCard from '@components/ticket/similarTickets/SimilarTicketsCard';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { OnDesktop } from '@providers/Responsive.provider';
import { Ticket } from '@service/model';
import axios from 'axios';
import React, { createRef, useState } from 'react';
import useSWR from 'swr';

import styles from './SimilarTickets.styles';


interface SimilarTicketsProps {
  id: number;
  state?: string;
  content?: string;
}

const SimilarTicketsLayout: React.VFC<SimilarTicketsProps> = (props) => {
  const classes = styles();

  const url = '/api/tickets/similarTickets';
  const fetcher = (url: string) => axios.get<Ticket[]>(url).then((res) => res.data);
  const { data, error } = useSWR(`${url}?ticketId=${props.id}&state=${props.state}&text=${props.content}`, fetcher);

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const Ref = createRef();

  const scrollRight = () => {
    Ref.current['scrollLeft'] += 200;
  };

  const scrollLeft = () => {
    Ref.current['scrollLeft'] -= 200;
  };

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;

    if (scrollLeft >= 80) {
      setShowLeftButton(true);
    } else if (scrollLeft < 5) {
      setShowLeftButton(false);
    }

    if (scrollLeft == 1205) {
      setShowRightButton(false);
    } else if (scrollLeft < 1205) {
      setShowRightButton(true);
    }
  };

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.header} display='flex' flexDirection='row' alignItems='center'
           justifyContent='space-between'>
        <Box display='flex' flexDirection='row'>
          <Typography variant='h6' className={classes.headerText}>Consultas similares</Typography>
        </Box>
      </Box>

      {error && <ErrorState />}
      {!data && <Box display='flex' alignItems='center' height='70px' justifyContent='center'><CircularProgress /></Box>}
      {data && (
        <>
          {data.length === 0 && <EmptySimilarTickets />}
          {data.length > 0 && <Box className={classes.secondaryBox}>

            <Box className={classes.ticketsBox} ref={Ref} onScroll={handleScroll}>
              {data && data.map((ticket) => {
                return (
                  <SimilarTicketsCard ticket={ticket} key={ticket.id} />
                );
              })}
            </Box>

            <OnDesktop>
              <Box className={classes.boxButtons} justifyContent={!showLeftButton ? 'flex-end' : 'space-between'}>

                {showLeftButton && <Button onClick={scrollLeft} className={classes.button}>
                  <NavigateBeforeIcon />
                </Button>
                }

                {showRightButton && <Button onClick={scrollRight} className={classes.button}>
                  <NavigateNextIcon />
                </Button>}
              </Box>
            </OnDesktop>


          </Box>}
        </>
      )}
    </Box>
  );
};

export default SimilarTicketsLayout;
