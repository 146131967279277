/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from '@components/Avatar/Avatar';
import mentionsInputStyle from '@components/common/mentionInputStyle';
import mentionsInputStyleComment from '@components/common/mentionInputStyleComment';
import mentionStyle from '@components/common/mentionStyle';
import { TextCardProps } from '@components/ticket/cards/TextCard';
import { Box } from '@mui/material';
import { useIsMobile } from '@providers/Responsive.provider';
import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';

export function transformTextWithMentions(text) {
  return text.replace(
    /@\[([^)]+)\]\((\[Business\]|\[User\])\[(\d+)\]\)/g,
    (_, mentionName, mentionType, mentionId) => {
      if (mentionType === '[Business]') {
        return `@[e_${mentionId}]`;
      } else if (mentionType === '[User]') {
        return `@[u_${mentionId}]`;
      }
    },
  );
}

const MentionsTextInput: React.FC<{
  TextCardProps: TextCardProps,
  TextRef?: React.MutableRefObject<any>
}> = (props) => {
  const isMobile = useIsMobile();


  const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.TextCardProps.updateText(event.target.value);
  };

  function fetchUsers(query, callback) {
    if (!query) return;

    const url = '/api/mentions';

    fetch(`${url}?keyword=${query}`, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
      .then((res) => res.json())
      .then((data) => {
        return data.cards.map((user) => ({
          display: user.props.nickname,
          id: `[${user.type}][${user.props.id}]`,
          name: user.props.name,
          img: user.props.img,
        }));
      })
      .then((transformedData) => {
        callback(transformedData);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  const handleMentionSelect = (id) => {

    const mentionRegex = /\[([^\]]+)\]\[(\d+)\]/g;

    let match;

    while ((match = mentionRegex.exec(id)) !== null) {
      const objectType = match[1] === 'Business' ? 'e' : 'u';
      const objectId = match[2];

      const newMention = {
        objectType: objectType,
        objectId: objectId,
      };

      props.TextCardProps.updateMentions(newMention);
    }
  };


  return (
    <Box style={{ width: '100%', lineHeight: 1.18 }} margin={props.TextCardProps.isPost ? '0' : '0 5px 0 14px'}>
      <MentionsInput
        inputRef={props.TextRef}
        id={props.TextCardProps.isPost ? 'mentionsPost' : 'mentionsComment'}
        placeholder={props.TextCardProps.isPost ? 'Postea algo... (Podés usar @ para mencionar otros usuarios)' : 'Escribí un comentario'}
        multiline
        size={isMobile ? 'small' : 'medium'}
        value={props.TextCardProps.text}
        onChange={handleChangeTextField}
        style={props.TextCardProps.isPost ? mentionsInputStyle : mentionsInputStyleComment}
      >
        <Mention
          displayTransform={(id, display) => `@${display}`}
          trigger='@'
          data={fetchUsers}
          appendSpaceOnAdd
          onAdd={(id) => handleMentionSelect(id)}
          style={mentionStyle}
          markup='@[__display__](__id__)'

          renderSuggestion={(suggestion, search, highlightedDisplay, index) => {
            const highlightMatch = (text, search) => {
              const parts = text.split(new RegExp(`(${search})`, 'gi'));

              return (
                <span>
                    {parts.map((part, i) => (
                      <span
                        key={i}
                        style={{
                          fontWeight: part.toLowerCase() === search.toLowerCase() ? 'bold' : 'normal',
                          color: part.toLowerCase() === search.toLowerCase() ? '#0A803B' : 'inherit',
                        }}>
                        {part}
                      </span>
                    ))}
                </span>
              );
            };

            return (
              <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                <Avatar alt={suggestion.name} src={suggestion.img} name={suggestion.name}
                        size={{ desktop: 30, mobile: 36 }} />
                <Box style={{ margin: '0px 5px' }}>
                  <div>
                    {highlightMatch(suggestion.name, search)}
                  </div>
                  <div style={{ fontSize: '14px' }}>@{suggestion.display}</div>
                </Box>
              </div>
            );
          }}

        />
      </MentionsInput>


    </Box>
  );
};

export default MentionsTextInput;
