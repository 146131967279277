import { alpha, Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { StyleResponsive } from '@providers/Responsive.provider';


export default makeStyles((theme: Theme) =>
  createStyles({
    cardProfile: {
      display: 'flex',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 2px 6px #DCDCDC',
      '&:hover': {
        overflow: 'visible',
        borderRadius: 8,
        boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
        cursor: 'pointer',
      },

      [StyleResponsive.onDesktop]: {
        padding: '20px 18px 18px 20px',
        flexDirection: 'column',
      },


      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: '18px 60px 27px 60px',
        minHeight: '230px',
      },

    },

    profileInformation: {
      [StyleResponsive.onDesktop]: {
        marginLeft: '24px',
      },

      [StyleResponsive.onMobile]: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      },
    },

    chip: {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
      borderRadius: '6px',
      padding: '12px 4px',
      color: theme.palette.primary.main,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      marginLeft: '5px',
      marginBottom: '5px',
    },

    categoriesBox: {
      [StyleResponsive.onDesktop]: {
        marginTop: 5,
      },
    },

  }));

