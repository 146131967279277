import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.body1.fontWeight,
      textTransform: 'none'
    },
    buttonIcon: {
      fontSize: 12,
      marginLeft: 0
    },
    menu: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      borderRadius: 8
    },
    menuItem: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: theme.typography.subtitle2.fontWeight,
    }
  })
);
