import { Box, Typography } from '@mui/material';
import React from 'react';

const EmptySimilarTickets: React.VFC = () => {

  return (
    <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' style={{ height: '165px' }}>
      <Typography variant='h5' color='secondary' style={{ marginBottom: '16px' }}>
        Aún no hay consultas similares.
      </Typography>
      <Typography variant='body1'>Pronto las tendremos disponibles.</Typography>
    </Box>
  );
};

export default EmptySimilarTickets;
