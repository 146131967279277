import { LinearProgress } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Tweet } from 'react-twitter-widgets';

import { useACOSharedTweetStyles } from './ACOSharedTweet.styles';

export const ACOSharedTweet = ({ tweetId, href }) => {
  const [load, setLoad] = useState(false);
  const [valid, setValid] = useState(true);

  const classes = useACOSharedTweetStyles();

  const onError = () => {
    setLoad(true);
    setValid(false);

    return <div>Tweet no encontrado</div>;
  };


  return (
    <>
      <div className={clsx({ [classes.tweet]: valid })}>
        <Tweet key={href} tweetId={tweetId} onLoad={() => setLoad(true)} renderError={onError} />
      </div>
      {!load && <div className={classes.loading}>Cargando Tweet...<LinearProgress /></div>}
    </>
  );
};