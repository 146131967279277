import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    mainBox: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px',
      boxShadow: '0px 2px 6px #DCDCDC',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      margin: '10px 0',
    },

    ticketsBox: {
      display: 'flex',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        height: '5px',
      },

      '&::-webkit-scrollbar-thumb': {
        height: '5px',
      },
    },

    secondaryBox: {
      height: 'calc(246px - 30px)',
    },

    boxButtons: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      bottom: '100px',
    },

    button: {
      height: '30px',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxSizing: 'border-box',
      minWidth: '30px !important',
      width: '30px !important',
      background: '#FFFFFF',

      '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
      },
    },

    header: {
      marginBottom: '10px',
    },
    headerText: {
      [StyleResponsive.onMobile]: {
        fontSize: '18px',
      },
    },

    card: {
      background: '#FFFFFF',
      border: '1px solid #DCDCDC',
      borderRadius: '8px',
      minWidth: '385px',
      padding: '16px',
      marginRight: '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '&:hover': {
        border: '1px solid #26A65B',
        cursor: 'pointer',
        filter: 'drop-shadow(0px 2px 12px #DCDCDC)',
      },

      [StyleResponsive.onMobile]: {
        minWidth: '320px',
      },
    },

    involvedPeople: {
      display: 'flex',
      [StyleResponsive.onMobile]: {
        justifyContent: 'space-around',
        marginBottom: 10,
      },
      '& .arrow': {
        marginRight: '10px',
        marginLeft: '10px',
        fontSize: 15,
        fontWeight: 'bold',
        [StyleResponsive.onMobile]: {
          fontSize: 12,
          minWidth: 30,
        },
      } as CSSProperties,
    },

    boxArrow: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    question: {

      lineHeight: '20px',
      padding: '0 5px',
      fontSize: 16,
      letterSpacing: 0.25,
      [StyleResponsive.onMobile]: {
        fontSize: 14,
        marginBottom: 5,
      },
      [StyleResponsive.onDesktop]: {
        wordBreak: 'break-word',
        marginTop: 20,
      },
    },
  }),
);
