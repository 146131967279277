import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '20px',
      height: '20px',
      '&.green': {
        fill: theme.palette.primary.main,
      },
      '&.blue': {
        fill: theme.palette.secondary.main,
      },
    },
    menu: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      borderRadius: 8,
      '&.bottom': {
        top: '305px !important',
      },
    },
    menuList: {
      padding: 0,
    },
    menuItem: {
      minWidth: 'unset',
      marginRight: '8px',
    },
    menuText: {
      fontSize: theme.typography.subtitle2.fontSize,
      fontWeight: 500,
      '&.green': {
        color: theme.palette.primary.main,
      },
      '&.blue': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);
