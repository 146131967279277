import { Box, Typography } from '@mui/material';
import { ErrorTractor } from '@svg/errors';
import React from 'react';


interface ErrorStateProps {
  fontSize?: string;
  svgWidth?: string;
}

export const ErrorState: React.FC<ErrorStateProps> = ({ fontSize, svgWidth }) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' padding='10px'>
      <ErrorTractor width={svgWidth || '100px'} />
      <Typography variant='h5' style={{ fontSize: fontSize || '24px', marginTop: '10px' }}>Ups, algo salió mal!</Typography>
    </Box>
  );
};