import { ObjectType } from '@service/model';

const CARD_VIEW_BUTTON_NAME = {
  [ObjectType.TICKET]: 'Consulta',
  [ObjectType.POST]: 'Posteo',
  [ObjectType.NEWSLETTER]: 'Boletín',
  [ObjectType.DOCUMENT]: 'Documento',
}

export function resolveButtonText(type: ObjectType) {
  return CARD_VIEW_BUTTON_NAME[type] || 'Contenido';
}