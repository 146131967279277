import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
    },
    paper: {
      backgroundColor: '#FFFFFF',
      padding: '15px',
      marginBottom: '15px',
    },
    followGrid: {
      marginRight: '15px',
    },
    socialNetworkIcon: {
      '&:hover': {
        fill: theme.palette.primary.main,
      },
    },
    boxFeedbackButton: {
      display: 'flex',
      alignItems: 'center',
      margin: '20px 0 15px 12px',
      '& .MuiSvgIcon-root': {
        color: '#2270A8',
        width: '0.8em',
        height: '0.8em',
      }
    }
  })
);
