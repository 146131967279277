import makeStyles from '@mui/styles/makeStyles';

export const useACOSharedTweetStyles = makeStyles({
  loading: {
    padding: '10px 15px 5px 15px',
    border: '1px solid rgb(204, 214, 221)',
    borderRadius: 15,
    fontSize: 10,
    textAlign: 'center'
  },
  tweet: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      '& > div': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }
    }
  }
});