import { Space } from '@components/Space/Space';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  CircularProgress,
  Divider, FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useRecoverPassword } from '@providers/hooks/RecoverPasswordHook';
import clsx from 'clsx';
import React from 'react';
import validator from 'validator';

import styles from './RecoverPassword.styles';


export interface IFormPassword {
  email: string;
}

interface IRecoverPassword {
  changeState: () => void;
  isModal?: boolean;
}

const RecoverPassword: React.FC<IRecoverPassword> = (props) => {
  const classes = styles();

  const INITIAL_FORM_INPUTS = {
    email: '',
  };

  const [values, setValues] = React.useState<IFormPassword>(INITIAL_FORM_INPUTS);

  const { stateSendPassword, responseBack, sendEmail } = useRecoverPassword();

  const handleChange = (event) => {
    setValues({ ['email']: event.target.value });
  };

  const [showError, setShowError] = React.useState(false);

  const handleEnterPress = () => {
    if (emailValid(values.email)) {
      setShowError(false);
      sendEmail(values.email);
    } else {
      setShowError(true);
    }
  };

  const emailValid = (event) => {
    return !!validator.isEmail(event);
  };

  const enableButton = values.email.length > 3;

  return (
    <Paper classes={{ root: `${classes.paper} ${props.isModal && 'modal'}` }}>
      <Box className={classes.boxTop}>
        <Box className={classes.boxLogin}>
          <Typography variant='h5'>
            Recuperar contraseña
          </Typography>
        </Box>
      </Box>

      <Divider className={classes.divider} orientation='horizontal' variant='middle' />

      <Box>
        <Box marginTop='8px'>
          <Typography className={classes.textHelp}>
            A continuación le solicitamos que ingrese la dirección de email con la cual se registró en Agroconsultas
            Online. Un vez ingresada,
            se le enviará un email con las instrucciones para reestablecer la contraseña.
            En caso de no encontrar el email, verificar si este se encuentra en la carpeta Spam o Correo no deseado
          </Typography>
        </Box>

        <Divider className={classes.divider} orientation='horizontal' variant='middle' />

        <form autoComplete='off' className={classes.form}>
          <Box className={classes.textInputContainer}>
            <TextField
              required
              autoFocus
              id='email'
              label='Email'
              margin='normal'
              variant='outlined'
              autoComplete='off'
              size='medium'
              error={showError || responseBack && (responseBack.error == true)}
              onChange={(e) => handleChange(e)}
              className={clsx(classes.textFieldEmail, responseBack && (responseBack.error == false && classes.textFieldWithMessage))}
              inputProps={{
                className: classes.inputEmail,
                autoComplete: 'email',
                form: {
                  autoComplete: 'off',
                },
              }} />

            {showError && (
              <FormHelperText id='error-text' className={classes.formHelperText}>
                <ErrorIcon sx={{ width: '18px', height: '18px', marginBottom: '3px' }} />
                <Typography variant='body1' component='span' className={classes.textFormHelperText}>
                  Debe ingresar un email válido
                </Typography>
              </FormHelperText>
            )}

            {responseBack && (responseBack.error == false && (
              <Box display='flex' maxWidth='360px' alignItems='flex-start' width={'100%'} justifyContent='center'>
                <Box display='flex' justifyContent='center' alignItems='flex-start' paddingTop='2px'>
                  <InfoIcon fontSize='small' className={classes.inputMessageIcon} />
                </Box>
                <Space vertical size={8} />
                <Box flexGrow={1}>
                  <label className={classes.inputMessage}>
                    {responseBack.response}
                  </label>
                </Box>
              </Box>
            ))}

            {responseBack && (responseBack.error == true && (
              <FormHelperText id='error-text' className={classes.formHelperText}>
                <ErrorIcon sx={{ width: '18px', height: '18px', marginBottom: '3px' }} />
                <Typography variant='body1' component='span' className={classes.textFormHelperText}>
                  {responseBack.response}
                </Typography>
              </FormHelperText>
            ))}

          </Box>
        </form>


        <Box className={classes.boxButtons}>
          <Box display='flex' justifyContent='center' flexGrow='1'>
            <Button color='secondary' disableRipple className={classes.buttonBack} onClick={props.changeState}>
              Volver</Button>
          </Box>

          <Box>
            {!stateSendPassword ?
              <Button onClick={handleEnterPress}
                      classes={{ root: classes.buttonRecorverPass, disabled: classes.disabled }}
                      disabled={!enableButton}>
                Recuperar clave
              </Button>
              : <Box className={classes.circularProgress}>
                <CircularProgress />
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default RecoverPassword;
