import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useDocumentCardStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: 10,
  },
  content: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.5,
    color: theme.palette.text.primary,
    [StyleResponsive.onMobile]: {
      fontSize: 14
    }
  },
  date: {
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.text.secondary
  },
  iframePdf: {
    width: '100%',
    [StyleResponsive.onDesktop]: {
      height: '85vh'
    },
    [StyleResponsive.onMobile]: {
      height: '70vh'
    }
  },
  attachmentButton: {
    width: '100%',
    padding: '15px',
    textTransform: 'none',
    borderRadius: '8px',
    [StyleResponsive.onDesktop]: {
      justifyContent: 'space-between'
    },
    [StyleResponsive.onMobile]: {
      justifyContent: 'center'
    }
  },
  attachmentDownloadText: {
    color: '#2270A8',
    fontSize: '16px',
    fontWeight: 500
  },
  attachmentDownloadIcon: {
    fontSize: '19px',
    marginRight: '6px'
  },
  attachmentDownloadInfo : {
    color: '#828282',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'end'
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%'
  },
  videoIframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
}));
