import { UploadFilesStore, useUploadFilesStore } from '@providers/hooks/fileuploads/UploadFilesStoreContext';
import { ObjectType, UserRegisterLocationData } from '@service/model';
import { UserSessionData } from '@service/model/session';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';


export enum PostStatus {
  INITIAL,
  LOADING,
  ERROR,
  OK,
}

export interface PostErrors {
  text?: boolean,
  publicador?: string,
  archivos?: string,
}

interface BackErrors {
  contenido: string;
  publicador: string;
  archivos: string;
}

export interface sharedObjectProps {
  objectType: ObjectType,
  objectId: number | string
}

export interface MentionsProps {
  objectType: string,
  objectId: string,
}

interface NewPostContextInterface {
  viewForm: boolean;
  postText: string;
  mentions: MentionsProps[];
  privacy: number;
  publisherId: number;
  statePost: PostStatus;
  uploadFilesStore: UploadFilesStore;
  postLocation: UserRegisterLocationData;
  postErrors: PostErrors;
  sharedObject: sharedObjectProps;
  removePostLocation: () => void;
  updatePostText: (text: string) => void;
  updateMentions: (mention: []) => void;
  updatePostPublisher: (publisher: number) => void;
  updatePostPrivacy: (privacy: number) => void;
  updatePostLocation: (newLocation: UserRegisterLocationData) => void;
  updateViewForm: (newValue: boolean) => void;
  createPost: (user: UserSessionData, publisherId: number, text: string) => void;
  restartFormPost: () => void;
  updateSharedObject: (object: sharedObjectProps) => void;
}


const useNewPost: () => NewPostContextInterface = () => {

  const [viewForm, setViewForm] = useState<boolean>(false);
  const uploadFilesStore = useUploadFilesStore();
  const [postLocation, setPostLocation] = useState<UserRegisterLocationData>();
  const [postText, setPostText] = useState<string>('');
  const [privacy, setPrivacy] = React.useState(0);
  const [mentions, setMentions] = useState([]);
  const [publisherId, setPublisherId] = useState<number>(undefined);
  const [statePost, setStatePost] = useState(PostStatus.INITIAL);

  const [postErrors, setPostErrors] = React.useState<PostErrors>({
    text: false,
    publicador: undefined,
  });

  const [sharedObject, setSharedObject] = useState(null);

  const updateSharedObject = (object: sharedObjectProps) => {
    setSharedObject(object);
  };

  const updatePostText = (text: string) => {
    setPostText(text);
  };

  const updatePostPublisher = (publisher: number) => {
    setPublisherId(publisher);
  };

  const updatePostPrivacy = (privacy: number) => {
    setPrivacy(privacy);
  };

  const updatePostLocation = (newTicketLocation: UserRegisterLocationData) => {
    setPostLocation(newTicketLocation);
  };

  const removePostLocation = () => {
    setPostLocation(null);
  };

  const backendErrors = (errors: BackErrors) => {

    setPostErrors(
      {
        text: !!errors.contenido,
        publicador: errors.publicador,
        archivos: errors.archivos,
      })
    ;
  };

  const updateMentions = (mention) => {
    setMentions(mentions.concat(mention));
  };

  const createPost = (user: UserSessionData, publisherId, text) => {
    setStatePost(PostStatus.LOADING);

    axios.post<ResponseResult>('/api/posts/new', {
      privacy: privacy,
      text: text,
      mentions: mentions,
      user: user,
       publisherId: publisherId,
      location: postLocation,
      attachments: uploadFilesStore.getAll(),
      objects: sharedObject,
    })
      .then((res) => {

        if (res.data.errors) {
          setStatePost(PostStatus.ERROR);
          // @ts-ignore
          backendErrors(res.data.errors);
        } else {
          setStatePost(PostStatus.OK);
        }

      })
      .catch(() => {
        setStatePost(PostStatus.ERROR);
      });
  };

  const updateViewForm = (newValue: boolean) => {
    setViewForm(newValue);
  };

  const restartFormPost = () => {
    setPostText('');
    setMentions([]);
    setPostLocation(null);
    setPrivacy(0);
    setStatePost(PostStatus.INITIAL);
    setSharedObject(null);
  };

  return {
    viewForm,
    postText,
    mentions,
    privacy,
    publisherId,
    postLocation,
    uploadFilesStore,
    statePost,
    postErrors,
    sharedObject,
    updateViewForm,
    updatePostText,
    updateMentions,
    updatePostPublisher,
    updatePostLocation,
    updatePostPrivacy,
    removePostLocation,
    createPost,
    restartFormPost,
    updateSharedObject,
  };
};


const NewPostProvider: React.FC = ({ children }) => {

  const newTicket = useNewPost();

  return (
    <NewPostContext.Provider value={newTicket}>
      {children}
    </NewPostContext.Provider>
  );
};

export default NewPostProvider;
export const NewPostContext = createContext<NewPostContextInterface>(null);

export const useNewPostContext = () => useContext(NewPostContext);


