import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { StyleResponsive } from '@providers/Responsive.provider';


export default makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      [StyleResponsive.onDesktop]: {
        width: '80vw',
        height: '80vh',
        margin: '20px',
        overflow: 'hidden',
        padding: '15px',
        overflowY: 'auto',
      },
      [StyleResponsive.onMobile]: {
        width: '100vw',
        height: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '1200px',
        maxHeight: '900px',
      },


      '& .Mui-active': {
        '& .MuiStepConnector-lineHorizontal': {
          borderColor: theme.palette.primary.main,
        },
      },

      '& .Mui-completed': {
        '& .MuiStepConnector-lineHorizontal': {
          borderColor: theme.palette.primary.main,
        },
      },


    },

    buttons: {
      color: '#828282',
      '& > *': {
        margin: '10px 0',
      },
      display: 'flex',
      alignItems: 'flex-start',
      alignContent: 'flex-start',
      flexDirection: 'column',
    },

    svg: {
      background: 'rgba(34, 112, 168, 0.1)',
      borderRadius: '45px',
      [StyleResponsive.onDesktop]: {
        width: '373px',
      },
      [theme.breakpoints.down(1200)]: {
        display: 'none',
      },
    },

    buttonVerified: {
      textTransform: 'none',
      padding: '10px 20px',
      [StyleResponsive.onMobile]: {
        marginLeft: '-18px',
      },
    },
    homeButton: {
      marginTop: '70px',
      [StyleResponsive.onMobile]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },

    btnWhite: {
      marginLeft: '6px',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxSizing: 'border-box',

      '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
      },
    },

    paperVerified: {
      margin: '20px',
      padding: '15px',
      width: '60vw',
      [StyleResponsive.onMobile]: {
        width: '100vw',
        height: '100%',
      },
    },

    content: {
      overflowY: 'auto',
      [StyleResponsive.onMobile]: {
        height: '80vh',
      },
      [StyleResponsive.onDesktop]: {
        marginTop: '10px',
        paddingBottom: '10px',
        height: '100%',
      },
    },

    categories: {
      display: 'flex',
      padding: '6px',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',
      marginBottom: '15px',
      marginTop: '10px',

      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
        alignItems: 'flex-end',
      },

      '&.error': {
        border: '1px solid red',
      },
    },

    expert: {
      display: 'flex',
      padding: '6px',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',
      marginBottom: '15px',

      [StyleResponsive.onDesktop]: {
        justifyContent: 'space-between',
        width: '100%',
      },

      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
      },
      '&.error': {
        border: '1px solid red',
      },
    },


    ticketTextBox: {
      display: 'flex',
      border: `1px solid ${theme.palette.grey['500']}`,
      boxSizing: 'border-box',
      borderRadius: '8px',
      width: '100%',

      [StyleResponsive.onDesktop]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginLeft: '11px',
      },
      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '14px',
      },
    },

    ticketTextInputComponents: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },

    textFieldInput: {
      background: '#FFFFFF',
      width: '100%',

      '& .MuiFilledInput-root': {
        backgroundColor: '#FFFFFF',
      },

    },

    buttonsArea: {
      display: 'flex',
      height: '38px',
      width: '100%',
      background: theme.palette.background.default,
    },

    boxSelectPrivacy: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '12px',
    },

    select: {
      '& .MuiButton-root': {
        color: theme.palette.text.primary,
      },
      '& .MuiMenuItem-root': {
        color: theme.palette.text.primary,
      },
    },

    button: {
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.success.main,
      },
      '&.error': {
        color: theme.palette.error.main,
      },
    },

    boxLocation: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
      padding: '11px 19px',
      background: theme.palette.background.default,

      '& > .boxGeneral': {
        display: 'flex',

        [StyleResponsive.onDesktop]: {
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        },

        [StyleResponsive.onMobile]: {
          flexDirection: 'column',
          width: '100%',
        },

        '& > .boxButtons': {
          [StyleResponsive.onDesktop]: {
            width: '50%',
            marginLeft: '50px',
          },
          [StyleResponsive.onMobile]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          },

        },

        '& > .inputLocation': {
          marginBottom: '10px',
          [StyleResponsive.onDesktop]: {
            width: '60%',
            marginBottom: '10px',
          },
        },
      },
    },

    chipLocation: {
      background: theme.palette.secondary.main,
      color: theme.palette.text.disabled,
      padding: '8px 20px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '6px',
      [StyleResponsive.onDesktop]: {
        minWidth: '290px',
      },
      [StyleResponsive.onMobile]: {
        width: '100%',
      },
    },

    root: {
      borderRadius: 8,
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxShadow: '0px 2px 6px #DCDCDC',
      display: 'flex',
      padding: '15px',
      alignItems: 'center',
      justifyContent: 'space-between',


      [StyleResponsive.onDesktop]: {
        height: '85px',
      },


      '&:hover': {
        overflow: 'visible',
        borderRadius: 8,
        '& .container': {
          borderRadius: 8,
          boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
        },
        cursor: 'pointer',
      },
    },


    chipTag: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: alpha(theme.palette.primary.main, 0.2),
      borderRadius: '6px',
      color: theme.palette.primary.main,

      [StyleResponsive.onDesktop]: {
        minHeight: '28px',
        padding: '4px 12px',
        marginLeft: '4px',
        /*width: 'max-content',*/
      },

      [StyleResponsive.onMobile]: {
        height: '20px',
        marginLeft: '5px',
        padding: '5px',
        /*  width: 'max-content',
          minWidth: 'fit-content',*/
      },

      '&.select': {
        '&:hover': {
          cursor: 'pointer',
        },
      },

      '&.noSelect': {
        '& .MuiChip-deleteIcon': {
          display: 'none',
        },
      },

      '& .MuiChip-deleteIcon': {
        color: theme.palette.primary.main,
        '&:hover': {
          color: alpha(theme.palette.primary.main, 0.7),
        },
      },
    },

    boxMenuNewTicket: {
      display: 'flex',

      [StyleResponsive.onDesktop]: {
        overflow: 'scroll',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },

    boxExperts: {
      display: 'flex',
      width: '100%',

      [StyleResponsive.onDesktop]: {
        justifyContent: 'space-evenly',
        marginBottom: '15px',
      },

      [StyleResponsive.onMobile]: {
        flexDirection: 'column',
      },

    },

    optionMenuBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: '40px 30px',
      border: `2px solid ${theme.palette.grey['500']}`,
      boxSizing: 'border-box',
      borderRadius: '8px',


      [StyleResponsive.onDesktop]: {
        width: '100%',
        maxWidth: '460px',
        minHeight: '240px',
      },

      [StyleResponsive.onMobile]: {
        margin: '12px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },

    childrensThematic: {
      overflow: 'hidden',
      letterSpacing: 0.25,
      lineHeight: '20px',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },

    modalProfile: {
      position: 'absolute',
      top: '43%',
      left: '19.2%',
      transform: 'translate(-50%, -50%)',
      minHeight: '200px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      padding: '10px 8px',
      width: 'auto',
      border: `1px solid ${theme.palette.grey['500']}`,
      boxSizing: 'border-box',
      '& .MuiBox-root': {
        width: 'auto',
      },
      '& .MuiModal-backdrop ': {
        opacity: '0',
      },
    },

    buttonIcon: {
      padding: '0 !important',
    },

    boxCategories: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      overflowY: 'auto',
      minHeight: '30px',
      '&::-webkit-scrollbar': {
        height: '5px',
      },

      '&::-webkit-scrollbar-thumb': {
        height: '5px',
      },
    },

    boxSuggestionCategories: {
      display: 'flex',
      alignItems: 'center',
      overflowY: 'auto',
      minHeight: '30px',
      padding: '15px',
      border: '1px solid #DCDCDC',
      borderRadius: '8px',
      margin: '35px 0',
      cursor: 'pointer',
      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      '&::-webkit-scrollbar': {
        height: '5px',
      },

      '&::-webkit-scrollbar-thumb': {
        height: '5px',
      },
    },

    boxLabels: {
      display: 'flex',
      marginBottom: '15px',
      maxWidth: '690px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        height: '5px',
      },

      '&::-webkit-scrollbar-thumb': {
        height: '5px',
      },
    },

    thematicNameContainer: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '4px 12px',
      whiteSpace: 'nowrap',
      minHeight: '28px'
    },

    thematicName: {
      fontSize: '0.8125rem',
      color: '#26A65B',
      whiteSpace: 'nowrap'
    },

    /*TODO: List*/


    mainBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      width: '100%',


      [StyleResponsive.onMobile]: {
        height: '25px',
      },

    },


    buttonList: {
      [StyleResponsive.onMobile]: {
        display: 'flex',
        width: '100%',
        overflow: 'auto',
      },

      [StyleResponsive.onDesktop]: {
        display: 'flex',
        width: '100%',
        overflow: 'auto',
      },
    },


    test: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },


    search: {
      border: `2px solid ${theme.palette.primary.main} !important`,
    },

    textLocation: {
      marginLeft: '4px',
      textTransform: 'none',
      textWrap: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '16px',
    },
  }),
)
;
