import Button from '@components/AcoButtons/Button';
import { useFinishRegisterStyles } from '@components/modal/FinishRegister/FinishRegister.styles';
import { Modal, ModalProps } from '@components/modal/Modal';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { ResendEmail } from '@service/model';
import axios from 'axios';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React from 'react';

interface FinishRegister {
  name: string,
  email: string,
}


export const FinishRegister: React.VFC<{
  ModalProps: ModalProps,
  FinishRegister: FinishRegister
}> = ({ ModalProps: props, FinishRegister: propsFinishRegister }) => {

  const classes = useFinishRegisterStyles();
  const { snackbars } = useCustomSnackbar();
  const router = useRouter();

  const handleClose = () => {
    props.onClose && props.onClose('', 'escapeKeyDown');
    router.push({ pathname: '/' }, undefined, { shallow: true });
  };

  const sendEmail = async () => {
    axios
      .get<ResendEmail>('/api/user/resend-email', { params: { email: propsFinishRegister.email } })
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Email enviado');
        } else {
          snackbars.showError('No pudimos enviar el email');
        }
      })
      .catch((err) => {
        console.warn('Cannot check email for user', err);
        snackbars.showError('No pudimos enviar el email');
      })
      .finally(() => {
        handleClose();
        router.push({ pathname: '/' }, undefined, { shallow: true });
      });
  };


  return (
    <>
      <Modal {...(props as ModalProps)} onClose={handleClose}
             maxWidth='sm' fullWidth
             sx={{ marginTop: '126px', '& .MuiDialog-container': { alignItems: 'flex-start' } }}>
        <DialogContent className={classes.content}>
          <Typography variant='h4' color='#26A65B' marginBottom='20px'>Bienvenido a Agroconsultas</Typography>
          <Typography variant='subtitle1' fontSize='18px'
                      marginBottom='23px'>¡Hola {propsFinishRegister.name}! </Typography>
          <Box marginBottom='23px'>
            <Typography variant='subtitle1' fontSize='18px' display='inline'> Te enviamos un email a </Typography>
            <Typography variant='subtitle1' fontSize='18px' display='inline' word-wrap='break-word'
                        color='#26A65B'>{propsFinishRegister.email}</Typography>
            <Typography variant='subtitle1' display='inline' fontSize='18px'> para que puedas activar tu
              cuenta.</Typography>
          </Box>
          <Box marginBottom='25px'>
            <Typography variant='subtitle1' display='inline' color='#828282' fontWeight='400'> Debés activarla dentro
              de las próximas</Typography>
            <Typography variant='subtitle1' marginBottom='25px' color='#828282' display='inline'> 48
              horas.</Typography>
            <Typography variant='subtitle1' marginBottom='25px' color='#828282' fontWeight='400'>De lo contrario,
              deberás realizar el proceso de registro nuevamente.</Typography>
          </Box>
          <Typography variant='body2' color='#828282'> Si no recibís el email de activación en el transcurso de los
            próximos minutos te recomendamos que revises la carpeta Spam o Correo no deseado.</Typography>

          <Typography variant='body2' color='#828282'> Ante cualquier inconveniente contactarse a
            contacto@agroconsultasonline.com.ar</Typography>
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button color='secondary' onClick={sendEmail}>
            Reenviar email
          </Button>
          <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                  onClick={handleClose}>
            Ir a Agroconsultas
          </Button>
        </DialogActions>
      </Modal>
    </>
  );
};
