import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useFinishRegisterStyles  = makeStyles({
  content: {
    marginTop: 25,
    minWidth: 500,
    padding: 0,
    overflow: 'clip',

    [StyleResponsive.onMobile]: {
      minWidth: 'auto',
    },
  },
  actions: {
    padding: 0,
    margin: '35px 0 10px',
    '& > .postButton': {
      marginLeft: 30,
      [StyleResponsive.onMobile]: {
        marginLeft: 10,
      },
    },
    '& .MuiButton-root': {
      height: 48
    },
  },
});