import { ACOLink } from '@components/WithLinks/ACOLink/ACOLink';
import { ACOSharedFacebook } from '@components/WithLinks/ACOSharedFacebook/ACOSharedFacebook';
import { ACOSharedInstagram } from '@components/WithLinks/ACOSharedInstagram/ACOSharedInstagram';
import { ACOSharedLinkedin } from '@components/WithLinks/ACOSharedLinkedin/ACOSharedLinkedin';
import { ACOSharedTweet } from '@components/WithLinks/ACOSharedTweet/ACOSharedTweet';
import { LinkifyMatch } from '@components/WithLinks/model';
import { linkify } from '@components/WithLinks/WithLinks.linkify';
import React from 'react';

export function defaultMatchDecorator(text: string): Array<LinkifyMatch> {
  return linkify.match(text);
}

const TW_REGEX = /https?:\/\/(?:www\.)?(?:twitter\.com|x\.com)\/([a-zA-Z0-9_]+)\/status\/([0-9]+)[^ <]*/g;
const LN_REGEX = /https?:\/\/(?:www\.)?linkedin\.com\/embed\/feed\/update\/urn:li:share:([0-9]+)[^ <]*/g;
const IG_REGEX = /https?:\/\/(?:www\.)?instagram\.com\/(?:p|reel)\/([a-zA-Z0-9_]+)\/*/g;
/* eslint-disable-next-line max-len */
const FB_REGEX = /https?:\/\/(?:www\.)?(?:facebook\.com\/(?:[a-zA-Z0-9_.]+\/posts\/[a-zA-Z0-9_]+|reel\/[a-zA-Z0-9_]+|photo\/\?fbid=[a-zA-Z0-9_]+&set=[a-zA-Z0-9_.]+)|fb\.watch\/[a-zA-Z0-9_]+)\/?[^ <]*/g;

export function defaultComponentDecorator(decoratedHref: string, decoratedText: string, key: number): React.ReactNode {

  if (TW_REGEX.test(decoratedHref)) {
    TW_REGEX.lastIndex = 0;
    const tweetId = decoratedHref.replace(TW_REGEX, (match, userId, tweetId) => tweetId);

    return <ACOSharedTweet href={decoratedHref} tweetId={tweetId} key={`tweet ${tweetId}`} />;
  }

  if (LN_REGEX.test(decoratedHref)) {
    LN_REGEX.lastIndex = 0;

    return <ACOSharedLinkedin href={decoratedHref} />;
  }

  if (IG_REGEX.test(decoratedHref)) {
    IG_REGEX.lastIndex = 0;

    return <ACOSharedInstagram href={decoratedHref} />;
  }

  if (FB_REGEX.test(decoratedHref)) {
    FB_REGEX.lastIndex = 0;

    return <ACOSharedFacebook href={decoratedHref} />;
  }

  return <ACOLink href={decoratedHref} key={key} text={decoratedText} />;
}

export function matchSocialMedia(text: string): boolean {
  const twMatch = TW_REGEX.test(text);
  const lnMatch = LN_REGEX.test(text);
  const igMatch = IG_REGEX.test(text);
  const fbMatch = FB_REGEX.test(text);

  TW_REGEX.lastIndex = 0;
  LN_REGEX.lastIndex = 0;
  IG_REGEX.lastIndex = 0;
  FB_REGEX.lastIndex = 0;

  return twMatch || lnMatch || igMatch || fbMatch;
}

