import Button from '@components/AcoButtons/Button';
import { Modal, ModalProps } from '@components/modal/Modal';
import {
  Box,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRecoverPassword } from '@providers/hooks/RecoverPasswordHook';
import { useSession } from '@providers/user/SessionContext';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({

  container: {
    boxSizing: 'border-box',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  },

}));

export const UserLogged: React.VFC<ModalProps> = (props) => {

  const classes = useStyles();
  const [step, setStep] = useState(1);
  const { user, signOut } = useSession();
  const { responseBack, sendEmail } = useRecoverPassword();

  const handleClose = () => {
    props.onClose && props.onClose('', 'escapeKeyDown');

    if (step === 2) {
      signOut();
    }
  };

  useEffect(() => {
    if (responseBack && !responseBack.error) {
      setStep(2);
    }
  }, [responseBack]);

  useEffect(() => {
    if (step === 2) {
      const timer = setTimeout(() => {
        signOut();
      }, 10000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      <Modal {...props} onClose={handleClose}
             maxWidth='md' fullWidth
             sx={{ marginTop: '126px', '& .MuiDialog-container': { alignItems: 'flex-start' } }}>
        <DialogContent>
          {step === 1 && (
            <>
              <Typography variant='h4' color='#26A65B' marginBottom='20px'>
                Actualización de contraseña
              </Typography>
              <Typography fontSize='18px' display='inline'>
                Por motivos de seguridad para poder seguir navegando debes actualizar tu contraseña.
              </Typography>
            </>
          )}

          {step === 2 && (
            <>
              <Typography variant='h4' color='#26A65B' marginBottom='20px'>
                Actualización de contraseña
              </Typography>

              <Box className={classes.container}>
                <Typography fontSize='18px' display='inline'>
                  Te hemos enviado un mail a la dirección <b>{user?.email}</b> para que actualices tu contraseña.
                </Typography>

                <Typography fontSize='16px' display='inline' style={{ marginTop: '10px' }}>Ante cualquier inconveniente
                  escribinos
                  a: <b><u><a
                    href='mailto:contacto@agroconsultasonline.com.ar'>contacto@agroconsultasonline.com.ar</a></u></b></Typography>

                <Typography fontSize='16px' display='inline' style={{ marginTop: '10px' }}>Tu sesión se cerrará
                  automáticamente en unos segundos.</Typography>
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          {step === 1 && (
            <Button
              color='primary'
              variant='contained'
              size='large'
              onClick={() => sendEmail(user.email, true)}
              sx={{ backgroundColor: '#26A65B', borderRadius: '20px', textTransform: 'none' }}
            >
              Actualizar contraseña
            </Button>
          )}
          {step === 2 && (
            <>
              <Button
                color='primary'
                variant='contained'
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </>
          )}
        </DialogActions>
      </Modal>
    </>
  );
};
