import { alpha } from '@mui/material/styles';
import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useTicketAnswerStyle = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 35
  },
  avatarContainer: {
    marginRight: 12,
    position: 'relative',
    '&::before': {
      content: '""',
      backgroundColor: theme.palette.primary.main,
      bottom: 'calc(100% + 5px)',
      borderRadius: 8,
      width: 3,
      height: 18,
      left: '50%',
      position: 'absolute'
    } as CSSProperties
  },
  answerContainer: {
    borderRadius: 8,
    fontSize: 14,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    position: 'relative',
    width: '100%',
    '& .gradient': {
      bottom: 0,
      height: '45%',
      width: '100%',
      position: 'absolute',
      background: 'linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%);',
      opacity: 0.9
    } as CSSProperties
  },
  answerText: {
    padding: '15px 12px',
    [StyleResponsive.onMobile]: {
      padding: '6px 12px'
    } as CSSProperties,
    '& .expert': {
      lineHeight: '25px',
      '& .name': {
        fontSize: 16,
        fontWeight: 500
      } as CSSProperties
    } as CSSProperties,
    '& .answer': {
      fontSize: 16,
      marginTop: 10,
      letterSpacing: 0.25,
      lineHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
      [StyleResponsive.onMobile]: {
        fontSize: 14
      },
      [StyleResponsive.onDesktop]: {
        wordBreak: 'break-word',
      },
    } as CSSProperties
  },
  waitingBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    [StyleResponsive.onMobile]: {
      alignItems: 'flex-start',
    },
    [StyleResponsive.onDesktop]: {
      wordBreak: 'break-word',
    },
  }
}));