import Radio, { RadioProps } from '@mui/material/Radio';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 16,
    height: 16,
    borderRadius: 16,
    borderWidth: 2,
    borderStyle: 'solid',
    'input:disabled ~ &': {
      borderColor: '#828282',
    },
  },
  checkedIcon: {
    width: 16,
    height: 16,
    borderRadius: 16,
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      content: '""',
    },
    'input:disabled ~ &': {
      backgroundColor: '#828282',
    }
  },
  iconPrimary: {
    borderColor: theme.palette.primary.main
  },
  iconSecondary: {
    borderColor: theme.palette.secondary.main
  },
  checkedIconPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  checkedIconSecondary: {
    backgroundColor: theme.palette.secondary.main,
  }
}));

export const RadioButton = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      icon={<span className={clsx(classes.icon, props.color == 'secondary' ? classes.iconSecondary : classes.iconPrimary)} />}
      checkedIcon={<span className={clsx(classes.checkedIcon, props.color == 'secondary' ? classes.checkedIconSecondary : classes.checkedIconPrimary )} />}
      {...props}
    />
  );
};