import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { Box, Chip } from '@mui/material';
import React from 'react';

import styles from './Tags.styles';


interface TagsProps {
  tags: string[];
}

const Tags: React.FC<TagsProps> = ({tags}) => {
  const classes = styles();

  return (
    <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center' alignContent='center' flexWrap='wrap' className={classes.tagsContainer}>
      <ACOTooltip title='Etiquetas' arrow><LabelOutlinedIcon /></ACOTooltip>
      {tags.map((keyword, index) => <Chip key={index} label={keyword} className={classes.tag} />)}
    </Box>
  );
};

export default Tags;
