import React from 'react';

export const onClickPrevent = (onClick) => {
  return { onClick: prevent(onClick) }
}

export const prevent = (onClick) => {
  return async (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      return;
    }

    onClick && onClick(e);
    e.stopPropagation();
    e.preventDefault();
  }
}