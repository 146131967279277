import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { makeResponsiveStyles, StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme) =>
  createStyles({
    mainBox: {
      width: '100%',
    },

    secondaryBox: {
      width: '85%',
      margin: '0 5%',
    },

    boxText: {
      display: 'flex',
    },

    paper: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',

      [StyleResponsive.onDesktop]: {
        padding: '29px 27px 28px 29px',
        minWidth: '413px',
        marginTop: '10px',
        marginBottom: '10px',

        '&.modal': {
          marginTop: '0px',
          marginBottom: '0px',
          borderLeft: '1px solid #DCDCDC',
          boxShadow: 'unset',
          backgroundColor: '#F8F8F8',
          border: 'unset',
        },

        '&.withoutBranding': {
          border: '1px solid #DCDCDC',
        },

      },
      [StyleResponsive.onMobile]: {
        padding: '23px 10px 14px 12px',
        minWidth: '320px',
        width: '70%',
        minHeight: '520px',
        marginTop: '14px',
        marginBottom: '14px',
        '&.modal': {
          width: '100%',
        },
      },
    },

    boxTop: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    buttons: {
      boxSizing: 'border-box',
      borderRadius: '8px',
      minWidth: '170px',
      marginBottom: '18px',
      marginRight: '10px',
      justifyContent: 'flex-start',
      padding: '4px 6px',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '120px',
        minHeight: '42px',
        marginBottom: '10px',
        marginRight: '10px',
      },

      '&.facebook': {
        border: `2px solid ${theme.palette.secondary.main}`,
        color: `${theme.palette.secondary.main}`,
      },

      '&.google': {
        border: `2px solid ${theme.palette.error.main}`,
        color: `${theme.palette.error.main}`,
      },
    },

    boxLogin: {
      [StyleResponsive.onDesktop]: {
        marginBottom: '12px',
      },
      [StyleResponsive.onMobile]: {
        marginBottom: '12px',
      },
    },

    boxConnect: {
      [StyleResponsive.onDesktop]: {
        marginBottom: '10px',
      },
      [StyleResponsive.onMobile]: {
        marginBottom: '8px',
      },
    },

    textButton: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      [StyleResponsive.onDesktop]: {
        marginLeft: '16px',
      },
      [StyleResponsive.onMobile]: {
        marginLeft: '3px',
      },
    },

    divider: {
      [StyleResponsive.onDesktop]: {
        margin: 'auto 0px',
      },
      [StyleResponsive.onMobile]: {
        margin: 'auto 4px',
      },
    },

    boxButtons: {
      marginTop: '25px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    boxButtonLogin: {
      [StyleResponsive.onMobile]: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0 30px',
      },
    },

    buttonLogin: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      width: '153px',
      height: '52px',
      color: 'white',
      letterSpacing: '0.1px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:disabled': {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey['500'],
      },
    },

    disabled: {},

    inputMessage: {
      fontSize: '12px',
      color: theme.palette.primary.main,
      fontWeight: 400,
    },

    inputMessageIcon: {
      color: theme.palette.primary.main,
      height: '16px',
      width: '16px',
    },

    boxBottom: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '12px',
      [StyleResponsive.onDesktop]: {
        justifyContent: 'start',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '260px',
        justifyContent: 'space-around',
      },
    },

    register: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      [StyleResponsive.onMobile]: {
        border: '2px solid #26A65B',
        boxSizing: 'border-box',
        borderRadius: '6px',
        padding: '9px 12px',
      },
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },

    icon: {
      minHeight: '22px',
      minWidth: '22px',
      marginRight: '14px',
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
    },

    inputCheckContainer: {
      position: 'absolute',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      top: '4px',
      right: '10px',
    },

    inputCheck: {
      color: theme.palette.primary.main,
    },

    textFieldEmail: {
      [StyleResponsive.onDesktop]: {
        minWidth: '358px',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '266px',
        width: '90%',
      },
    },

    textFieldWithMessage: {
      '& input:valid + fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        borderWidth: 2,
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
        // padding: '4px !important', // override inline-style
      },
    },
    loginMessageLink: {
      '&:hover': {
        cursor: 'pointer',
      },
    },

    nextButtonContainer: {
      width: '100%',
      margin: '15px 0',
      [StyleResponsive.onMobile]: {
        width: '90%',
      },
    },

    infoTextContainer: {
      width: '100%',
      marginBottom: '10px',
      [StyleResponsive.onDesktop]: {
        maxWidth: '350px',
      },
      [StyleResponsive.onMobile]: {
        width: '90%',
      },
    },

    infoText: {
      color: theme.palette.text.secondary,
    },
    infoTextLink: {
      color: theme.palette.primary.main,
    },

    inputEmail: {
      padding: '15px',
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    textFieldPassword: {
      marginTop: '16px',
      [StyleResponsive.onDesktop]: {
        minWidth: '358px',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '266px',
        width: '90%',
      },
    },

    inputPassword: {
      minHeight: '29px',
      padding: '15px',
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    formHelperText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.error.main,
      marginTop: '10px',
      marginLeft: '16px',
    },

    textFormHelperText: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      marginLeft: '8px',
    },

    circularProgress: {
      [StyleResponsive.onDesktop]: {
        padding: '0px 50px',
        width: '153px',
        height: '52px',
      },
      [StyleResponsive.onMobile]: {
        padding: '0px 120px',
      },
    },

    socialIcon: {
      [StyleResponsive.onMobile]: {
        width: '33px',
        height: '33px',
      },
    },

    textInputContainer: {
      width: '100%',
      minHeight: 56,
      '& > div': {
        minHeight: 56,
        width: '100%',
      },
    },

    dateTextField: {
      '& .MuiOutlinedInput-input': {
        fontSize: '14px',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '& .MuiFormLabel-root': {
        paddingTop: '-2px',
        fontSize: '14px',
        color: '#828282',
        '& > .MuiOutlinedInput-root > fieldset > legend': {
          fontSize: '11px',
        },
      },
    },

    textFieldSecondary: {
      // Override TextField color
      '& label.Mui-focused': {
        color: theme.palette.secondary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.secondary.main,
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: theme.palette.secondary.main,
        },
      },
    },

    loginTextField: {
      '& > :not(style)': { height: '55px' },
      '& .MuiOutlinedInput-input': { padding: '15px' },
    },

  }),
);

export const responsiveStyles = makeResponsiveStyles((res) => () => ({
  layout: {
    [res.onMobile]: {
      width: '100%',
    },
  },
}));
