import React from 'react';
import { FacebookEmbed } from 'react-social-media-embed';

export const ACOSharedFacebook = ({ href }) => {

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <FacebookEmbed url={href} width="100%" />
    </div>
  );
};