import { Box } from '@mui/material';
import { OnMobile } from '@providers/Responsive.provider';
import React from 'react';


export interface BannerCardProps {
  espacio_html: string;
  width: number;
  pauta_orden: number;
  height: number;
}

export const BannerCard: React.FC<BannerCardProps> = (props) => {

  return (
    <OnMobile>
      <Box width='100%' height='250px' display='flex' justifyContent='center'>
        <iframe src={'data:text/html;charset=utf-8,' + encodeURIComponent(props.espacio_html)}
                width='320px'
                height='270px'
                style={{ borderWidth: 'inherit' }}></iframe>
      </Box>
    </OnMobile>
  );
};
