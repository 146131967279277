import { useIsMobile } from '@providers/Responsive.provider';
import React from 'react';
import { LinkedInEmbed } from 'react-social-media-embed';

export const ACOSharedLinkedin = ({ href }) => {
  const isMobile = useIsMobile();

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <LinkedInEmbed
        url={href}
        width='100%'
        height={isMobile ? 500 : 700}
      />
    </div>
  );
};
