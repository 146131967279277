import { Useful, UsefulHookProps, UsefulObjectActualData, UsefulSwitch } from '@providers/hooks/useful/model';
import { useUsefulStoreForObject } from '@providers/hooks/useful/UsefulStoreContext';
import { useSession } from '@providers/user/SessionContext';
import { UsefulResponse, UsefulStatus } from '@service/useful/model';
import axios from 'axios';

const api = axios.create();

const usefulPromise = async ({ type, id }: UsefulHookProps): Promise<Useful> => {
  return api.put<UsefulResponse>('/api/useful', { type, id })
    .then(async res => {
      if (res.data.status === UsefulStatus.ERROR) {
        throw new Error('No se puede marcar como ùtil');
      }

      return res.data.status === UsefulStatus.YES ? Useful.YES : Useful.NO;
    }).catch(() => {
      throw new Error('No se puede marcar como ùtil');
    });
};

const negate = {
  [Useful.YES]: Useful.NO,
  [Useful.NO]: Useful.YES,
  [Useful.LOADING]: Useful.LOADING,
};

const diff = {
  [Useful.YES]: -1,
  [Useful.NO]: 1,
  [Useful.LOADING]: 0,
};


/**
 * Useful hook provide a hook that has a 'happy path behaviour'. Hook returns {@link UsefulObjectActualData} and {@link UsefulSwitch}.
 * First one contains data/state about useful regarding object id and type (included on {@link UsefulHookProps}). Second one provide a switch
 * mark/unmark useful.
 *
 */
export const useUseful = ({ id, type, onError, ...initialData }: UsefulHookProps): [boolean, UsefulObjectActualData, UsefulSwitch] => {
  const usefulStore = useUsefulStoreForObject(type, id, initialData);
  const session = useSession();

  const data = usefulStore.data;

  const usefulSwitch = async () => {
    if (!session.isLogged) {
      (onError && onError(new Error('Debe iniciar sesión para marcar como util')));

      return;
    }

    const { actual, count } = data;
    usefulStore.set({ useful: Useful.LOADING, actual: negate[actual], count: count + diff[actual], next: negate[actual] });

    usefulPromise({ id, type })
      .then(actual => {
        usefulStore.set({ actual, useful: actual, next: null });
      })
      .catch(err => {
        usefulStore.set({ count, actual, useful: actual, next: null });
        (onError && onError(err));
      });
  };


  return [data && data.actual === Useful.YES, data, usefulSwitch];
};