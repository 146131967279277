import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({

    paper: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',

      [StyleResponsive.onDesktop]: {
        padding: '29px 27px 28px 29px',
        minWidth: '413px',
        minHeight: '533px',
        marginTop: '10px',
        marginBottom: '10px',

        '&.modal': {
          marginTop: '0px',
          marginBottom: '0px',
          borderLeft: '1px solid #DCDCDC',
          boxShadow: 'unset',
          backgroundColor: '#F8F8F8',
          border: 'unset',
          minWidth: 'unset',
          width: 'min-content',
          padding: '12px 27px 13px 29px',
        },
      },

      [StyleResponsive.onMobile]: {
        padding: '23px 10px 14px 12px',
        minWidth: '300px',
        width: '70%',
        minHeight: '520px',
        marginTop: '14px',
        marginBottom: '14px',
      },
    },

    boxTop: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    boxLogin: {
      [StyleResponsive.onDesktop]: {
        marginBottom: '24px',
      },
      [StyleResponsive.onMobile]: {
        marginBottom: '16px',
      },
    },

    divider: {
      [StyleResponsive.onDesktop]: {
        margin: 'auto 0px',
      },
      [StyleResponsive.onMobile]: {
        margin: 'auto 4px',
      },
    },

    boxButtons: {
      marginTop: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '21px',

    },

    boxButtonRecorverPass: {
      [StyleResponsive.onMobile]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },

    boxButtonBack: {
      [StyleResponsive.onMobile]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
      },
    },

    buttonBack: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#FFFFFF',
      },
    },

    buttonRecorverPass: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '8px',
      width: '153px',
      height: '52px',
      color: 'white',
      letterSpacing: '0.1px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '&:disabled': {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.grey['500'],
      },
    },

    disabled: {},

    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    textInputContainer: {
      width: '100%',
      minHeight: 56,
      '& > div': {
        minHeight: 56,
        width: '100%',
      },
    },

    inputEmail: {
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0 1000px white inset !important',
      },
    },

    textFieldEmail: {
      [StyleResponsive.onDesktop]: {
        minWidth: '358px',
      },
      [StyleResponsive.onMobile]: {
        minWidth: '266px',
        width: '90%',
      },
    },

    textFieldWithMessage: {
      '& input:valid + fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
      },
      '& input:invalid + fieldset': {
        borderWidth: 2,
      },
      '& input:valid:focus + fieldset': {
        borderLeftWidth: 6,
      },
    },

    formHelperText: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.error.main,
    },

    textFormHelperText: {
      letterSpacing: '0.1px',
      textTransform: 'none',
      marginLeft: '8px',
    },

    textHelp: {
      backgroundColor: theme.palette.text.disabled,
      alignItems: 'center',
      padding: '10px',
      borderRadius: '8px',
      maxWidth: 'fit-content',
      margin: '20px 0',

      [StyleResponsive.onMobile]: {
        margin: '10px 0',
        padding: '8px',
        lineHeight: '20px',
        fontSize: '13px',
      },
    },

    inputMessage: {
      fontSize: '12px',
      color: theme.palette.primary.main,
      fontWeight: 400,
    },

    inputMessageIcon: {
      color: theme.palette.primary.main,
      height: '16px',
      width: '16px',
    },

    circularProgress: {
      [StyleResponsive.onDesktop]: {
        padding: '0px 50px',
        width: '153px',
        height: '52px',
      },
      [StyleResponsive.onMobile]: {
        padding: '0px 120px',
      },
    },

  }),
);