import { a11yTabProps, Tab, Tabs } from '@components/ACOTabs';
import { DocumentCardProps, TicketCardProps } from '@components/cards/card';
import CardContainer, { ACOCardContainerProps } from '@components/cards/card/Container/CardContainer';
import { useNewsletterCardStyles } from '@components/cards/card/Newsletter/NewsletterCard.style';
import { IconCount } from '@components/cards/card/shared/IconCount/IconCount';
import { CommonCardProp, SocialProps } from '@components/cards/card/shared/model';
import { CARD_LIST_MAPPER } from '@components/cards/CardList/renderer/CardList.renderer';
import { FlagCountry } from '@components/Flag/ACOFlag';
import { BookOutlined, DescriptionOutlined, PeopleOutlineOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { Box, SvgIconTypeMap } from '@mui/material';
import { Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTheme } from '@mui/material/styles';
import { BackendObjectPKG } from '@service/backend/model/objects';
import * as Model from '@service/model';
import { ObjectType, UserMin } from '@service/model';
import { ObjectProps } from '@service/objects/model';
import React, { useEffect, useState } from 'react';

export interface NewsletterCardProps extends CommonCardProp {
  title: string;
  content: string;
  year: string;
  number: string;
  social: SocialProps;
  objects: {
    counts: {
      tickets: number;
      documents: number;
      newsletters: number;
      experts: number;
    }
    tickets?: TicketCardProps[];
    documents?: DocumentCardProps[];
    newsletters?: NewsletterCardProps[];
    experts?: UserMin[];
  };
}

export const NewsletterCard: React.FC<NewsletterCardProps> = (props) => {
  const classes = useNewsletterCardStyles();
  const theme = useTheme();

  const cardContainerActions: ACOCardContainerProps = {
    id: props.id,
    type: ObjectType.NEWSLETTER,
    header: {
      color: theme.palette.newsletter.light,
      title: 'Boletín',
      icon: BookOutlined,
      flagCounty: FlagCountry.ARG,
    },
    social: props.social,
    hideActions: props.hideActions,
    share: {
      text: `Boletín: ${props.title}`,
    },
    noRedirect: props.noRedirect,
    isPageView: props.isPageView,
    sourceObject: props.sourceObject,
  };

  if (props.isPageView) {
    cardContainerActions.header = {
      ...cardContainerActions.header,
      flagCounty: undefined,
      rightContent: `#${props.number} | Año ${props.year}`,
    };
  }

  const objects = props.objects;

  const relatedObjects = [
    {
      icon: PersonOutlineOutlined,
      count: objects.counts.experts,
      tooltip: `${objects.counts.experts} expertos relacionados`,
    },
    {
      icon: PeopleOutlineOutlined,
      count: objects.counts.tickets,
      tooltip: `${objects.counts.tickets} consultas relacionadas`,
    },
    {
      icon: DescriptionOutlined,
      count: objects.counts.documents,
      tooltip: `${objects.counts.documents} documentos relacionados`,
    },
    {
      icon: cardContainerActions.header.icon,
      count: objects?.counts.newsletters,
      tooltip: `${objects.counts.tickets} boletines relacionados`,
    },
  ];

  if (!props.isPageView) {
    return (
      <CardContainer {...cardContainerActions}>
        <h4 className={classes.title}>{props.title}</h4>

        <div className={classes.content} dangerouslySetInnerHTML={{ __html: props.content }} />

        <Box display='flex' alignItems='center'>
          <Box display='flex'>
            {relatedObjects.map((related, i) => (
              <IconCount key={i} {...related} />
            ))}
          </Box>
          <Box flexGrow='1' className={classes.date}>
            N° {props.number} | Año {props.year}
          </Box>
        </Box>
      </CardContainer>
    );
  }

  //Page view

  interface NewsletterTab {
    label: string;
    key: number;
    icon: OverridableComponent<SvgIconTypeMap>;
    count: number;
    objectsType: Model.ObjectType;
    objects: ObjectProps[];
  }

  const tabOptions: NewsletterTab[] = [
    {
      key: 0,
      label: 'Consultas a expertos',
      icon: GroupOutlinedIcon,
      count: props.objects.counts.tickets,
      objectsType: Model.ObjectType.TICKET,
      objects: props.objects.tickets,
    },
    {
      key: 1,
      label: 'Documentos',
      icon: DescriptionOutlinedIcon,
      count: props.objects.counts.documents,
      objectsType: Model.ObjectType.DOCUMENT,
      objects: props.objects.documents,
    },
    {
      key: 2,
      label: 'Boletines',
      icon: BookOutlinedIcon,
      count: props.objects.counts.newsletters,
      objectsType: Model.ObjectType.NEWSLETTER,
      objects: props.objects.newsletters,
    },
  ];


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [tabState, setTabState] = useState<NewsletterTab>(tabOptions[0]);

  const handleTabChange = (event: React.ChangeEvent, selectedIndex: number) => {
    setTabState({
      ...tabState,
      ...tabOptions[selectedIndex],
    });
  };


  /*TODO: Ver esto - La idea es recargar la pagina porque sino aparecen los elementos del boletin anterior al hacer click en un nuevo boletin*/
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    handleTabChange(null, tabOptions[0].key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <CardContainer {...cardContainerActions}>
      <h4 className={classes.title}>{props.title}</h4>
      <div className={classes.content} dangerouslySetInnerHTML={{ __html: props.content }} />

      <Tabs value={tabState.key} onChange={handleTabChange} variant='fullWidth' indicatorColor='primary'
            textColor='primary'>
        {tabOptions.map(tabOption => {
            const Icon = tabOption.icon;

            return (
              <Tab key={tabOption.key} value={tabOption.key} label={tabOption.label} disableRipple
                   icon={<Icon />} {...a11yTabProps(tabOption.key)} />
            );
          },
        )}
      </Tabs>

      <Box p='20px' className={classes.tab}>
        <Typography variant='h6'>{tabState.count} {tabState.label}</Typography>
        {!!tabState.objects &&
          tabState.objects.map((data, i) => {

            const CardComponent = CARD_LIST_MAPPER[tabState.objectsType];

            if (!CardComponent) {
              return null;
            }

            return (
              <Box key={i} marginTop={'10px'}>
                <CardComponent key={i} {...{
                  ...data,
                  sourceObject: { pkg: BackendObjectPKG.NEWSLETTER, id: props.id },
                }} />
              </Box>
            );
          })}
      </Box>
    </CardContainer>
  );
};
