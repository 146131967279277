import { ObjectType } from '@service/model';


export interface UsefulProps {
  type: ObjectType;
  id: string | number;
}

export enum UsefulStatus {
  YES, NO, ERROR
}

export interface UsefulResponse {
  status: UsefulStatus;
}