import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  root: {
    borderRadius: 5,
    border: '1px solid #2270A8'
  },
});

const ARFlag: React.FC = () => {
  const styles = useStyles();

  return (
    <svg
      className={styles.root}
      width='24'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='-5.64307'
        y='-0.975098'
        width='37.0256'
        height='25.4167'
        rx='4.375'
        fill='white'
        stroke='#F5F5F5'
        strokeWidth='1.25'
      />
      {/* eslint-disable-next-line react/no-unknown-property */}
      <mask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='-7' y='-2' width='40' height='28'>
        <rect
          x='-5.64307'
          y='-0.975098'
          width='37.0256'
          height='25.4167'
          rx='4.375'
          fill='white'
          stroke='white'
          strokeWidth='1.25'
        />
      </mask>
      <g mask='url(#mask0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M-6.26807 7.28879H32.0075V-1.6001H-6.26807V7.28879Z'
          fill='#88BBE8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M-6.26807 25.0666H32.0075V16.1777H-6.26807V25.0666Z'
          fill='#88BBE8'
        />
        <path
          /* eslint-disable-next-line max-len */
          d='M12.8697 15.2333C14.8202 15.2333 16.437 13.6858 16.437 11.7333C16.437 9.78086 14.8202 8.23332 12.8697 8.23332C10.9192 8.23332 9.30241 9.78086 9.30241 11.7333C9.30241 13.6858 10.9192 15.2333 12.8697 15.2333Z'
          fill='#F4B32E'
          stroke='#DB7A2C'
          strokeWidth='1.66667'
        />
      </g>
      <path
        /* eslint-disable-next-line max-len */
        d='M-1.26807 -0.600098H27.0075V-2.6001H-1.26807V-0.600098ZM31.0075 3.3999V20.0666H33.0075V3.3999H31.0075ZM27.0075 24.0666H-1.26807V26.0666H27.0075V24.0666ZM-5.26807 20.0666V3.3999H-7.26807V20.0666H-5.26807ZM-1.26807 24.0666C-3.47721 24.0666 -5.26807 22.2757 -5.26807 20.0666H-7.26807C-7.26807 23.3803 -4.58179 26.0666 -1.26807 26.0666V24.0666ZM31.0075 20.0666C31.0075 22.2757 29.2166 24.0666 27.0075 24.0666V26.0666C30.3212 26.0666 33.0075 23.3803 33.0075 20.0666H31.0075ZM27.0075 -0.600098C29.2167 -0.600098 31.0075 1.19076 31.0075 3.3999H33.0075C33.0075 0.0861961 30.3212 -2.6001 27.0075 -2.6001V-0.600098ZM-1.26807 -2.6001C-4.58177 -2.6001 -7.26807 0.0861899 -7.26807 3.3999H-5.26807C-5.26807 1.19076 -3.47721 -0.600098 -1.26807 -0.600098V-2.6001Z'
        fill='#2270A8'
      />
    </svg>
  );
};

export default ARFlag;
