import Button from '@components/AcoButtons/Button';
import ACOLink from '@components/ACOLink/ACOLink';
import { Avatar } from '@components/Avatar/Avatar';
import PersonAddAltIcon from '@mui/icons-material/PersonAddOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import { Box, Card, Typography } from '@mui/material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { onClickPrevent } from '@providers/helper/click';
import { useFollowUseful } from '@providers/hooks/useful/FollowUsefulHook';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { User } from '@service/model';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import React, { useEffect } from 'react';

import styles from './WhoUsefulCard.styles';

interface WhoUsefulCardProps {
  user: User;
}

function renderBtns(state: boolean, title: string, icon: OverridableComponent<SvgIconTypeMap>, className: string, func: () => void) {
  const Icon = icon;

  return (<>
        <Button disableRipple startIcon={<Icon />} className={className} {...onClickPrevent(() => func())}> {title}</Button>
      </>
  );

}

const WhoUsefulCard: React.FC<WhoUsefulCardProps> = (props) => {
  const classes = styles();
  const isMobile = useIsMobile();
  const { user: loggedUser } = useSession();

  const { isFollow, setIsFollow , unfollow, follow } = useFollowUseful();


  {
    loggedUser &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      const url = '/api/user/red/followUser';

      axios.get<ResponseResult>(`${url}?usuario_logueado_id=${loggedUser.id}&usuario_id=${props.user.id}`).then((res) => {
        setIsFollow(res.data.success);
      });
    }, [isFollow, loggedUser.id, props.user.id, setIsFollow]);
  }


  return (
      <Card className={classes.followCard}>
        <Box display='flex' flexDirection={isMobile ? 'column' : 'unset'}
             alignItems={isMobile ? 'flex-start' : 'flex-end'} justifyContent='space-between'>
          <ACOLink href={(props.user && `/user/${props.user.id}`)}>
            <Box display='flex'>

              <Avatar src={props.user.img} alt={props.user.completeName} size={{ desktop: 99, mobile: 38 }} />
              <Box display='flex' flexDirection='column' marginLeft='16px'>
                <Box display='flex'>
                  <Typography variant='subtitle1' color='textPrimary'>{props.user.completeName}
                  </Typography>{props.user.verified && <VerifiedUserOutlinedIcon color='primary' />}
                </Box>
                <Typography variant='body2' color='textSecondary'>(@{props.user.nickname})</Typography>

              </Box>

            </Box>
          </ACOLink>

          {loggedUser.id != props.user.id &&
              renderBtns(isFollow,
                  isFollow ? 'Dejar de seguir' : 'Seguir',
                  isFollow ? PersonRemoveOutlinedIcon : PersonAddAltIcon,
                  isFollow ? classes.buttonUnfollow : classes.btnWhite,
                  isFollow ? () => unfollow(props.user.id, props.user.completeName) : () => follow(props.user.id, props.user.completeName),
              )}

        </Box>
      </Card>

  );
};

export default WhoUsefulCard;