import { useCardLocationStyles } from '@components/cards/card/shared/Location/CardLocation.styles';
import { LocationOnOutlined } from '@mui/icons-material';
import React from 'react';

export interface LocationProps {
  name: string;
}

export const CardLocation: React.FC<LocationProps> = ({name}) => {
  const classes = useCardLocationStyles();

  return (
    <div className={classes.root}>
      <LocationOnOutlined fontSize='small' /> {name}
    </div>
  )
}
