import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '#layoutBody': {
        marginBottom: '70px',
      },
    },
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
      boxShadow: '0px -2px 2px rgba(0, 0, 0, 0.1)',
      zIndex: 100,
      '&.isCriOS': {
        paddingBottom: '5px',
      },
      '& .MuiBottomNavigationAction-root': {
        [theme.breakpoints.down(420)]: {
          minWidth: 0,
          maxWidth: 100,
        },
        [theme.breakpoints.down(400)]: {
          minWidth: 0,
          maxWidth: 90,
          padding: '0px 8px',
        },
      },
    },
    itemRoot: {
      'color': theme.palette.text.primary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    itemSelected: {
      color: theme.palette.primary.main,
    },
    iconRoot: {},
  }),
);
