export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 16,
    // fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      height: 170,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
      boxSizing: 'border-box',
      overflow: 'hidden',
      height: 170,
    },
    input: {
      padding: 9,
      border: '1px solid silver',
      borderBottom: '3px solid #3A3A3A',
      overflowY: 'auto',
      height: 170,

    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    float: 'right',
    marginTop: '18px',
    borderRadius: '8px',
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 16,
      borderRadius: '4px',
      width: 'max-content',
    },
    item: {
      padding: '5px',
      margin: '2px 0px',
      '&focused': {
        backgroundColor: '#dfdfdf',
      },
    },
  },
};
