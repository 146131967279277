import { ObjectType } from '@service/model';

export enum Useful {
  LOADING = 'Loading', YES = 'YES', NO = 'NO'
}

export declare type UsefulSwitch = () => Promise<void>

export interface UsefulObjectData {
  count?: number;
  useful?: Useful;
}

export interface UsefulHookProps extends UsefulObjectData {
  type: ObjectType,
  id: string | number;
  onError?: (err) => void;
}

export interface UsefulObjectActualData extends UsefulObjectData {
  actual: Useful; /** If useful is {@link Useful#LOADING} actual contains last value before loading **/
  next?: Useful;
}