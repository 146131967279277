import React, { createContext, RefObject, useContext, useRef } from 'react';

interface CommentsContextInterface {
  focusTextField: () => void;
  textFieldRef: RefObject<unknown>;
}

const useComment: () => CommentsContextInterface = () => {

  const textFieldRef = useRef(null);

  const focusTextField = () => {
    textFieldRef.current.focus();
  };

  return {
    textFieldRef,
    focusTextField,
  };
};


const CommentProvider: React.FC = ({ children }) => {
  const comment = useComment();

  return (
    <CommentContext.Provider value={comment}>
      {children}
    </CommentContext.Provider>
  );
};

export default CommentProvider;
export const CommentContext = createContext<CommentsContextInterface>(null);

export const useCommentContext = () => useContext(CommentContext);


