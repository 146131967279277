import { Useful, UsefulObjectActualData } from '@providers/hooks/useful/model';
import { ObjectType } from '@service/model';
import React, { useContext, useEffect, useState } from 'react';

type UsefulContextObjectData = {
  [key in ObjectType]?: {
    [key in string | number]: UsefulObjectActualData
  };
}

type UsefulStoreUpdate = (type: ObjectType, id: string | number, data: Partial<UsefulObjectActualData>) => void;

type UsefulStoreGet = (type: ObjectType, id: string | number) => UsefulObjectActualData;

interface UsefulStore {
  set: UsefulStoreUpdate;
  get: UsefulStoreGet;
  data: UsefulContextObjectData;
}

const useUsefulStore: () => UsefulStore = () => {
  const [data, setData] = useState<UsefulContextObjectData>({});

  const get: UsefulStoreGet = (type, id) => (data[type] || {})[id];

  const set: UsefulStoreUpdate = (type, id, objectData) => {
    if (!data[type]) {
      data[type] = {};
    }

    const actual = get(type, id);
    data[type][id] = {...actual, ...objectData};

    setData({...data});
  };

  return { set, get, data };

};


const UsefulStoreContext = React.createContext<UsefulStore>(null);

export const useUsefulStoreContext = () => useContext(UsefulStoreContext);

declare type UsefulObjectStoreSet = (data: Partial<UsefulObjectActualData>) => void

interface UsefulObjectStore {
  set: UsefulObjectStoreSet;
  data: UsefulObjectActualData;
}

declare type UsefulObjectStoreHook = (type: ObjectType, id: string | number, initialData?: Partial<UsefulObjectActualData>) => UsefulObjectStore;

export const useUsefulStoreForObject: UsefulObjectStoreHook = (type, id, initialData) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useContext(UsefulStoreContext) || useUsefulStore();

  const set: UsefulObjectStoreSet = (data) => store.set(type, id, data);
  const data = store.get(type, id) || { useful: Useful.NO, count: 0, actual: Useful.NO, ...initialData };

  useEffect(() => {
    const setUpData: UsefulObjectActualData = {
      useful: Useful.NO, count: 0, actual: initialData.actual || initialData.useful || Useful.NO,
      ...initialData
    };


    store.set(type, id, setUpData)
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { set, data };
};


export const UsefulStoreContextProvider: React.FC = ({ children }) => {
  const store = useUsefulStore();

  return (
    <UsefulStoreContext.Provider value={store}>
      {children}
    </UsefulStoreContext.Provider>
  );
};

/** To DEBUG Useful context store
const UsefulDebugStatus = () => {
  const store = useUsefulStoreContext() || { data: {} };

  const count = Object.values(store.data || {})
    .map(byType => Object.values(byType).map(data => data.actual === Useful.YES).filter(Boolean).length)
    .length;

  return (
    <>
      <h1>UTILS: {count}</h1>
      <div>{JSON.stringify(store.data || {}, null, 2)}</div>
    </>
  );
};
*/
