import { UserSessionData } from '@service/model/session';

export function parseDateFromBackendFormat(date: string) {
  //Parse and centralize "dd/MM/yyyy HH:mm:ss" (GTM-3) date
  const dateParts = date.replace(/\//g,':').replace(/ /g,':').split(':');

  return new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T${dateParts[3]}:${dateParts[4]}:${dateParts[5]}-03:00`);
}

export function dateToString(date: Date) {
  //return "dd/MM/yyyy" local date
  return date.toLocaleDateString();
}

export function relativeShortDate(date: Date, previous?: string, relative?: Date): string {
  const delta = Math.round((+(relative || new Date()).getTime() - date.getTime()) / 1000);

  const minute = 60,
    hour = minute * 60,
    day = hour * 24;

  let fuzzy: string;

  if (delta < 30) {
    return 'ahora';
  } else if (delta < minute) {
    fuzzy = delta + ' s';
  } else if (delta < hour) {
    fuzzy = Math.floor(delta / minute) + ' m';
  } else if (delta < day) {
    fuzzy = Math.floor(delta / hour) + ' h';
  } else if (delta < day * 5) {
    fuzzy = Math.floor(delta / day) + ' d';
  } else if (delta > 5* day) {
    return dateToString(date);
  }

  return previous? `${previous} ${fuzzy}` : fuzzy;
}

export function relativeDate(date: Date): string {
  const delta = Math.round((+new Date().getTime() - date.getTime()) / 1000);

  const minute = 60,
    hour = minute * 60,
    day = hour * 24;

  let fuzzy: string = dateToString(date);

  if (delta < 30) {
    fuzzy = 'ahora';
  } else if (delta < minute) {
    fuzzy = delta + ' sugundos';
  } else if (delta < 2 * minute) {
    fuzzy = '1 minuto'
  } else if (delta < hour) {
    fuzzy = Math.floor(delta / minute) + ' minutos';
  } else if (Math.floor(delta / hour) == 1) {
    fuzzy = '1 hora'
  } else if (delta < day) {
    fuzzy = Math.floor(delta / hour) + ' horas';
  } else if (delta < day * 2) {
    fuzzy = 'ayer';
  }

  return fuzzy;
}

//size in bytes
export function humanFileSize(size: number): string {
  const i = Math.floor( Math.log(size) / Math.log(1024) );

  // @ts-ignore
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export function userCompleteName(user: UserSessionData) {
  return `${user.name} ${user.lastName}`;
}

export function truncateString(content: string, length: number , final = '...') {
  return content.length > (length - final.length) ?
    content.substring(0, length - final.length) + final :
    content;
}