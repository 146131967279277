import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles(() =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 20px',
      boxShadow: '0px 2px 6px #DCDCDC',
      borderRadius: '8px',
      backgroundColor: '#FFFFFF',
      margin: '10px 0',
      overflow: 'inherit',
      [StyleResponsive.onMobile]: {
        overflow: 'unset',
      },
    },
    commentsHeader: {
      marginBottom: '10px',
    },
    commentsHeaderText: {
      [StyleResponsive.onMobile]: {
        fontSize: '18px',
      },
    },
  }),
);
