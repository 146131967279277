import { CreatePost } from '@components/modal/CreatePost/CreatePost';
import { LoginModal } from '@components/modal/Login/LoginModal';
import styles from '@components/ShareMenu/ShareMenu.styles';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { onClickPrevent } from '@providers/helper/click';
import NewPostProvider from '@providers/post/NewPostContext';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType } from '@service/model';
import { truncateString } from '@shared/helper/human-friendly';
import { PostSVG, LinkSVG } from '@svg/index';
import { SocialFacebookSVG, SocialTwitterSVG, SocialWhatsappSVG } from '@svg/socialNetworks';
import copy from 'copy-to-clipboard';
import React, { FC, SVGProps, useState } from 'react';

interface IOption {
  text: string;
  icon?: FC<SVGProps<SVGElement>>;
  key: 'post' | 'facebook' | 'twitter' | 'whatsapp' | 'link';
  onClick: (url: string, text: string, isMobile: boolean) => void;
}

interface ShareMenuProps {
  text: string;
  url: string;
  renderButton: (onClick: ((e: React.MouseEvent) => void)) => JSX.Element;
  useInHome?: boolean;
  objectId?: string | number,
  objectType?: ObjectType,
}

const urlBuilder = {
  facebook: (url: string, text: string) => `https://www.facebook.com/sharer/sharer.php?u=${url}&amp;title=${text}`,
  twitter: (url: string, text: string) => `https://twitter.com/share?url=${url}&via=agroconsultas&related=Agroconsultas%2CAgroconsultasOnline&hashtags=Agroconsultas&text=${text}`,
  whatsapp: (url: string, text: string) => `https://wa.me://send?text=${text}%20-%20${url}`,
};

const openShareLink = (url: string, text: string, windowName: string, isMobile: boolean, urlBuilder: (url: string, text: string) => string) => {

  const finalUrl = urlBuilder(encodeURIComponent(url), encodeURIComponent(truncateString(text, 100)));

  window.open(finalUrl, isMobile ? '_blank' : windowName, isMobile ? null : 'width=700, height=700, screenX=100');
};

const ShareMenu: React.VFC<ShareMenuProps> = (props: ShareMenuProps) => {
  const classes = styles();
  const { isLogged } = useSession();
  const isMobile = useIsMobile();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loginOpen, setLoginOpen] = useState(false);
  const [postOpen, setPostOpen] = useState(false);


  const openLoginModal = () => {
    setLoginOpen(true);
  };

  const openPostModal = () => {
    setPostOpen(true);
  };


  const handleMenuClickOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenuList = () => {
    setAnchorEl(null);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    closeMenuList();
  };

  const shareOptions: IOption[] = [
    {
      text: 'Compartir en Agroconsultas',
      icon: PostSVG,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClick: () => {
        isLogged ? openPostModal() : openLoginModal();
      },
      key: 'post',
    },
    {
      text: 'Compartir en Whatsapp',
      icon: SocialWhatsappSVG,
      onClick: (url, text, isMobile) => {
        openShareLink(url, text, 'Compartir en Whatsapp', isMobile, urlBuilder.whatsapp);
      },
      key: 'whatsapp',
    },
    {
      text: 'Compartir en Twitter',
      icon: SocialTwitterSVG,
      onClick: (url, text, isMobile) => {
        openShareLink(url, text, 'Compartir en Twitter', isMobile, urlBuilder.twitter);
      },
      key: 'twitter',
    },
    {
      text: 'Compartir en Facebook',
      icon: SocialFacebookSVG,
      onClick: (url, text, isMobile) => {
        openShareLink(url, text, 'Compartir en Facebook', isMobile, urlBuilder.facebook);
      },
      key: 'facebook',
    },
    {
      text: 'Copiar enlace',
      icon: LinkSVG,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClick: (url, text, isMobile) => {
        copy(url);
      },
      key: 'link',
    },
  ];

  return (
    <Box display='flex' flexDirection='row' justifyContent='flex-start' alignItems='center'>
      {props.renderButton(handleMenuClickOption)}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        variant='menu'
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ paper: `${classes.menu} ${!props.useInHome && 'bottom'} `, list: classes.menuList }}
        elevation={2}
      >
        {shareOptions.map((option: IOption, index: number) => (
          <MenuItem
            key={index}
            {...onClickPrevent(() => {
              option.onClick(props.url, props.text, isMobile);
              closeMenuList();
            })}
          >
            {option.icon &&
              <ListItemIcon className={classes.menuItem}>
                <option.icon className={`${classes.icon} ${props.useInHome ? 'green' : 'blue'} `} />
              </ListItemIcon>
            }
            <ListItemText className={`${classes.menuText} ${props.useInHome ? 'green' : 'blue'} `}
                          primary={option.text} />
          </MenuItem>
        ))}
      </Menu>

      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
                                loginModalProps={{ notRedirect: true }} />}
      <NewPostProvider>
        {postOpen && <CreatePost props={{ open: postOpen, onClose: () => setPostOpen(false) }}
                                 postModalProps={{ objectType: props.objectType, objectId: props.objectId }} />}
      </NewPostProvider>
    </Box>
  );
};

export default ShareMenu;
