import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: theme.typography.subtitle1.fontSize,
      fontWeight: theme.typography.subtitle1.fontWeight,
      textTransform: 'none',
      padding: 5,
      borderWidth: 2,
      wordBreak: 'break-all',
      width: '40px',
      minWidth: '40px',
      display: 'flex',
      flexDirection: 'column',
      '& .MuiButton-label' : {
        display: 'flex',
        flexDirection: 'column'
      }
    },
    primary: {
      border: `2px solid ${theme.palette.primary.main}`
    },
    secondary: {
      border: `2px solid ${theme.palette.secondary.main}`
    },
    delete: {
      border: `2px solid ${theme.palette.error.main}`
    },
    iconWithLabel: {
      marginBottom: '8px',
      fontSize: '1.4rem',
    },
    buttonIcon: {
      fontSize: 12,
      marginLeft: 0
    }
  })
);
