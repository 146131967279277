import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {StyleResponsive} from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
    createStyles({
      followCard: {
        height: '135px',
        padding: '18px 16px',
        marginBottom: '8px',
        background: '#FFFFFF',
        border: '1px solid #828282',
        boxSizing: 'border-box',
        borderRadius: '8px',
      },

      btnWhite: {
        height: '40px',
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        boxSizing: 'border-box',

        [StyleResponsive.onMobile]: {
          marginTop: '15px',
        },

        '&:hover': {
          background: theme.palette.primary.main,
          color: '#FFFFFF',
        },

        '&.password': {
          marginTop: '20px',
          fontSize: '12px',
          width: '100%',
          [StyleResponsive.onMobile]: {
            width: '194px',
          },
        },
      },

      buttonUnfollow: {
        height: '40px',
        border: `2px solid ${theme.palette.error.main}`,
        boxSizing: 'border-box',
        borderRadius: '6px',
        color: theme.palette.error.main,

        [StyleResponsive.onMobile]: {
          backgroundColor: '#FFFFFF',
          marginTop: '8px',
        },

        [StyleResponsive.onDesktop]: {
          minWidth: '127px',
        },

        '&:hover': {
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.main,
        },

      },
    })
)