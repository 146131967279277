// size in bytes
export function humanFileSize(size: number): string {
  const i = Math.floor( Math.log(size) / Math.log(1024) );

  // @ts-ignore
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}


export function truncateString(content: string, length: number , final = '...') {
  return content.length > (length - final.length) ?
    content.substring(0, length - final.length) + final :
    content;
}