import { EventWithoutSelfRegistrationModal } from '@components/modal/OnlineEvents/EventWithoutSelfRegistrationModal';
import { SelfRegistrationEventModal } from '@components/modal/OnlineEvents/SelfRegistrationEventModal';
import { OnlineEventHomeProps } from '@components/OnlineEvent/OnlineEventHome';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import { Box, Card, Link, Typography } from '@mui/material';
import { useEventCarouselContext } from '@providers/onlineEvent/eventCarouselContext';
import { OnDesktop, OnMobile, useIsDesktop } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { parseDateFromBackendFormat } from '@shared/helper/date';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import React, { useState } from 'react';


import styles from '../OnLiveOnlineEventsHome.styles';

const NextOnlineEventCardHome: React.FC<OnlineEventHomeProps> = ({ onliveEvent, smallOnLiveEvent }) => {
  const classes = styles();

  const [selfRegistrationEventOpen, setSelfRegistrationEventOpen] = useState(false);
  const [registrationEventOpen, setRegistrationEventOpen] = useState(false);
  const date = parseDateFromBackendFormat(onliveEvent.date);

  const dateString = date.toLocaleDateString('es-ES', { weekday: 'long', month: 'long', day: 'numeric' });
  const startTime = date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });

  /*TODO: Pasar noRedirect en props */
  const router = useRouter();
  const redirectUrl = `/events/${onliveEvent.id}`;
  const redirectClick = redirectUrl ? () => router.push(redirectUrl) : undefined;
  const isDesktop = useIsDesktop();
  const { isLogged } = useSession();
  const { showDescription, updateShowDescription } = useEventCarouselContext();

  const handleClickDescription = (e) => {
    updateShowDescription(onliveEvent.id)
    e.stopPropagation();
  };

  const openSelfRegistrationEventModal = () => {
    setSelfRegistrationEventOpen(true);
  };

  const openRegistrationEventModal = () => {
    setRegistrationEventOpen(true);
  };

  const openEvent = () => {
    onliveEvent.free ? redirectClick() : (isLogged ? redirectClick() : onliveEvent.settings.selfRegistration === '1' ? openRegistrationEventModal() : openSelfRegistrationEventModal());
  };

  return smallOnLiveEvent ? (
    <Box padding='0 10px' marginBottom='10px'>
      <Card className={clsx(classes.smallCardOnLive, { clickable: isDesktop })} onClick={() => openEvent()}>
        <Box display='flex' flexDirection='column'>
          <Box className={classes.smallBoxCategory}>
            <Typography variant='body1'>{onliveEvent.title}</Typography>
          </Box>
          <Box className={classes.smallOnLive}>
            <Box display='flex' alignItems='center'>
              <Typography variant='subtitle2'> Proximo evento: Inicia el {dateString} {startTime} Hs. </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  ) : (
    <Box className={classes.boxOnLive}>
      <Card className={clsx(`${classes.cardOnLive} next`, { clickable: isDesktop })} onClick={() => openEvent()}>
        <Box>
          <OnMobile>
            <Box className={classes.nextPast}>
              <Typography variant='subtitle2'> Proximo evento </Typography>
              <Typography variant='subtitle2'>Inicia el {dateString} {startTime} Hs.</Typography>
            </Box>
            <Box className={classes.reproductor}>
              <img src={onliveEvent.thumbnails} alt={onliveEvent.title} className={classes.imgEvent} />
              <PlayCircleFilledWhiteOutlinedIcon className={classes.iconPlayOnLive} />
            </Box>
          </OnMobile>

          <Typography variant='h6' fontSize='18px' marginBottom='5px'> {onliveEvent.title}</Typography>
          <OnDesktop>

            <Typography style={{ fontWeight: 'bold' }}>Inicia el {dateString} {startTime} Hs.</Typography>
            {onliveEvent.description &&
              <Box>
                {/* eslint-disable-next-line max-len */}
                <div style={{ display: 'contents', color: '#828282' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onliveEvent.description.slice(0, showDescription.includes(onliveEvent.id) ? onliveEvent.description.length : (onliveEvent.title.length > 60 ? 100 : 150))) }} />
                {!showDescription.includes(onliveEvent.id) && onliveEvent.description.length > 100 ? '..' : null}
                {onliveEvent.description.length > 100 &&
                  <Link onClick={handleClickDescription}
                        className={classes.linkDescription}> {showDescription.includes(onliveEvent.id) ? 'Ver menos' : 'Ver más'} </Link>}
              </Box>
            }
          </OnDesktop>
        </Box>
      </Card>
      {registrationEventOpen &&
        <SelfRegistrationEventModal
          modalProps={{ open: registrationEventOpen, onClose: () => setRegistrationEventOpen(false) }}
          onlineEvent={onliveEvent} />}

      {selfRegistrationEventOpen &&
        <EventWithoutSelfRegistrationModal
          modalProps={{ open: selfRegistrationEventOpen, onClose: () => setSelfRegistrationEventOpen(false) }}
          onlineEvent={onliveEvent} />}
    </Box>


  );
};

export default NextOnlineEventCardHome;
