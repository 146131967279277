import CardContainer, { ACOCardContainerProps } from '@components/cards/card/Container/CardContainer';
import { useDocumentCardStyles } from '@components/cards/card/Document/DocumentCard.style';
import { CardAttachments } from '@components/cards/card/shared/Attachments/CardAttachments';
import { CommonCardProp, SocialProps } from '@components/cards/card/shared/model';
import { FlagCountry } from '@components/Flag/ACOFlag';
import Tags from '@components/Tags/Tags';
import { DescriptionOutlined } from '@mui/icons-material';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { Box, Button, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OnDesktop } from '@providers/Responsive.provider';
import { ObjectType } from '@service/model';
import { humanFileSize } from '@shared/helper/human-friendly';
import React from 'react';

export interface DocumentAttachmentProps {
  contentType: string;
  contentLength: string;
  downloadUrl: string;
  downloads: number;
}

export interface DocumentCardProps extends CommonCardProp {
  title: string;
  content: string;
  date: string;
  attachment?: DocumentAttachmentProps;
  social: SocialProps;
  keywords?: string[];
  youtubeVideoId?: string;
  authors: string;
  source: {
    name: string;
    url?: string;
  };
}

export const DocumentCard: React.FC<DocumentCardProps> = (props) => {
  const classes = useDocumentCardStyles();
  const theme = useTheme();
  const isPageView = props.isPageView;

  const cardContainerActions: ACOCardContainerProps = {
    id: props.id,
    type: ObjectType.DOCUMENT,
    header: {
      color: theme.palette.document.light,
      title: 'Documento',
      icon: DescriptionOutlined,
      flagCounty: FlagCountry.ARG,
    },
    social: props.social,
    share: {
      text: `Documento: ${props.title}`
    },
    noRedirect: props.noRedirect,
    isPageView: isPageView,
    sourceObject: props.sourceObject
  };

  const renderPDFAttachment = (attachmentProps: DocumentAttachmentProps) => {
    return <iframe src={attachmentProps.downloadUrl} className={classes.iframePdf} frameBorder='0'></iframe>;
  };

  const renderDownloadAttachment = (attachmentProps: DocumentAttachmentProps) => {
    return (
      <Button
        variant='outlined'
        href={attachmentProps.downloadUrl}
        target='_blank'
        className={classes.attachmentButton}>
        <Box className={classes.attachmentDownloadText} display='flex' alignItems='center'>
          <AttachFileOutlinedIcon className={classes.attachmentDownloadIcon} />
          Descargar archivo adjunto
        </Box>

        <OnDesktop>
          <Box className={classes.attachmentDownloadInfo} display='flex' flexDirection='column'>
            <span>{humanFileSize(Number(attachmentProps.contentLength))}</span>
            <span>{attachmentProps.downloads} descargas</span>
          </Box>
        </OnDesktop>
      </Button>
    );
  };

  const renderAttachment = (attachmentProps: DocumentAttachmentProps) => {
    return attachmentProps.contentType == 'application/pdf'
      ? renderPDFAttachment(attachmentProps)
      : renderDownloadAttachment(attachmentProps);
  };

  const renderYoutubeVideo = (youtubeVideoId: string) => {
    return (
      <div className={classes.videoContainer}>
        <iframe
          id='ytPlayer'
          src={`http://www.youtube.com/embed/${youtubeVideoId}`}
          frameBorder='0'
          className={classes.videoIframe}
        />
      </div>
    );
  };

  return (
    <CardContainer {...cardContainerActions}>
      <h4 className={classes.title}>{props.title}</h4>

      <p className={classes.content}>{props.content}</p>

      {isPageView && props.attachment && renderAttachment(props.attachment)}

      {isPageView && props.youtubeVideoId && renderYoutubeVideo(props.youtubeVideoId)}

      {isPageView && (
        <Box paddingY='15px'>
          <Typography variant='body1'>{props.authors}</Typography>
          {props.source.url ? (
            <Link
              href={props.source.url}
              color='textPrimary'
              target='_blank'
              underline="hover">
              <Typography variant='body1'>{props.source.name}</Typography>
            </Link>
          ) : (
            <Typography variant='body1'>{props.source.name}</Typography>
          )}
        </Box>
      )}

      {isPageView && props.keywords && <Box marginBottom='10px' /> && <Tags tags={props.keywords} />}

      {!isPageView && (
        <Box display='flex' alignItems='center'>
          <Box>
            <CardAttachments count={props.attachment ? 1 : 0} files={[]} hideGallery />
          </Box>
          <Box flexGrow='1' className={classes.date}>
            {props.date}
          </Box>
        </Box>
      )}
    </CardContainer>
  );
};
