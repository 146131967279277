import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    itemRoot: {
      borderRadius: '6px',
      textTransform: 'none',
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      color: '#FFFFFF',
      padding: '3px 7px',
      [theme.breakpoints.up('lg')]: {
        padding: '3px 15px',
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      '&.selected': {
        backgroundColor: theme.palette.primary.dark,
        fontSize: theme.typography.subtitle2.fontSize,
        fontWeight: theme.typography.subtitle2.fontWeight,
      }
    },
    itemLabel: {
      flexDirection: 'column',
    },
    iconRoot: {
      color: 'white',
      fontSize: 18,
      marginBottom: 2
    },
  })
);
