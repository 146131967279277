import React, { createContext, useContext, useState } from 'react';

interface EventCarouselContextInterface {
  showDescription: number[];
  activeStepIndex: number;
  handleNext: () => void;
  handleBack: () => void;
  updateStepIndex: (newIndex: number) => void;
  updateShowDescription: (clickedIndex: number) => void;
}


const useNewTicket: () => EventCarouselContextInterface = () => {

  const [showDescription, setShowDescription] = useState<number[]>([]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const handleNext = () => {
    setShowDescription([]);
    setActiveStepIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setShowDescription([]);
    setActiveStepIndex((prevActiveStep) => prevActiveStep - 1);
  };

  const updateStepIndex = (newIndex: number) => {
    setActiveStepIndex(newIndex);
  };

  const updateShowDescription = (clickedIndex: number) => {

    if (showDescription.includes(clickedIndex)) {
      const openCopy = showDescription.filter((element) => {
        return element !== clickedIndex;
      });

      setShowDescription(openCopy);
    } else {
      const openCopy = [...showDescription];
      openCopy.push(clickedIndex);
      setShowDescription(openCopy);
    }
  };

  return {
    showDescription,
    activeStepIndex,
    handleNext,
    handleBack,
    updateStepIndex,
    updateShowDescription,
  };
};


const EventCarouselProvider: React.FC = ({ children }) => {
  const newTicket = useNewTicket();

  return (
    <EventCarouselContext.Provider value={newTicket}>
      {children}
    </EventCarouselContext.Provider>
  );
};

export default EventCarouselProvider;
export const EventCarouselContext = createContext<EventCarouselContextInterface>(null);

export const useEventCarouselContext = () => useContext(EventCarouselContext);


