import CommentsLayout from '@components/Comments/Layout/CommentsLayout';
import CommentSubscribe from '@components/Comments/Subscribe/CommentSubscribe';
import { Box, Card, Typography } from '@mui/material';
import { ReferenceObject } from '@service/model';
import React from 'react';

import styles from './Comments.styles';


interface CommentsProps {
  reference: ReferenceObject;
  count?: number;
}

const Comments: React.VFC<CommentsProps> = (props) => {
  const classes = styles();

  const url = `/api/comments/${props.reference.referenceType}/${props.reference.referenceId}`;

  return (
    <Card className={classes.main}>
      <Box className={classes.commentsHeader} display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
        <Box display='flex' flexDirection='row'>
          {!!props.count && props.count > 0 && <Typography variant='h6' color='primary' className={classes.commentsHeaderText}>{props.count}&nbsp;</Typography>}
          <Typography variant='h6' className={classes.commentsHeaderText}>Comentario{props.count != 1 ? 's': ''}</Typography>
        </Box>

        <CommentSubscribe reference={props.reference} />
      </Box>
      <CommentsLayout url={url} reference={props.reference} showForm />
    </Card>
  );
};


export default Comments;
