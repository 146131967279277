import Button from '@components/AcoButtons/Button';
import IconButton from '@components/AcoButtons/IconButton';
import ProgressButton from '@components/AcoButtons/ProgressButton';
import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import { Avatar } from '@components/Avatar/Avatar';
import styles from '@components/Comments/Form/CommentForm.styles';
import MentionsTextInput, { transformTextWithMentions } from '@components/common/MentionsTextInput';
import FilesUploader from '@components/FilesUploader/FilesUploader';
import { LoginModal } from '@components/modal/Login/LoginModal';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { Box, Typography } from '@mui/material';
import { useCommentContext } from '@providers/comment/CommentsContext';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useUploadFilesStore, UploadFilesStoreContext } from '@providers/hooks/fileuploads/UploadFilesStoreContext';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { Comment, ReferenceObject } from '@service/model';
import axios from 'axios';
import clsx from 'clsx';
import React, { useState } from 'react';

interface CommentFormProps {
  reference: ReferenceObject;
  commentParentId?: number;
  comment?: Comment;
  onNewComment?: (CommentDTO) => void;
  changeComment?: (CommentDTO) => void;
  showEditComment?: () => void;
  buttonText?: string;
  label?: string;
  isEdit?: boolean;
  initInput?: string;
}

const CommentForm: React.VFC<CommentFormProps> = (props) => {
  const classes = styles();
  const { user, isLogged } = useSession();
  const { snackbars } = useCustomSnackbar();
  const [loading, setLoading] = useState(false);
  const [newComment, setNewComment] = React.useState(props.initInput ? props.initInput : '');
  const [deleteAttachment, setDeleteAttachment] = useState([]);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const uploadFilesStore = useUploadFilesStore();
  const [loginOpen, setLoginOpen] = useState(false);
  const [viewLogin, setViewLogin] = useState<boolean>(false);

  const onNewComment = props.onNewComment || (() => undefined);

  const cleanForm = () => {
    setNewComment('');
    setShowUploadForm(false);
  };

  const commentAttachments = () => {
    return uploadFilesStore.getAll();
  };

  const commentEditAttachments = (orden: number) => {
    setDeleteAttachment([deleteAttachment.push(orden)]);
  };

  function createComment(text) {
    setLoading(true);

    axios.post<Comment>(`/api/comments/${props.reference.referenceType}/${props.reference.referenceId}`, {
      text: text,
      parentId: props.commentParentId,
      attachments: commentAttachments(),
      mentions: mentions,
    })
      .then((res) => {
        snackbars.showSuccess('Comentario creado');
        onNewComment(res.data);
        cleanForm();
      })
      .catch(() => snackbars.showError('Error al crear el comentario'))
      .finally(() => {
        setLoading(false);
      });
  }

  const handleCreateComment = () => {
    if (!!!newComment.trim()) {
      return snackbars.showError('El comentario no contiene texto');
    }

    const transformedText = transformTextWithMentions(newComment);

    createComment(transformedText);
  };

  const handleAddAttachment = () => {
    setShowUploadForm(prevState => !prevState);
  };

  function editComment(text) {
    setLoading(true);

    axios.post<{ success: boolean, error?: string, comment: Comment }>('/api/comments/edit', {
      objectType: props.reference.referenceType,
      objectId: props.reference.referenceId,
      text: text,
      commentId: props.comment.id,
      deleteFiles: deleteAttachment,
      attachments: commentAttachments(),
      mentions: mentions,
    })
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Comentario modificado');
          cleanForm();
          props.showEditComment();
          props.changeComment(res.data.comment);
        } else {
          snackbars.showError(res.data.error);
        }
      })
      .catch(() => snackbars.showError('Error al modificar el comentario'))
      .finally(() => {
        setLoading(false);
        setDeleteAttachment(undefined);
      });
  }

  const handleEditComment = () => {
    if (!!!newComment.trim()) {
      return snackbars.showError('El comentario no contiene texto');
    }

    editComment(newComment);
  };

  const { textFieldRef } = useCommentContext();

  const openLoginModal = () => {
    setLoginOpen(true);
    setViewLogin(true);
  };

  const openRegisterModal = () => {
    setLoginOpen(true);
    setViewLogin(false);
  };

  const [mentions, setMentions] = useState([]);

  const updateMentions = (mention) => {
    setMentions(mentions.concat(mention));
  };

  if (!isLogged) {
    return (
      <>
        <OnDesktop>
          <Box display='flex' flexDirection='row' justifyContent='space-between' className={classes.loginBox}>
            <Typography variant='body1' className={classes.loginText}>
              Iniciá sesión o registrate para poder dejar un comentario
            </Typography>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              <Button color='primary' variant='outlined' size='large' onClick={() => openRegisterModal()}>
                Registrarse
              </Button>
              <Box marginRight='5px' />
              <Button color='primary' variant='contained' size='large' onClick={() => openLoginModal()}>
                Iniciar sesión
              </Button>
            </Box>
          </Box>
        </OnDesktop>
        <OnMobile>
          <Box display='flex' flexDirection='row' justifyContent='space-between' className={classes.loginBox}
               onClick={() => openLoginModal()}>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              <ChatOutlinedIcon style={{ fontSize: '20px', color: '#828282', marginRight: '10px' }} />
              <Typography variant='body1' color='textSecondary'>
                Iniciá sesión o registrate para poder dejar un comentario
              </Typography>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
              <ArrowForwardIosOutlinedIcon style={{ fontSize: '15px', color: '#828282', marginLeft: '10px' }} />
            </Box>
          </Box>
        </OnMobile>

        {loginOpen && (
          <LoginModal
            modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
            loginModalProps={{ notRedirect: true, viewLogin: viewLogin }}
          />
        )}
      </>
    );
  }

  return (
    <Box>
      <Box className={clsx(classes.newCommentBox, { reply: !!props.commentParentId }, { edit: props.isEdit })}>
        <Box className={classes.newCommentTextInputComponents}>
          {!props.isEdit && <Avatar src={user?.avatarUrl} alt={user?.fullName} size={{ desktop: 48, mobile: 36 }} />}

          <MentionsTextInput TextCardProps={{
            updateText: setNewComment,
            updateMentions: updateMentions,
            text: newComment,
          }}
                             TextRef={textFieldRef} />
        </Box>
        <Box className={classes.newCommentActionComponents}>
          <ACOTooltip title='Adjuntar archivo' arrow>
            <IconButton color='secondary' size='large' onClick={handleAddAttachment}>
              <AttachFileIcon fontSize='small' />
            </IconButton>
          </ACOTooltip>
          <Box marginRight='5px' />
          <ProgressButton color='primary' variant='contained' size='large'
                          onClick={props.isEdit ? handleEditComment : handleCreateComment}
                          loading={loading}>
            {props.buttonText ? props.buttonText : 'Comentar'}
          </ProgressButton>
        </Box>
      </Box>
      {showUploadForm &&
        <Box>
          <UploadFilesStoreContext.Provider value={uploadFilesStore}>
            <FilesUploader filesInComment={props.comment?.attachments} deleteFileInComment={commentEditAttachments} />
          </UploadFilesStoreContext.Provider>
        </Box>
      }
    </Box>
  );
};

export default CommentForm;
