import ACOLayout from '@components/ACOLayout/ACOLayout';
import Breadcrumbs, { BreadcrumbsProps } from '@components/Breadcrumbs/Breadcrumbs';
import StatsLateral, { StatsLateralProps } from '@components/StatsLateral/StatsLateral';
import { Box } from '@mui/material';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import React from 'react';

interface ObjectViewProperties {
  statsLateralProps?: StatsLateralProps;
  breadcrumbsProps: BreadcrumbsProps;
}

const ObjectView: React.FC<ObjectViewProperties> = ({ children, statsLateralProps, breadcrumbsProps }) => {
  return <ACOLayout>
    <OnDesktop>
      <Box display='flex' flexDirection='column' style={{ paddingRight: '2px' }}>
        <Breadcrumbs {...breadcrumbsProps} />
        <Box display='flex' flexDirection='row' marginTop='25px'>
          {statsLateralProps && <>
              <Box style={{width: '10%'}}>
                  <Box style={{position: 'fixed'}}>
                      <StatsLateral {...statsLateralProps} />
                  </Box>
              </Box>
              <Box style={{width: '90%'}}>
                {children}
              </Box>
          </>}
          {!statsLateralProps &&
              <Box style={{width: '100%'}}>
                {children}
              </Box>}
        </Box>
      </Box>
    </OnDesktop>
    <OnMobile>
      {children}
    </OnMobile>
  </ACOLayout>;
};

export default ObjectView;
