import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useNewsletterCardStyles = makeStyles(theme => ({
  title: {
    marginTop: 10,
  },
  content: {
    fontSize: 16,
    letterSpacing: 0.5,
    color: theme.palette.text.primary,
    [StyleResponsive.onMobile]: {
      fontSize: 14
    }
  },
  date: {
    textAlign: 'right',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.text.secondary
  },
  tab: {
  },
}))