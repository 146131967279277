import { DocumentCardProps, NewsletterCardProps, PostCardProps, TicketCardProps } from '@components/cards/card';
import { useSharedMinObjectStyles } from '@components/cards/card/Post/shared/SharedMinObject/SharedMinObject.styles';
import { objectTypePalette } from '@components/cards/card/shared/model';
import { Box } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { ObjectType } from '@service/model';
import { AcoObject, ObjectProps } from '@service/objects/model';
import React from 'react';

declare type LegendResolver = (data: ObjectProps) => string;

const SHARED_MIN_LEGEND_NAME: { [key: string]: string | LegendResolver } = {
  [ObjectType.TICKET]: (ticket: TicketCardProps) => `Consulta a ${ticket.expert.name} de ${ticket.client.name}` + (ticket.labels ? ` sobre ${ticket.labels[0]}` : ''),
  [ObjectType.POST]: (post: PostCardProps) => `Posteo de ${post.user.name}`,
  [ObjectType.NEWSLETTER]: (newsletter: NewsletterCardProps) => `Boletin ${newsletter.number} | ${newsletter.year}`,
  [ObjectType.DOCUMENT]: (doc: DocumentCardProps) => `Documento: '${doc.title}'`,
};

export function sharedMinLegend(data: AcoObject): string {
  const legend = SHARED_MIN_LEGEND_NAME[data.type];

  if (!legend) {
    return 'Contenido';
  }

  if (typeof legend === 'function') {
    return legend(data.props);
  }

  return legend;
}

export const SharedMinObject = (sharedProps: AcoObject) => {
  const classes = useSharedMinObjectStyles();
  const theme = useTheme();

  const themedObjectTypePalette = objectTypePalette(sharedProps.type)(theme);

  return (
    <Box className={classes.sharedMin} style={{ backgroundColor: alpha(themedObjectTypePalette.main, 0.3) }}>
      {sharedMinLegend(sharedProps)}
    </Box>
  );
};