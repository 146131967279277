import Button from '@components/AcoButtons/Button';
import CardContainer, { ACOCardContainerProps } from '@components/cards/card/Container/CardContainer';
import { CardAttachments, CardAttachmentsProps } from '@components/cards/card/shared/Attachments/CardAttachments';
import { CardLocation } from '@components/cards/card/shared/Location/CardLocation';
import { CommonCardProp, SocialProps } from '@components/cards/card/shared/model';
import UserInfo, { UserData } from '@components/cards/card/shared/UserInfo/UserInfo';
import { TicketAnswer, TicketAnswerData } from '@components/cards/card/Ticket/Answer/TicketAnswer';
import { useTicketClasses } from '@components/cards/card/Ticket/TicketCard.styles';
import { TicketEventProps, TicketEvents } from '@components/cards/card/Ticket/TicketEvents/TicketEvents';
import { FlagCountry } from '@components/Flag/ACOFlag';
import { ArrowForwardIos, PeopleOutlineOutlined } from '@mui/icons-material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { onClickPrevent } from '@providers/helper/click';
import { OnDesktop, OnMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType } from '@service/model';
import { parseDateFromBackendFormat, relativeShortDate } from '@shared/helper/date';
import { useRouter } from 'next/router';
import React from 'react';

export interface TicketCardProps extends CommonCardProp {
  client: UserData,
  expert: UserData
  business?: UserData,
  social: SocialProps,
  location: string;
  labels?: string[];
  question: string;
  attachments: CardAttachmentsProps,
  events?: TicketEventProps[];
  state?: string;
  edit?: boolean;
  answer: TicketAnswerData;
  thematics:
    {
      id: number,
      name: string,
    }[],
}

export const TicketCard: React.FC<TicketCardProps> = (props) => {
  const classes = useTicketClasses();
  const theme = useTheme();
  const router = useRouter();
  const { user, isLogged } = useSession();

  const showStatus = router.pathname == '/user/[uid]' && (router.query.uid == `${props.client.id}` || router.query.uid == `${props.expert.id}`) ||
    router.pathname == '/tickets/[tid]' && (user?.id == props.client.id || user?.id == props.expert.id);

  const redirectToEdit = isLogged && user && (user.id == props.client.id || user.id == props.expert.id) && props.state != 'Aprobado' && props.state != 'Cancelado' && `/tickets/${props.id}/edit`;

  const cardContainerActions: ACOCardContainerProps = {
    id: props.id,
    type: ObjectType.TICKET,
    header: {
      color: theme.palette.ticket.light,
      title: props.business ? `Consulta a Empresa | Responde ${props.expert.name}` : 'Consulta a Experto',
      icon: PeopleOutlineOutlined,
      flagCounty: FlagCountry.ARG,
    },
    social: props.social,
    share: {
      text: `Respuesta de ${props.expert.name} a: ${props.question}`,
    },
    noRedirect: props.noRedirect,
    isPageView: props.isPageView,
    hideActions: props.hideActions || props.state != 'Aprobado',
    redirectUrl: redirectToEdit,
    sourceObject: props.sourceObject,
  };

  const states = {
    'Aprobado': 'Respondida',
    'Inicial': 'Esperando respuesta',
    'Investigando': 'Esperando respuesta',
    'Esperando respuesta del cliente': user?.verified ? 'Esperando respuesta' : 'Esperando aclaración de mi parte',
    'Cancelado': 'Cancelada',
  };

  const defineTicketFormCTA = (user, ticket) => {
    if (!isLogged || user && (user.id != ticket.client.id && user.id != ticket.expert.id) || props.isPageView) {
      return;
    } else if (ticket.state == 'Esperando respuesta del cliente' && user.id == ticket.client.id) {

      return (
        <Box display='flex' justifyContent='flex-end'>
          <Button size='small' startIcon={<LiveHelpOutlinedIcon fontSize='small' />}
                  color='info'
                  {...onClickPrevent(() => router.push(`/tickets/${props.id}/edit?stateResponse=2`))}>
            Responder pedido de aclaración
          </Button>
        </Box>);
    } else if (ticket.state == 'Inicial' && user.id == ticket.expert.id) {

      return (
        <Box marginTop='15px' display='flex' justifyContent='space-between' flexWrap='wrap'>
          <Button size='small' className={classes.btnGreen}
                  startIcon={<ForumOutlinedIcon fontSize='small' />}
                  {...onClickPrevent(() => router.push(`/tickets/${props.id}/edit?stateResponse=0`))}>
            Responder consulta
          </Button>
          <Button size='small' startIcon={<NextPlanOutlinedIcon fontSize='small' />}
                  {...onClickPrevent(() => router.push(`/tickets/${props.id}/edit?stateResponse=1`))}>
            Delegar a otro experto
          </Button>
          <Button size='small' startIcon={<LiveHelpOutlinedIcon fontSize='small' />}
                  color='inherit'
                  {...onClickPrevent(() => router.push(`/tickets/${props.id}/edit?stateResponse=2`))}>
            Pedír aclaración
          </Button>
          <Button size='small' startIcon={<CancelOutlinedIcon fontSize='small' />}
                  color='inherit'
                  {...onClickPrevent(() => router.push(`/tickets/${props.id}/edit?stateResponse=3`))}>
            Cancelar consulta
          </Button>
        </Box>
      );
    }
  };

  const waitingMessage = (props) => {
    if (props.state == 'Cancelado' && !props.answer.text) {
      return 'Consulta cancelada por inactividad';
    } else if (!props.answer.text && (!props.events || props.events[props.events.length - 1].type == 2)) {
      return props.expert.id == user?.id ? 'Esperando respuesta de mi parte. Ver opciones de respuesta abajo.' : 'En breve el experto enviará su respuesta.';
    }
  };

  return (
    <CardContainer {...cardContainerActions}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '1px' }}>
        <div className={classes.involvedPeople}>
          <UserInfo {...props.client} />
          <Box className={classes.boxArrow}>
            <ArrowForwardIos className='arrow' />
          </Box>
          <UserInfo {...(props.business ? props.business : props.expert)} verified={true} />
        </div>
        {props.isPageView && !props.edit && !user?.verified &&
          <OnDesktop>
            <Button
              color='primary'
              variant='contained'
              size='medium'
              {...onClickPrevent((e) => {
                e.stopPropagation();

                router.push({
                  pathname: '/tickets/new',
                  query: {
                    type: props.business ? 'business' : 'experts',
                    id: props.business ? props.business.id : props.expert.id,
                  },
                });
              })
              }
            >
              Consultar al experto
            </Button>
          </OnDesktop>}
      </Box>
      <OnDesktop>
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {props.answer.since && <div
            className={classes.answerSince}>{relativeShortDate(parseDateFromBackendFormat(props.answer.since))} </div>}
          {showStatus &&
            <Box className={classes.state}>
              <Typography color='secondary' fontSize='14px'>
                Estado: {states[props.state]}
              </Typography>
            </Box>
          }
        </Box>
      </OnDesktop>
      <OnMobile>
      <div style={{ display: 'flex', marginBottom: '3px' }}>
          {showStatus &&
            <Box className={classes.state}>
              <Typography color='secondary' fontSize='14px'>
                Estado: {states[props.state]}
              </Typography>
            </Box>
          }
        </div>
        {props.isPageView &&
          <Button
            color='primary'
            variant='outlined'
            className={classes.buttonToNewTicket}
            size='medium'
            {...onClickPrevent((e) => {
              e.stopPropagation();

              router.push({
                pathname: '/tickets/new',
                query: {
                  type: props.business ? 'business' : 'experts',
                  id: props.business ? props.business.id : props.expert.id,
                },
              });
            })
            }>
            Consultar al experto
          </Button>
        }
        {props.answer.since && <div
          className={classes.answerSince}>{relativeShortDate(parseDateFromBackendFormat(props.answer.since))} </div>}
      </OnMobile>
      <CardLocation name={props.location} />

      {props.thematics &&
        <Box className={classes.boxLabels}>
          {
            props.thematics.map((item, index: number) =>
              (
                <Box display='flex' alignItems='center' key={index}>
                  <div className={classes.labels}>
                    <label key={item.id}
                           className='label'>{!props.isPageView && item.name.length > 35 ? item.name.slice(0, 35) + '...' : item.name}</label>
                  </div>

                  {index < (props.thematics.length - 1) &&
                    <OnDesktop>
                      <ArrowForwardIosOutlinedIcon fontSize='small' style={{ color: '#26A65B', marginBottom: '5px' }} />
                    </OnDesktop>
                  }
                </Box>
              ))
          }
        </Box>
      }

      <Divider />

      {props.isPageView && props.events?.length > 0 &&
        <TicketEvents events={props.events}
                      clientId={props.client.id}
                      expert={props.expert}
                      state={props.state}
                      ticketId={props.id} />
      }

      {!props.isPageView &&
        <>
          <div className={classes.question} dangerouslySetInnerHTML={{ __html: props.question }} />

          <CardAttachments {...props.attachments} alwaysExpandImages={true} />

          <TicketAnswer expert={props.expert} answer={props.answer} waitingMessage={waitingMessage(props)}
                        canceled={props.state == 'Cancelado' && !props.answer.text} />
        </>
      }

      {defineTicketFormCTA(user, props)}

    </CardContainer>
  );
};


