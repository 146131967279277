import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      color: theme.palette.background.paper,
      '&:before': {
        boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
      },
    },
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.body1.fontWeight,
      padding: '10px 15px',
      boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
      borderRadius: '8px',
      minWidth: '160px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    user: {
      color: theme.palette.text.primary,
      textAlign: 'center',
      margin: '10px 0',
      cursor: 'default'
    },
    buttonUnfollow: {
      height: '40px',
      border: `2px solid ${theme.palette.error.main}`,
      boxSizing: 'border-box',
      borderRadius: '6px',
      color: theme.palette.error.main,

      [StyleResponsive.onMobile]: {
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
      },

      [StyleResponsive.onDesktop]: {
        minWidth: '127px',
      },

      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: theme.palette.error.main,
      },
    },
    btnWhite: {
      height: '40px',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      boxSizing: 'border-box',

      [StyleResponsive.onMobile]: {
        marginTop: '15px',
      },

      '&:hover': {
        background: theme.palette.primary.main,
        color: '#FFFFFF',
      },

      '&.password': {
        marginTop: '20px',
        fontSize: '12px',
        width: '100%',
        [StyleResponsive.onMobile]: {
          width: '194px',
        },
      },
    },

  })
);