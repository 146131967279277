import ACOLink from '@components/ACOLink/ACOLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Breadcrumbs as MuiBreadcrumbs, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRouter } from 'next/router';
import React from 'react';

export interface BreadcrumbsProps {
  breadcrumbs: {
    text: string;
    href?: string;
  }[]
}

const useStyles = makeStyles({
  separator: {
    marginRight: '16px',
    borderRight: '1px solid #828282',
    height: '30px'
  },
  backButton: {
    textTransform: 'none',
    fontSize: 16,
    marginRight: '12px'
  },
  breadcrumbs: {
    fontSize: 16
  },

  link: {
    color: '#26a65b',
    '&:hover': {
        textDecoration : 'underline'
    }
  }
});

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({breadcrumbs}) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <Box display='flex' alignItems='center'>
      <Button onClick={() => router.back()} className={classes.backButton} color='secondary' startIcon={<ArrowBackIcon />}>
          Volver
      </Button>
      <Box className={classes.separator} />
      <MuiBreadcrumbs separator='›' aria-label='breadcrumb' style={{fontSize: 16}}>
        { 
          breadcrumbs.map(breadcrumb => {
            return breadcrumb.href ?
                (<ACOLink
              key={breadcrumb.text}
              href={breadcrumb.href}
              className={classes.link}>
                  {breadcrumb.text}
                </ACOLink>)
                :
                <Typography key={breadcrumb.text} color='textPrimary' variant='subtitle1' style={{fontWeight: 'normal'}}>
                  {breadcrumb.text}
                </Typography>;
          })
        }
      </MuiBreadcrumbs>
    </Box>
  );
}

export default Breadcrumbs;
