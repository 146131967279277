import { Theme, alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';


export default makeStyles((theme: Theme) =>
  createStyles({

    smallCardOnLive: {
      border: `1px solid ${theme.palette.secondary.main}`,
      backgroundColor: '#FFFFFF',
      boxSizing: 'border-box',
      borderRadius: '8px',
      '&.clickable': {
        cursor: 'pointer',
      },

      '&.onLive': {
        border: `1px solid ${theme.palette.error.main}`,
      },
    },

    smallOnLive: {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      minHeight: '40px',
      padding: '8px 8px',

      '& .MuiSvgIcon-root': {
        width: '0.7em',
        height: '0.7em',
      },

      '&.onLive': {
        backgroundColor: theme.palette.error.main,
      }

    },

    smallTextOnLive: {
      marginLeft: '6px',
    },

    smallBoxCategory: {
      width: '100%',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.secondary.main,
      margin: '6px 0 6px',


      '&.onLive': {
        color: theme.palette.error.main,
      }

    },

    boxOnLive: {
      margin: '3px 0 8px',

      [StyleResponsive.onMobile]: {
        padding: '0 0 5px',
        marginTop: '8px',
      },
    },

    cardOnLive: {
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCDCDC',
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '11px 12px 10px',
      boxShadow: '0 2px 6px #DCDCDC',
      minHeight: '196px',
      '&.clickable': {
        cursor: 'pointer',
      },
      '&.next': {
        border: '3px solid #26A65B',
      },


    },

    onLive: {
      backgroundColor: theme.palette.error.main,
      borderRadius: '6px',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '40px',
      padding: '8px 5px',
      marginBottom: '9px',

      '& .MuiSvgIcon-root': {
        width: '0.7em',
        height: '0.7em',
      },

      [StyleResponsive.onMobile]: {
        justifyContent: 'space-around',
      },
    },


    nextPast: {
      color: theme.palette.secondary.main,
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '40px',
      padding: '6px 5px',
      marginBottom: '9px',
      border: `2px solid ${theme.palette.secondary.main}`,

      '& .MuiSvgIcon-root': {
        width: '0.7em',
        height: '0.7em',
      },

      [StyleResponsive.onMobile]: {
        justifyContent: 'space-around',
      },
    },

    textOnLive: {
      marginLeft: '1px',

      [StyleResponsive.onMobile]: {
        marginLeft: '3px',
      },
    },

    reproductor: {
      backgroundColor: theme.palette.text.secondary,
      borderRadius: '6px',
      color: 'red',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',
      overflow: 'hidden',

      [StyleResponsive.onMobile]: {
        maxHeight: '150px',
      },

      '&.onLive': {
        border: '1px solid red',
      },

    },

    boxCategory: {
      backgroundColor: alpha(theme.palette.secondary.main, 0.1),
      width: '132px',
      minHeight: '33px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.secondary.main,
      marginBottom: '6px',
    },

    iconPlayOnLive: {
      height: '40px',
      width: '40px',
      position: 'absolute',
      color: theme.palette.background.paper,
    },

    linkDescription: {
      textDecoration: 'none',
      marginLeft: '2px',
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.dark,
      },
    },

    imgEvent: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      borderRadius: '6px',
    },

  }),
);
