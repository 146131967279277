import Button from '@components/AcoButtons/Button';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { Modal, ModalProps } from '@components/modal/Modal';
import { userRegisterEventModalStyles } from '@components/modal/OnlineEvents/RegisterEventModal.styles';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import { useIsMobile } from '@providers/Responsive.provider';
import { OnlineEvent, SingularEventType } from '@service/model';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

export const EventWithoutSelfRegistrationModal: React.VFC<{ modalProps: ModalProps, onlineEvent: OnlineEvent }> = ({
                                                                                                                     modalProps,
                                                                                                                     onlineEvent,
                                                                                                                   }) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const classes = userRegisterEventModalStyles();
  const [loginOpen, setLoginOpen] = useState(false);
  const [viewLogin, setViewLogin] = useState<boolean>(false);


  const handleClose = () => {
    modalProps.onClose && modalProps.onClose('escapeKeyDown', 'escapeKeyDown');

    if (router.query.oeid) {
      router.push(onlineEvent.categoryId == 3 ? '/trainings' : '/events')
    }
  };

  const handleStopPropagation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  const openLoginModal = () => {
    setLoginOpen(true);
    setViewLogin(true);
  };

  const openRegisterModal = () => {
    setLoginOpen(true);
    setViewLogin(false);
  };

  const modalRegisterEvent = (
    <>
      <Modal {...(modalProps as ModalProps)} onClose={handleClose}
             maxWidth='md' fullWidth fullScreen={isMobile} sx={{ '& .MuiDialog-container': { alignItems: 'flex-start' } }}
             onClick={handleStopPropagation}
             title={onlineEvent.title}>
        <DialogContent className={classes.content}>

          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(onlineEvent.description) }} />

          <Box className={classes.boxPago}>
            <Typography variant='subtitle2'>Para poder
              ver {onlineEvent.categoryId == 3 ? ' esta ' : ' este '} {SingularEventType[onlineEvent.categoryId]} debe
              ser usuario
              Registrado de
              Agroconsultas (es gratis).</Typography>
            {/* <Typography variant='subtitle2'>Una vez que se registre deberá acceder el día y hora del evento
              con su usuario y
              clave de registro.</Typography>*/}

          </Box>
        </DialogContent>

        <DialogActions className={classes.actions}>

          <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                  onClick={() => (
                    router.push({
                      query: { destination: `/events/${onlineEvent.id}` },
                    }, undefined, { shallow: true }) &&
                    openLoginModal())}>
            Iniciar sesión
          </Button>

          <Button className={clsx('postButton')} color='primary' variant='outlined' size='large'
                  onClick={() => (
                    router.push({
                      query: { destination: `/events/${onlineEvent.id}` },
                    }, undefined, { shallow: true }) &&
                    openRegisterModal())}>
            Registrarse
          </Button>


        </DialogActions>
      </Modal>
    </>

  );


  return (
    <>
      {modalRegisterEvent}
      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
                                loginModalProps={{ notRedirect: true, viewLogin: viewLogin }}
      />}
    </>
  );
};
