import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useModalStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    padding: 0,
  },
  closeButton: {
    [StyleResponsive.onMobile]: {
      position: 'fixed'
    },
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    zIndex: 1,
  },
  content: {
    padding: '25px 35px',

    [StyleResponsive.onMobile]: {
      padding: '15px 20px',
    },
  },
}));