import Button from '@components/AcoButtons/Button';
import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import ACOTooltipProfile from '@components/ACOTooltip/ACOTooltipProfile';
import { Avatar } from '@components/Avatar/Avatar';
import { SocialProps, StatsUserProps } from '@components/cards/card/shared/model';
import { useUserInfoStyles } from '@components/cards/card/shared/UserInfo/UserInfo.styles';
import { Modal } from '@components/modal/Modal';
import { VerifiedUserOutlined } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import PublicIcon from '@mui/icons-material/Public';
import { Avatar as AvatarMUI, Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Radio from '@mui/material/Radio';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { onClickPrevent } from '@providers/helper/click';
import { useIsMobile } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType, Thematic } from '@service/model';
import { ResponseResult } from '@service/user.service';
import { parseDateFromBackendFormat, relativeShortDate } from '@shared/helper/date';
import axios from 'axios';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

export interface UserData {
  id: number;
  name?: string;
  img?: string;
  imgLegacy?: boolean;
  url?: string;
  verified?: boolean;
  social?: SocialProps;
  stats?: StatsUserProps;
  nickname?: string;
  objectType?: ObjectType;
  thematics?: Thematic[];
}

export interface UserInfoProps extends UserData {
  since?: string;
  post?: string;
  hideTooltip?: boolean;
  showName?: boolean;
  privacy?: string;
  postId?: number;
}

const UserInfo = (props: UserInfoProps) => {
  const classes = useUserInfoStyles();
  const router = useRouter();
  const { user } = useSession();
  const isMobile = useIsMobile();
  const { snackbars } = useCustomSnackbar();
  const [isPrivacyTooltipOpen, setIsPrivacyTooltipOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.privacy);

  const privacyOptions = {
    '0': { text: 'Público', icon: <PublicIcon fontSize='small' /> },
    '1': { text: 'Solo seguidores', icon: <PeopleIcon fontSize='small' /> },
    '2': { text: 'Solo yo', icon: <LockIcon fontSize='small' /> },
  };

  const [privacyData, setPrivacyData] = useState(privacyOptions[props.privacy]);

  const handleChange = (value: string) => () => {
    setSelectedValue(value);
  };

  async function isAdminBusiness(businessId) {
    if (props.objectType !== ObjectType.BUSINESS) {
      return false;
    }

    try {
      const res = await axios.post(`/api/user/${user?.id}/businessAdmin`, { businessId });

      return !!res.data.success;
    } catch (error) {
      console.error('Error when verifying business administrator:', error);

      return false;
    }
  }

  const handleOpenModal = async () => {
    if ((props.id === user?.id) || await isAdminBusiness(props.id)) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => setOpenModal(false);

  function changePrivacy(postId: number, privacy: string) {
    axios.post<ResponseResult>('/api/posts/edit', { postId, privacy })
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Privacidad modificada');
          setPrivacyData(privacyOptions[privacy]);
          setOpenModal(false);
        } else {
          snackbars.showError(res.data.error || 'Error interno');
        }
      });
  }

  const userCard = (
    <a href={props.url}>
      <div
        className={clsx(classes.container, { post: props.post }, { ticket: !props.post })}
        {...onClickPrevent(() => router.push(props.url))}>
        <Avatar src={props.img} alt={props.name} name={props.name} />
        {!props.showName && (
          <div className={clsx(classes.nameContainer, { post: props.post }, { ticket: !props.post })} data-name= 'nameContainer'>
            <div className={clsx(classes.name, { post: props.post })} data-name= 'nameUserCard'>
            {props.name} {props.verified && <VerifiedUserOutlined className={classes.verified} />}
            </div>
            {(props.post || isMobile) &&
              <div style={{
                display: 'flex',
                alignItems: 'flex-start',
              }}>
                {props.since && <div
                  className={classes.since}>{relativeShortDate(parseDateFromBackendFormat(props.since), 'Hace')}</div>}
                <ACOTooltip
                  title={privacyData?.text}
                  arrow
                  onOpen={() => setIsPrivacyTooltipOpen(true)}
                  onClose={() => setIsPrivacyTooltipOpen(false)}
                >
                  <div className={classes.privacy} {...onClickPrevent(() => handleOpenModal())}>
                    {privacyData?.icon}
                  </div>
                </ACOTooltip>
              </div>
            }
          </div>
        )}
      </div>
    </a>
  );

  const modalAudience = (
    <Modal open={openModal} onClose={handleCloseModal} title={'Seleccionar audiencia'} fullWidth={isMobile}>

      <List dense sx={{ width: '100%', minWidth: isMobile ? 'inherit' : '400px', margin: '15px auto' }}>
        {Object.keys(privacyOptions).map((key) => {
          const option = privacyOptions[key];

          return (
            <ListItem
              key={key}
              secondaryAction={
                <Radio
                  edge='end'
                  onChange={handleChange(key)}
                  checked={selectedValue === key}
                />
              }
              disablePadding
            >
              <ListItemButton onClick={handleChange(key)}>
                <AvatarMUI>
                  {option.icon}
                </AvatarMUI>
                <ListItemText primary={option.text} primaryTypographyProps={{ style: { fontSize: 16 } }}
                              sx={{ marginLeft: '10px' }} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <Box display='flex' justifyContent='flex-end' padding='0px 24px' maxHeight='40px'>
        <Button color='secondary' onClick={() => setOpenModal(false)}>
          Cancelar
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => changePrivacy(props.postId, selectedValue)}
        >
          Aceptar
        </Button>
      </Box>
    </Modal>
  );

  return (
    <div className={clsx(classes.mainBox, { post: props.post }, { ticket: !props.post })}>
      {props.hideTooltip || isPrivacyTooltipOpen ?
        <>
          {userCard}
        </> :
        <ACOTooltipProfile profile={props} objectType={props.objectType}>
          {userCard}
        </ACOTooltipProfile>
      }
      {openModal && modalAudience}
    </div>
  );
};

export default UserInfo;
