import { useModalStyles } from '@components/modal/Modal.styles';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogProps, DialogTitle, IconButton } from '@mui/material';
import { DialogClasses } from '@mui/material/Dialog/dialogClasses';
import React, { ReactElement, useState } from 'react';

export interface ModalContext {
  open: () => void;
}

export declare type ModalTrigger = (modalContext: ModalContext) => JSX.Element;

export interface ModalCustomProps {
  title?: string;
  maxWidth?: string;
  classes?: Partial<DialogClasses>;
}

declare type ModalBaseProps = ModalCustomProps & Partial<DialogProps>

export declare type ModalProps = ModalBaseProps & (
  | { trigger: ModalTrigger; open?: never }
  | { trigger?: never; open: boolean }
)

export const Modal: React.VFC<ModalProps> = ({title, trigger, ...props}) => {
  const classes = useModalStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = trigger ? useState(false) : [props.open, () => undefined];

  const handleClose = (e, reason) => {
    setOpen(false);
    props.onClose && props.onClose(e, reason);
  };

  return <>
    {trigger && trigger({ open: () => setOpen(true) })}
    <Dialog {...(props as DialogProps)} open={open} onClose={handleClose}>
      <Box className={classes.content}>
        {title && (
          <DialogTitle className={classes.title}>
            {title}
            <IconButton className={classes.closeButton} onClick={(e) => handleClose(e, 'escapeKeyDown')} size="large">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        )}
        {!title && (
          <IconButton className={classes.closeButton} onClick={(e) => handleClose(e, 'escapeKeyDown')} size="large">
            <CloseIcon />
          </IconButton>
        )}
        {props.children}
      </Box>
    </Dialog>
  </>;
};

interface ModalOpenerProps {
  children(data: ModalContext): ReactElement;

  modal: React.VFC;
}

export const ModalOpener: React.FC<ModalBaseProps & ModalOpenerProps> = ({ modal, children, ...modalProps }) => {
  const TheModal = modal;

  const theModalProps = {
    trigger: context => children(context),
    ...modalProps
  }

  return (
    <TheModal {...theModalProps} />
  )
}
