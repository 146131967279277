import Layout from '@components/ACOLayout/ACOLayout';
import PageNotFound from '@components/PageNotFound/PageNotFound';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Box, Button, CircularProgress, Divider, FormControl, FormHelperText, IconButton,
  InputAdornment, InputLabel, OutlinedInput, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { StyleResponsive } from '@providers/Responsive.provider';
import { RecoverPasswordResult } from '@service/recoverPassword.service';
import theme from '@theme';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({

  container: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #DCDCDC',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '15px 20px',
    margin: '40px auto',
    maxWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },

  textHelp: {
    backgroundColor: theme.palette.text.disabled,
    alignItems: 'center',
    padding: '10px',
    borderRadius: '8px',

    [StyleResponsive.onMobile]: {
      margin: '10px 0',
      padding: '8px',
      lineHeight: '20px',
      fontSize:'13px',
    },
  },

  textInputContainer: {
    width: '100%',
    margin: '20px 0 10px',
    minHeight: 56,
    '& > div': {
      minHeight: 56,
      width: '100%',
    },
  },

  inputPassword: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },

  divider: {
    width: '100%',

    [StyleResponsive.onMobile]: {
      margin: '10px 4px',
    },
  },

  formHelperText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error.main,
  },

  textFormHelperText: {
    letterSpacing: '0.1px',
    textTransform: 'none',
    marginLeft: '8px',
  },

  buttonLogin: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    width: '200px',
    height: '52px',
    color: 'white',
    letterSpacing: '0.1px',
    textTransform: 'none',
    margin: '20px 0 20px',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
      color: theme.palette.text.secondary,
      backgroundColor: theme.palette.grey['500'],
    },
  },

  disabled: {},

  circularProgress: {
    [StyleResponsive.onDesktop]: {
      padding: '0px 50px',
      width: '153px',
      height: '52px',
    },
    [StyleResponsive.onMobile]: {
      padding: '0px 120px',
    },
  },
}));

interface FormInputs {
  password: string;
  passwordConfirmation: string;
}

interface IErrors {
  different: boolean,
  long: boolean,
  number: boolean,
}

const INITIAL_FORM_INPUTS = {
  password: '',
  passwordConfirmation: '',
};

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 30;

const SetNewPassword = () => {
  const classes = useStyles();
  const { snackbars } = useCustomSnackbar();
  const router = useRouter()
  const { token, uid } = router.query

  const [passwordChanged, setPasswordChanged] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<FormInputs>(INITIAL_FORM_INPUTS);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [showError, setShowError] = React.useState<IErrors>();
  const [stateSendPassword, setSendPassword] = useState(false);

  const redirect = () => {
    setTimeout(() => {router.push('/login')}, 4000)
  }

  const enableButton = values.password.length >= MIN_PASSWORD_LENGTH && values.passwordConfirmation.length >= MIN_PASSWORD_LENGTH;

  const handleChange = (prop: keyof FormInputs) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setShowError(undefined)
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowpasswordConfirmation = () => {
    setShowPasswordConfirmation(!showPasswordConfirmation);
  };

  const sendPassword = async (token, uid, password, passwordConfirmation) => {

    const data = {
      token,
      uid,
      password,
      passwordConfirmation,
    }

    setSendPassword(true)

    return await axios.post<RecoverPasswordResult>('/api/setNewPassword', data)
      .then((res) => {
        if (res.data.errors && res.data.response === undefined) {
          snackbars.showError('Error interno');
        }
        else {
          setPasswordChanged(!res.data.errors)
        }

      })
      .catch((err) => {
        console.warn(err)
        snackbars.showError('Error interno');
      })
      .finally(() => setSendPassword(false));
  }

  const handleSend = () => {
    if (values.password !== values.passwordConfirmation){
      setShowError({...showError, different: true})
    } else if (values.password.length > MAX_PASSWORD_LENGTH) {
      setShowError({...showError, long: true})
    } else if (!values.password.match(/\d/) || !values.passwordConfirmation.match(/[A-z]/ )) {
      setShowError({...showError, number: true})
    } else {
      sendPassword(token, uid, values.password, values.passwordConfirmation)
    }
  };

  if (!token || !uid) {
    return <Layout withoutRightColumn><PageNotFound/></Layout>
  }

  const content = passwordChanged ? (
    <Box className={classes.container}>
      <Box margin='20px auto'>
        <Typography variant='h6' textAlign='center'>La contraseña ha sido modificada correctamente</Typography>
      </Box>
      {redirect()}
      <Box display='flex' margin='10px auto'>
        <CircularProgress ></CircularProgress>
        <Typography variant='subtitle1' marginLeft='10px' >Seras redirigido al login en un instante</Typography>
      </Box>
      <Button size='large' classes={{ root: classes.buttonLogin }} onClick={() => router.push('/login')}>
        Iniciar sesion
      </Button>
    </Box>
  ) :(
    <Box className={classes.container}>

      <Box margin='20px 0'>
        <Typography variant='h5'>Elija una nueva clave</Typography>
      </Box>

      <Divider className={classes.divider} orientation='horizontal' variant='middle' />

      <Box margin='20px 0'>
        <Typography className={classes.textHelp}>
          {`La clave debe contener números, mayúsculas y/o minúsculas y debe tener un mínimo de ${MIN_PASSWORD_LENGTH} caracteres y un máximo de ${MAX_PASSWORD_LENGTH}`}
        </Typography>
      </Box>

      <form className={classes.form}>
        <Box className={classes.textInputContainer}>
          <FormControl variant='outlined' required>
            <InputLabel htmlFor='outlined-adornment-password'>
              Nueva Contraseña{' '}
            </InputLabel>
            <OutlinedInput
              id='password'
              error={showError && (showError.different || showError.long || showError.number)}
              label='Nueva Contraseña'
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              autoComplete='off'
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                    size='large'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                className: classes.inputPassword,
              }}
            />
          </FormControl>
        </Box>

        <Box className={classes.textInputContainer}>
          <FormControl variant='outlined' required>
            <InputLabel htmlFor='outlined-adornment-password'>
              Ingrese Nuevamente{' '}
            </InputLabel>
            <OutlinedInput
              id='passwordConfirmation'
              error={showError && (showError.different || showError.long || showError.number)}
              label='Ingrese Nuevamente'
              type={showPasswordConfirmation ? 'text' : 'password'}
              value={values.passwordConfirmation}
              autoComplete='off'
              onChange={handleChange('passwordConfirmation')}
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowpasswordConfirmation}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                    size='large'>
                    {showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                className: classes.inputPassword,
              }}
            />
          </FormControl>
        </Box>
      </form>
      {showError && showError.different && (
        <FormHelperText id='error-text' className={classes.formHelperText}>
          <ErrorIcon sx={{ width: '18px', height: '18px', marginBottom: '3px' }} />
          <Typography variant='body1' component='span' className={classes.textFormHelperText}>
            Las claves deben ser iguales
          </Typography>
        </FormHelperText>
      )}

      {showError && showError.long && (
        <FormHelperText id='error-text' className={classes.formHelperText}>
          <ErrorIcon sx={{ width: '18px', height: '18px', marginBottom: '3px' }} />
          <Typography variant='body1' component='span' className={classes.textFormHelperText}>
            {`Las claves deben tener menos de ${MAX_PASSWORD_LENGTH} caracteres`}
          </Typography>
        </FormHelperText>
      )}

      {showError && showError.number && (
        <FormHelperText id='error-text' className={classes.formHelperText}>
          <ErrorIcon sx={{ width: '18px', height: '18px', marginBottom: '3px' }} />
          <Typography variant='body1' component='span' className={classes.textFormHelperText}>
            La clave debe contener números, mayúsculas y/o minúsculas
          </Typography>
        </FormHelperText>
      )}

      <Box>
        {!stateSendPassword ? (
          <Button
            size='large'
            disableRipple
            classes={{ root: classes.buttonLogin, disabled: classes.disabled }}
            onClick={() => handleSend()}
            disabled={!enableButton}>
            Cambiar contraseña
          </Button>
        ) : (
          <Box className={classes.circularProgress}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );

  return <Layout withoutRightColumn>{content}</Layout>
};

export default SetNewPassword;