import { ACOHashTagRule } from '@components/WithLinks/installers/HashTagInstaller.linkify';
import { LinkifyInstaller } from '@components/WithLinks/model';
import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

export const linkify = LinkifyIt().tlds(tlds);

const install = ({ schema, rule }: LinkifyInstaller) => linkify.add(schema, rule);


install(ACOHashTagRule);