import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const usePostCardStyles = makeStyles({
  content: {
    marginTop: 20,
    marginBottom: 10,
    lineHeight: '20px',
    padding: '0 5px',
    fontSize: 16,
    letterSpacing: 0.25,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflow: 'hidden',
    [StyleResponsive.onMobile]: {
      fontSize: 14
    },
    '& > div > .video-container': {
      position: 'relative',
      paddingBottom: '56.25%',
      paddingTop: 30,
      height: 0,
      overflow: 'hidden',
      borderRadius: 8,
      '& > iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
      }
    },
    '&.minText': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 7,
      '-webkit-box-orient': 'vertical',
    }
  },
  textArea: {
    position: 'relative',
    width: '100%',
    '& .gradient': {
      bottom: 0,
      height: '45%',
      width: '100%',
      position: 'absolute',
      background: 'linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%);',
      opacity: 0.9
    } as CSSProperties
  },
  iframePdf: {
    width: '100%',
    [StyleResponsive.onDesktop]: {
      height: '85vh'
    },
    [StyleResponsive.onMobile]: {
      height: '70vh'
    }
  },

});
