import Button from '@components/AcoButtons/Button';
import { Avatar } from '@components/Avatar/Avatar';
import { CardAttachments } from '@components/cards/card/shared/Attachments/CardAttachments';
import { UserData } from '@components/cards/card/shared/UserInfo/UserInfo';
import { WithLinks } from '@components/WithLinks/WithLinks';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { useSession } from '@providers/user/SessionContext';
import { ResponseResult } from '@service/user.service';
import { parseDateFromBackendFormat, relativeShortDate } from '@shared/helper/date';
import axios from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { styles } from './TicketEvents.style';

export interface TicketEventProps {
  id: number;
  user: UserData,
  text: string;
  date: string;
  type: number;
  attachments?: { url: string }[];
}

export interface TicketEventsProps {
  events: TicketEventProps[];
  clientId: number;
  expert: UserData;
  state: string;
  ticketId: number | string;
}

const DELEGATION_EVENT_TYPE = 2;

const eventLabel = (events: TicketEventProps[], index: number, clientId: number, state: string) => {
  if (index < 1) return 'Consulta';

  const event = events[index];

  if (event.user.id == clientId) {
    return 'Aporte de aclaraciones';
  } else {
    if (index == events.length - 1 && event.type == 1 && (state == 'Aprobado' || state == 'Cancelado')) return 'Respuesta del experto';

    return event.type == DELEGATION_EVENT_TYPE ? 'Delegación de consulta' : 'Pedido de aclaraciones';
  }

};

export const TicketEvents: React.FC<TicketEventsProps> = ({
                                                            events,
                                                            clientId,
                                                            expert,
                                                            state,
                                                            ticketId,
                                                          }) => {
  const classes = styles();
  const { user, isLogged } = useSession();

  const { snackbars } = useCustomSnackbar();

  const [ticketEvents, setTicketEvents] = useState([]);

  const deleteEvent = (eventId) => {
    setTicketEvents(events.filter((event) => event.id !== eventId));
  };

  useEffect(() => {
    if (events) setTicketEvents([].concat(...(events.map(page => page))));
  }, [events]);

  const suspendEvent = (eventId) => {
    const data = { eventId, ticketId };

    axios.post<ResponseResult>('/api/tickets/suspendEvent', data)
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess('Evento eliminado');
          deleteEvent(eventId);
        } else {
          snackbars.showSuccess('Error al eliminar evento');

          if (res.data.status === 500) {
            console.error('Error interno');
          }
        }
      });
  };

  const cardTicketEvents = (event, index) => {
    const timeline = index < events.length - 1 || event.type == 2;

    return (
      <ListItem alignItems='flex-start' className={clsx(classes.listItem, { timeline: timeline })}
                key={`event_${event.id}`}>
        <ListItemAvatar className={classes.listItemAvatar}>
          <Avatar alt={event.user.name} src={event.user.img} name={event.user.name}
                  size={{ desktop: 48, mobile: 36 }} />
        </ListItemAvatar>
        <ListItemText
          className={clsx(classes.listItemText, { author: event.user.id == clientId })}
          disableTypography
          primary={
            <Box display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography variant='subtitle1'>{event.user.name}</Typography>
              <Box display='flex' alignItems='center'>
                {isLogged && user?.isAdmin && index > 0 &&
                  <Button size='small' color='primary' style={{ minWidth: '10px', padding: '8px 4px' }}
                          startIcon={<DeleteOutlineOutlinedIcon style={{ marginRight: '0px' }} />}
                          onClick={() => suspendEvent(event.id)}
                  />
                }
                <Box className={classes.label}>{eventLabel(events, index, clientId, state)}</Box>
              </Box>
            </Box>
          }
          secondary={
            <>
              <Typography
                className={classes.date}>{relativeShortDate(parseDateFromBackendFormat(event.date), 'Hace')}</Typography>
              <Box marginBottom='10px' />
              <WithLinks>
                <Typography className={classes.text} variant='body1' color='textPrimary' dangerouslySetInnerHTML={{ __html: event.text }} />
              </WithLinks>
              <CardAttachments {...{ files: event.attachments }} expandPdf={true} />
            </>
          }
        />
      </ListItem>
    );
  };

  const waitingResponse = () => {
    if (state == 'Cancelado' && events.length == 1) {
      return (
        <Box display='flex' alignItems='center' marginTop='10px'>
          <InfoOutlinedIcon color='secondary' fontSize='small' />
          <Typography color='secondary' marginLeft='5px'>
            Consulta cancelada por inactividad
          </Typography>
        </Box>
      );
    } else if (events[events.length - 1].type == DELEGATION_EVENT_TYPE || events[events.length - 1].user.id != expert.id) {
      const event = events[events.length - 1];

      return (
        <ListItem alignItems='flex-start' className={clsx(classes.listItem, { timeline: false })}
                  key={`event_${event.id}`}>
          <ListItemAvatar className={classes.listItemAvatar}>
            <Avatar alt={expert.name} src={expert.img} name={expert.name} size={{ desktop: 48, mobile: 36 }} />
          </ListItemAvatar>
          <ListItemText
            className={clsx(classes.listItemText, { author: false })}
            disableTypography
            primary={
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <Typography variant='subtitle1'>{expert.name}</Typography>
                <Box className={classes.label}>Pendiente de respuesta</Box>
              </Box>
            }
            secondary={
              <>
                <Typography
                  className={classes.date}>{relativeShortDate(parseDateFromBackendFormat(event.date), 'Hace')}</Typography>
                <Box marginBottom='10px' />
                <Box display='flex' alignItems='center'>
                  <InfoOutlinedIcon color='secondary' fontSize='small' />
                  <Typography color='secondary' marginLeft='5px'>
                    {user?.id == expert.id ? 'Esperando respuesta de mi parte. Ver opciones de respuesta abajo.' : 'En breve el experto enviará su respuesta.'}
                  </Typography>
                </Box>
              </>
            }
          />
        </ListItem>
      );
    }
  };

  return (
    <List className={classes.list}>
      {ticketEvents.map((event, index) =>
        cardTicketEvents(event, index),
      )}
      {waitingResponse()}
    </List>
  );
};
