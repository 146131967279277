import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useLoginModalStyles = makeStyles({
  content: {
    marginTop: 25,
    minWidth: 500,
    padding: 0,
    overflow: 'clip',

    [StyleResponsive.onMobile]: {
      minWidth: 'auto',
    },
  },
  actions: {
    padding: 0,
    marginTop: 15,
    '& > .postButton': {
      height: 48
    },
  },

});