import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StyleResponsive } from '@providers/Responsive.provider';

export default makeStyles(() =>
  createStyles({
    textFieldInput: {
      width: '100%',
      margin: '0 5px 0 14px'
    },
    newCommentBox: {
      marginTop: '10px',
      display:'flex',
      [StyleResponsive.onDesktop]: {
        justifyContent:'space-between'
      },
      [StyleResponsive.onMobile]: {
        flexDirection:'column',
        justifyContent:'space-between'
      },
      '&.reply': {
        marginTop: '4px',
      },
      '&.edit': {
        margin: '4px 0px 10px',
      }
    },
    newCommentTextInputComponents: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      margin: '4px 0',
      '&.reply': {
        margin: '4px 0',
      },
    },
    newCommentActionComponents: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [StyleResponsive.onDesktop]: {
        justifyContent:'space-between'
      },
      [StyleResponsive.onMobile]: {
        justifyContent:'flex-end',
        marginTop: '15px'
      },
    },
    loginBox: {
      backgroundColor: '#F8F8F8',
      border: '1px solid #DCDCDC',
      borderRadius: '8px',
      marginTop: '15px',
      [StyleResponsive.onMobile]: {
        padding:'15px',
      },[StyleResponsive.onDesktop]: {
        padding:'15px 20px',
      },
    },
    loginText: {
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center'
    }
  })
, {index: 1});
