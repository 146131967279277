import {
  CardContainerActions,
  CardContainerActionsProps,
} from '@components/cards/card/Container/action/CardContainerActions';
import { useStyles } from '@components/cards/card/Container/CardContainer.styles';
import { resolveButtonText } from '@components/cards/card/Container/CardContainerValues';
import { ACOFlag, FlagCountry } from '@components/Flag/ACOFlag';
import { LoginModal } from '@components/modal/Login/LoginModal';
import { SvgIconComponent } from '@mui/icons-material';
import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import { OnMobile, useIsDesktop } from '@providers/Responsive.provider';
import { useSession } from '@providers/user/SessionContext';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

import { showRegisterModal } from '../../../../pages/tickets/[tid]';

export interface ACOCardContainerProps extends CardContainerActionsProps {
  header: {
    title: string;
    color?: string;
    icon?: SvgIconComponent;
    rightContent?: string;
    flagCounty?: FlagCountry;
  };
  noRedirect?: boolean;
  isPageView?: boolean;
  hideActions?: boolean;
  redirectUrl?: string;
  sourceObject?: {
    pkg: string;
    id: string;
  };
  publisher?: number;
}

const CardContainer: React.FC<ACOCardContainerProps> = (props) => {
  const { children, header, ...actionProps } = props;

  const classes = useStyles();
  const theme = useTheme();
  const [cookies] = useCookies();
  const { isLogged } = useSession();
  const router = useRouter();
  const isDesktop = useIsDesktop();

  const [loginOpen, setLoginOpen] = useState(false);

  let redirectUrl = props.redirectUrl ? props.redirectUrl : `/${props.type.toLowerCase()}s/${props.id}`;

  if (props.sourceObject) {
    redirectUrl = redirectUrl + `?sourceObjectType=${props.sourceObject.pkg}&sourceObjectId=${props.sourceObject.id}`;
  }

  const redirectClick = !props.noRedirect
    ? (e) => {

      if (e.ctrlKey || e.metaKey) {
        return;
      }

      /*const thematicCropProductionCheck = props.children[4]?.props?.children[0]?.props?.children[0]?.props?.children?.key == 1; // Producción de cultivos

      const conditionForRegister = props.type === ObjectType.TICKET &&
        (!thematicCropProductionCheck || (thematicCropProductionCheck && cookies['Agroconsultas::Survey::DocumentSyngenta']));*/

      if (!isLogged /*&& conditionForRegister*/ && showRegisterModal(cookies['Agroconsultas::UUID'])) {
        router.push({ query: { destination: redirectUrl } }, undefined, { shallow: true });
        setLoginOpen(true);
        e.preventDefault();
      } else {
        router.push(redirectUrl);
        e.preventDefault();
      }
    }
    : undefined;

  const headerBackgroundColor = props.header?.color || theme.palette.secondary.light;

  const HeaderIcon = header.icon;

  return (
    <Card
      className={clsx(classes.root, {
        clickable: redirectClick && isDesktop,
      })}
      onClick={isDesktop ? redirectClick : undefined}>
      <a href={props.isPageView ? undefined : redirectUrl}>
        <div className='container'>
          <div
            className={clsx(classes.headerTag, { pageView: props.isPageView })}
            style={{ backgroundColor: headerBackgroundColor }}>
            <div className={classes.headerTagText}>
              {header?.icon && <HeaderIcon fontSize='inherit' className={classes.headerTagTextIcon} />}
              {header.title}
            </div>

            {header?.rightContent && <Typography variant='subtitle2'> {header.rightContent} </Typography>}

            {header?.flagCounty && (
              <div className={classes.headerTagFlag}>
                <ACOFlag country={header.flagCounty} />
              </div>
            )}
          </div>

          <div className={classes.contentContainer}>
            <div className={classes.mainContent}>
              <CardContent>{children}</CardContent>
            </div>

            {!props.isPageView && (
              <OnMobile>
                <Button variant='outlined' color='primary' className={classes.openButton} onClick={redirectClick}>
                  Ver {resolveButtonText(props.type)}
                </Button>
              </OnMobile>
            )}

            {!props.hideActions && (
              <CardContainerActions {...{ ...actionProps, redirectUrl: props.noRedirect ? undefined : redirectUrl }} />
            )}
          </div>
        </div>
      </a>

      {loginOpen && <LoginModal modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
                                loginModalProps={{ isAutomaticModal: true }} />}

    </Card>
  );
};

CardContainer.defaultProps = {
  header: {
    title: 'Contenido',
  },
  social: {
    hasUseful: false,
    useful: 0,
    comments: 0,
  },
};

export default CardContainer;
