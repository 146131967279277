import Button from '@components/AcoButtons/Button';
import { Avatar } from '@components/Avatar/Avatar';
import { LoginModal } from '@components/modal/Login/LoginModal';
import PersonAddAltIcon from '@mui/icons-material/PersonAddOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import { Box, SvgIconTypeMap, Tooltip, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useCustomSnackbar } from '@providers/CustomSnackbar.provider';
import { onClickPrevent } from '@providers/helper/click';
import { useSession } from '@providers/user/SessionContext';
import { ObjectType } from '@service/model';
import { ResponseResult } from '@service/user.service';
import axios from 'axios';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import styles from './ACOTooltipProfiel.styles';

function renderBtns(state: boolean, title: string, icon: OverridableComponent<SvgIconTypeMap>, className: string, func: () => void) {
  const Icon = icon;

  return (<>
      <Button disableRipple startIcon={<Icon />} className={className} {...onClickPrevent(func)}> {title}</Button>
    </>
  );
}

export const ACOTooltipProfile: React.VFC<{ children, profile, objectType }> = ({children, profile, objectType}) => {
  const classes = styles();
  const router = useRouter();
  const { snackbars } = useCustomSnackbar();
  const { user: loggedUser, isLogged } = useSession();
  const [isFollow, setIsFollow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loginOpen, setLoginOpen] = useState<boolean>(false);

  function unfollow(userId: number, name: string) {
    axios.post<ResponseResult>('/api/user/red/followers', { usuarioId: userId })
      .then((res) => {
        if (res.data.success) {
          snackbars.showError(`Dejaste de seguir a ${name}`);

          setIsFollow(!isFollow);
        } else {
          snackbars.showWarning('Error interno');
        }
      });
  }

  function follow(userId: number, name: string) {
    axios.post<ResponseResult>('/api/user/red/following', { usuarioId: userId })
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess(`Comenzaste a seguir a ${name}`);

          setIsFollow(!isFollow);
        } else {
          snackbars.showWarning('Error interno');
        }
      });
  }

  function notInterested(businessId: number, name: string) {
    axios.put<ResponseResult>('/api/business/red/markInterest', { empresaId: businessId })
      .then((res) => {
        if (res.data.success) {
          snackbars.showError(`Ya no te interesa ${name}`);

          setIsFollow(!isFollow);
        } else {
          snackbars.showWarning('Error interno');
        }
      });
  }

  function interested(businessId: number, name: string) {
    axios.post<ResponseResult>('/api/business/red/markInterest', { empresaId: businessId })
      .then((res) => {
        if (res.data.success) {
          snackbars.showSuccess(`Te interesa ${name}`);

          setIsFollow(!isFollow);
        } else {
          snackbars.showWarning('Error interno');
        }
      });
  }

  const InteractionActions= {
    [ObjectType.USER]: {
      follow: {
        label: 'Seguir',
        onClick: (id, name) => follow(id, name),
      },
      unfollow: {
        label: 'Dejar de seguir',
        onClick: (id, name) => unfollow(id, name),
      },
      urlFollowingUp: `/api/user/red/followUser?usuario_logueado_id=${loggedUser?.id}&usuario_id=${profile.id}`,
    },
    [ObjectType.BUSINESS]: {
      follow: {
        label: 'Me interesa',
        onClick: (id, name) => interested(id, name),
      },
      unfollow: {
        label: 'Ya no me interesa',
        onClick: (id, name) => notInterested(id, name),
      },
      urlFollowingUp: `/api/business/red/markInterest?empresa_id=${profile.id}`,
    }
  }

  function followingUp() {
    const url = InteractionActions[objectType].urlFollowingUp;

    axios.get<ResponseResult>(`${url}`).then((res) => {
      setIsFollow(res.data.success);
    });
  }

  const handleFollow = (isFollow, id, name) => {
    if (!isLogged) {
      setLoginOpen(true)
    }
    else if (isFollow) {
      InteractionActions[objectType].unfollow.onClick(id, name);
    } else {
      InteractionActions[objectType].follow.onClick(id, name);
    }
  }

  const handleOpenOrClose = () => {
    setOpen((prevState) => !prevState)
  }

  if (open && isLogged) {
    followingUp();
  }

  const content = (
    <Box className={classes.container} {...onClickPrevent(null)}>
      <Avatar alt={profile.name} src={profile.img} name={profile.name}
              size={{ desktop: 48, mobile: 36 }} />
      <Box className={classes.user}>
        <Typography variant={'subtitle1'}
                    style={{cursor: 'pointer'}}
                    onClick={() => router.push(`/user/${profile.id}`)}>{profile.completeName ? profile.completeName : profile.name}</Typography>
        <Typography style={{marginBottom: '10px'}}>@{profile.nickname}</Typography>
        {/*<Typography variant='body1' component='span'>
          <Typography variant='subtitle1' component='span'>{props.stats?.followers}</Typography> Seguidores
        </Typography>*/}
      </Box>

      {loggedUser?.id != profile.id &&
        renderBtns(isFollow,
          isFollow ? InteractionActions[objectType].unfollow.label : InteractionActions[objectType].follow.label,
          isFollow ? PersonRemoveOutlinedIcon : PersonAddAltIcon,
          isFollow ? classes.buttonUnfollow : classes.btnWhite,
          () => handleFollow(isFollow, profile.id, (profile.completeName ? profile.completeName : profile.name)),
        )}
    </Box>
  )

  return (
    <>
      <Tooltip open={open} onOpen={handleOpenOrClose} onClose={handleOpenOrClose} arrow classes={classes} title={content}>
        {children}
      </Tooltip>
      {loginOpen && (
        <LoginModal
          modalProps={{ open: loginOpen, onClose: () => setLoginOpen(false) }}
          loginModalProps={{ notRedirect: true, viewLogin: true }}
        />
      )}
    </>
    );
};

export default ACOTooltipProfile;
