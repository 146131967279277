import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme: Theme) =>
  createStyles({
    tagsContainer: {
      padding: '15px 0',
      color: '#828282',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    tag: {
      backgroundColor: '#F8F8F8',
      borderRadius: '6px',
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight
    }
  })
);
