import ACOTooltip from '@components/ACOTooltip/ACOTooltip';
import { SvgIconComponent } from '@mui/icons-material';
import makeStyles from '@mui/styles/makeStyles';
import { CreateCSSProperties } from '@mui/styles/withStyles/withStyles';
import React from 'react';

export interface IconCountProps {
  icon: SvgIconComponent,
  count: number;
  spacing?: number;
  tooltip?: string;
}

const useIconCountStyle = makeStyles({
  root: {
    margin: '6px 5px',
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    '& svg.icon': {
      fontSize: 15,
      marginRight: props => props.spacing || 8,
    } as CreateCSSProperties<IconCountProps>,
  },
});

export const IconCount: React.FC<IconCountProps> = (props) => {
  const classes = useIconCountStyle(props);

  const Icon = props.icon;

  return (
    <>
      {props.tooltip ? <ACOTooltip title={props.tooltip} arrow>
          <div className={classes.root}>
            <Icon className='icon' /> {props.count}
          </div>
        </ACOTooltip>
        : <div className={classes.root}>
          <Icon className='icon' /> {props.count}
        </div>
      }
    </>
  );
};
