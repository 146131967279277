import useInterval from 'beautiful-react-hooks/useInterval';
import React, { useState} from 'react';

interface IntervalProps {
  startDate: Date;
}

function formatIfGreaterThanZero(amount: number, formatter) {
  return amount > 0 ? formatter(amount) : null;
}

function formatChronometerIfGreaterThanZero(amount: number) {
  return `${amount}`.padStart(2, '0');
}

function formatDaysIfGreaterThanZero(amount: number) {
  return formatIfGreaterThanZero(amount, a => `${a} día${a > 1 ? 's' : ''}`);
}

function formatChrono(date: Date) {
  const format = formatChronometerIfGreaterThanZero;

  return `${format(date.getUTCHours())}:${format(date.getUTCMinutes())}:${format(date.getUTCSeconds())} horas`;
}

const StartedClock: React.VFC<IntervalProps> = (props) => {

  const referenceDate = props.startDate;

  const initial = new Date(new Date().getTime() - referenceDate.getTime());

  const [seconds, setSeconds] = useState(0);

  useInterval(() => {
    setSeconds(seconds + 1);
  }, 1000)

  const actual = new Date(initial.getTime() + seconds );
  const days = Math.floor(actual.getTime() / (1000 * 60 * 60 * 24));

  return (
    <>
      {formatDaysIfGreaterThanZero(days)} {formatChrono(actual)}
    </>

  );
};

export default StartedClock;
