import { Box } from '@mui/material';
import { EplContext, EplContextProvider } from '@providers/advertising/AdvertisingContext';
import { OnMobile } from '@providers/Responsive.provider';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import ScriptTag from 'react-script-tag';

const mobileAdBox = (espacio_id, content) => {
  return (
    <Box
      key={espacio_id}
      display='flex'
      alignItems='flex-end'
      bgcolor='background.paper'
      p={'5px'}
      width='100%'
      justifyContent='center'>
      {content}
    </Box>
  );
};

const AdvertisingSpace = () => {
  const ctx = useContext(EplContext);
  const resetEPL = 'document.epl = null;';
  const espacios = ['Top', 'Video_Slider', 'Anuncio_Previo', 'Zocalo'];

  const [reloadCounter, setReloadCounter] = useState(0);

  useEffect(() => {
    const reloadInterval = setInterval(() => {
      setReloadCounter((prevCounter) => prevCounter + 1);
    }, 60000);

    return () => clearInterval(reloadInterval);
  }, []);

  return (
    <OnMobile>
      <ScriptTag
        type='text/javascript'
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(resetEPL) }}
      />
      <ScriptTag id='Header' type='text/javascript' src='/js/e-planning/snippet_seccion_mobile.js' />
      {ctx.loaded &&
        espacios.map((item) => {
          return mobileAdBox(item, <EplDiv space={item} />);
        })
      }
    </OnMobile>
  );
};

export const Advertising = () => {
  return (
    <EplContextProvider>
      <AdvertisingSpace />
    </EplContextProvider>
  );
};

export const EplDiv: React.VFC<{ space: string }> = ({ space }) => {
  useEffect(() => {
    const loadEplAD4M = async () => {
      if (typeof window['eplAD4M'] === 'function') {
        window['eplAD4M'](space);
      } else {
        // Esperar a que window['eplAD4M'] se cargue completamente antes de intentar llamarlo nuevamente
        await new Promise((resolve) => setTimeout(resolve, 100));
        loadEplAD4M();
      }
    };

    loadEplAD4M().catch((err) => console.warn(`Cannot load ${space}`, err));
  }, [space]);


  return <div id={`eplAdDiv${space}`} style={{ width: '300px' }} />;
};
