import { useFinishRegisterStyles } from '@components/modal/FinishRegister/FinishRegister.styles';
import { Modal, ModalProps } from '@components/modal/Modal';
import ReportIcon from '@mui/icons-material/Report';
import { DialogContent, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { GA } from '@shared/ga/ga';
import React from 'react';

export const AdBlockModal: React.VFC<ModalProps> = (props) => {

  const classes = useFinishRegisterStyles();

  const handleClose = () => {
    props.onClose && props.onClose('', 'escapeKeyDown');

    GA.event({
      name: 'close_adblock_modal',
      params: {},
    });
  };

  return (
    <>
      <Modal {...(props as ModalProps)} onClose={handleClose}
             maxWidth='md' fullWidth
             sx={{ marginTop: '126px', '& .MuiDialog-container': { alignItems: 'flex-start' } }}>
        <DialogContent className={classes.content}>


          <Box style={{ color: '#26A65B', textAlign: 'center', marginBottom: '10px' }}>
            <ReportIcon sx={{ fontSize: 80 }}></ReportIcon>
            <Typography variant='h4'>Adblock activado</Typography>
          </Box>


          <Typography variant='subtitle1'>En Agroconsultas hacemos mucho esfuerzo para ofrecerte gratis
            contenido de calidad.
            Esto podemos hacerlo gracias a la publicidad. ¿Nos ayudas a ayudarte desbloqueando tu adbloker?</Typography>

        </DialogContent>

        {/* <DialogActions className={classes.actions}>
          <Button className={clsx('postButton')} color='primary' variant='contained' size='large'
                  onClick={handleClose}>
            Ir a Agroconsultas
          </Button>
        </DialogActions>*/}
      </Modal>
    </>
  );
};
