import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { Box, Grid, Link, Paper, Typography } from '@mui/material';
import {
  SocialFacebookSVG,
  SocialTwitterSVG,
  SocialYoutubeSVG,
  SocialInstagramSVG,
} from '@svg/socialNetworks';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import ScriptTag from 'react-script-tag';

import packageInfo from '../../../package.json';
import styles from './Footer.styles';

const PACKAGE_VERSION = packageInfo.version;

interface SocialNetwork {
  title: string;
  icon: React.FC<React.SVGProps<SVGElement>>;
  link: string;
}

const socialNetworks: SocialNetwork[] = [
  {
    title: 'Twitter',
    icon: SocialTwitterSVG,
    link: 'https://twitter.com/agroconsultas',
  },
  {
    title: 'Youtube',
    icon: SocialYoutubeSVG,
    link: 'https://www.youtube.com/user/agroconsultasonline',
  },
  {
    title: 'Facebook',
    icon: SocialFacebookSVG,
    link: 'https://es-es.facebook.com/pages/Agroconsultasonline/175335022500780',
  },
  {
    title: 'Instagram',
    icon: SocialInstagramSVG,
    link: 'https://www.instagram.com/agroconsultasonline/',
  },
];


const richMediaBox = (espacio_id, content) => {
  return (
    <Box
      key={espacio_id}
      display='none'>
      {content}
    </Box>
  );
};

const Footer: React.FC = () => {
  const feVersion = PACKAGE_VERSION;
  const classes = styles();
  const router = useRouter();

  const espacios = ['Video_Slider', 'Anuncio_Previo', 'Zocalo'];

  const funcionesComunes = 'function esperarCargaEPLFooter() { return new Promise(resolve => { function checkEPLFooter() { \
        if (typeof(eplAD4M) !== \'undefined\') { resolve(); } else { window.setTimeout(checkEPLFooter, 100); } }; checkEPLFooter(); }); }';

  useEffect(() => {
    if (window.location.pathname != '/') {
      const elementoZocalo = document.getElementById('zocalo_Zocalo');
      const elementoLayerZocalo = document.getElementById('layer_Zocalo');

      if (elementoZocalo) {
        elementoZocalo.remove();
      }

      if (elementoLayerZocalo) {
        elementoLayerZocalo.remove();
      }
    }

    if (window.location.pathname != '/tickets') {
      const elementoAnuncioPrevio = document.querySelector('[id^=adLayer]');

      if (elementoAnuncioPrevio) {
        router.reload();
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Paper elevation={3} classes={{ root: classes.paper }}>
        <Grid container justifyContent='center' alignItems='center' spacing={1}>
          <Grid item className={classes.followGrid}>
            <Typography variant='subtitle2' component='p' color='textSecondary'>
              Seguinos
            </Typography>
          </Grid>
          {socialNetworks.map((socialNetwork) => {
            const SocialIcon = socialNetwork.icon;

            return (
              <Grid key={socialNetwork.title} item>
                <Link href={socialNetwork.link} target='_blank' underline='hover'>
                  <SocialIcon width='38' height='43' className={classes.socialNetworkIcon} />
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
      <Box className={classes.boxFeedbackButton}>
        <FeedbackOutlinedIcon fontSize='small' />
        <Typography variant='subtitle2' sx={{ marginLeft: '9px' }}>
          <Link
            href='/feedback'
            color='secondary'
            underline='hover'>
            Enviar sugerencia
          </Link>
        </Typography>
      </Box>
      <Typography variant='body2' color='textPrimary' gutterBottom>
        Copyright © {new Date().getFullYear()} Agroconsultas Online v{feVersion}
      </Typography>
      <Typography variant='body2'>
        <Link
          href='https://www.agroconsultasonline.com.ar/docs/tyc.pdf'
          target='_blank'
          color='secondary'
          underline='hover'>
          Términos y condiciones de uso
        </Link>
      </Typography>
      <Typography variant='body2'>
        <Link
          href='https://www.agroconsultasonline.com.ar/docs/privacidad.pdf'
          target='_blank'
          color='secondary'
          underline='hover'>
          Política de privacidad
        </Link>
      </Typography>
      <ScriptTag id='funcionesComunesFooter' type='text/javascript'
                 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(funcionesComunes) }} />
      {espacios.map((item) => {
        let jsCall;

        if (item === 'Anuncio_Previo') {
          // eslint-disable-next-line max-len
          jsCall = 'async function cargarEspacio' + item + '() { await esperarCargaEPLFooter(); setArgs(); eplAD4M(\'' + item + '\'); }; cargarEspacio' + item + '();';
        } else {
          jsCall = 'async function cargarEspacio' + item + '() { await esperarCargaEPLFooter(); eplAD4M(\'' + item + '\'); }; cargarEspacio' + item + '();';
        }

        return (
          richMediaBox({ item }, <div>
            {(!window.location.pathname.startsWith('/events') && !window.location.pathname.startsWith('/trainings')) && (
              <div>
                <div id={`eplAdDiv${item}`} style={{ width: '300px' }} />
                <ScriptTag type='text/javascript' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jsCall) }} />
              </div>
            )}
          </div>)
        );
      })}
    </Box>
  );
};

export default Footer;
