import { alpha } from '@mui/material/styles';
import { CSSProperties, makeStyles } from '@mui/styles';
import { StyleResponsive } from '@providers/Responsive.provider';

export const useCardContainerActionsStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    borderRadius: 8,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 6px #DCDCDC',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      overflow: 'visible',
      borderRadius: 8,
      '& .container': {
        borderRadius: 8,
        boxShadow: `0px 0px 1.5px 1.5px ${alpha(theme.palette.primary.main, 0.8)}`,
      },
    },
  },
  headerTag: {
    borderRadius: '8px 8px 0px 0px',
    width: '100%',
    padding: '5px 4px 5px 25px',
    fontSize: 16,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  headerTagText: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  headerTagTextIcon: {
    marginRight: 6,
    fontSize: 19,
  },
  headerTagFlag: {
    textAlign: 'right',
    fontSize: 14,
    display: 'flex',
  },
  contentContainer: {
    height: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 22px',
    [StyleResponsive.onMobile]: {
      padding: '0 14px',
    } as CSSProperties,
  },
  mainContent: {
    flexGrow: 1,
    '& .MuiCardContent-root': {
      padding: '12px 0',
    },
  },
  actions: {
    display: 'flex',
    padding: '12px 0',
    '& .useful': {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    } as CSSProperties,
    '& .MuiButtonBase-root': {
      flexWrap: 'wrap',
      borderRadius: 8,
      color: theme.palette.text.primary,
      textTransform: 'capitalize',
    } as CSSProperties,
    '& .MuiButton-label': {
      textTransform: 'none',
      padding: '8px',
      fontSize: 16
    } as CSSProperties,
    [StyleResponsive.onMobile]: {
      justifyContent: 'space-around'
    } as CSSProperties
  },
  actionsSpacing: {
    '& > :not(:first-child)': {
      marginLeft: 16
    } as CSSProperties,
  },
  shareButton: {
    [StyleResponsive.onDesktop]: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  },
  openButton: {
    padding: '15px 0',
    textTransform: 'capitalize',
    border: `2px solid ${theme.palette.primary.main}`,
  },
}));
